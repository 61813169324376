import React, { useState, useEffect } from 'react'
import { handleVisibilityClick } from '@utils/userUtils'
import { useCV } from '@context/CVContext'
import Tooltip from '@mui/material/Tooltip'

import { Locked, Hidden, Visible, Limited } from '@assets'

import './VisibilityButton.css'

function VisibilityButton({ detailKeyOrPath, isCompany = false }) {
    const { cvData, updateCV } = useCV()
    const getVisibility = () => getVisibilityByPath(cvData, detailKeyOrPath)
    const [visibilityValue, setVisibilityValue] = useState(
        getVisibility() || 'Public',
    )
    const currentVisibility = getVisibility()

    function getVisibilityByPath(cvData, path) {
        const keys = path.split('.')
        let current = cvData.Visibility

        while (keys?.length > 0) {
            const key = keys.shift()

            if (current === undefined) {
                return 'Public'
            }

            if (keys?.length === 0) {
                if (Array.isArray(current)) {
                    const id = key
                    const item = current.find((item) => item._id === id)
                    return item ? item.visibility : 'Public'
                } else {
                    return current[key] || 'Public'
                }
            } else {
                current = current[key]
            }
        }

        return 'Public'
    }

    useEffect(() => {
        setVisibilityValue(currentVisibility || 'Public')
    }, [detailKeyOrPath, currentVisibility])

    let shouldLock = false
    if (
        detailKeyOrPath === 'Name' ||
        detailKeyOrPath === 'Surname' ||
        detailKeyOrPath === 'Summary'
    ) {
        shouldLock = true
    }

    const onClick = (event) => {
        event.stopPropagation()

        if (!shouldLock) {
            const newUserCV = handleVisibilityClick(cvData, detailKeyOrPath)
            updateCV(newUserCV)
        }
    }

    const getImageSrc = () => {
        if (shouldLock) {
            return Locked
        }
        return visibilityValue === 'Private'
            ? Hidden
            : visibilityValue === 'Public'
              ? Visible
              : Limited
    }

    const getTooltipText = () => {
        if (shouldLock) {
            return 'Locked'
        }
        if (visibilityValue === 'Private') return 'Visible only to you'
        if (visibilityValue === 'Limited') return 'Visible only on the CV'
        return 'Visible to everyone'
    }

    const getTooltipTextComp = () => {
        if (visibilityValue === 'Private') return 'Company name confidential'
        if (visibilityValue === 'Limited')
            return 'Company name confidential on Cotalent but visible on the CV'
        return 'Company name visible'
    }

    return (
        <Tooltip
            title={isCompany ? getTooltipTextComp() : getTooltipText()}
            placement="top"
        >
            <div className="visibility-edit-button">
                <button className="visibility-edit-button" onClick={onClick}>
                    <img
                        src={getImageSrc()}
                        alt={`Visibility: ${
                            isCompany ? getTooltipTextComp() : getTooltipText()
                        }`}
                    />
                </button>
                {/* <span className={`tooltip ${shouldLock ? '' : 'tooltip-blue'}`}>
                    {getTooltipText()}
                </span> */}
            </div>
        </Tooltip>
    )
}

export default VisibilityButton
