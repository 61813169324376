import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { useQuery } from 'react-query'
import { SpinnerCircular } from 'spinners-react'
import { useAuth, useAlert, useTmpData } from '@context'
import {
    scrollToBottom,
    getMessagesById,
    instructionTypes,
    descriptionState,
} from '@utils'
import {
    ChatComponent,
    DescriptionInputComponent,
    GenerateComponent,
    MessageCreateComponent,
} from './index'

import './DescriptionField.css'

function DescriptionField({ experience, onChange, modalRef, onSave }) {
    const { socket } = useAuth()
    const { showAlert } = useAlert()
    const { handleOpenFeedbackModal } = useTmpData()
    const [currentState, setCurrentState] = useState(
        descriptionState.SHOW_DESCRIPTION,
    )

    const chatContainerRef = useRef(null)
    const userMessageRef = useRef(null)
    const [chatMessages, setChatMessages] = useState([])
    const [generate, setGenerate] = useState(false)

    const InstructionData = useMemo(() => {
        return {
            CompanyName: experience.CompanyName,
            CompanyWebsite: experience.CompanyWebsite,
            CompanyDescription: experience.CompanyDescription,
            Title: experience.Title,
            ContractType: experience.ContractType,
            Location: experience.Location,
            Description: experience.Description || '',
        }
    }, [
        experience.CompanyName,
        experience.CompanyWebsite,
        experience.CompanyDescription,
        experience.Title,
        experience.ContractType,
        experience.Location,
        experience.Description,
    ])

    const addMessage = useCallback((message, sender) => {
        setChatMessages((prev) => [...prev, { text: message, sender }])
    }, [])

    const fetchMessages = useCallback(() => {
        if (!experience.Thread || !experience.Thread.threadId) {
            return
        }
        return getMessagesById(experience.Thread.threadId)
    }, [experience.Thread])

    const { isLoading } = useQuery(
        ['messages', experience.Thread?.threadId],
        fetchMessages,
        {
            enabled: !!experience.Thread?.threadId,
            onSuccess: (data) => {
                if (!data.messages) return
                setCurrentState(descriptionState.FETCHED)
                const messagesFromApi = data.messages.slice().reverse()

                if (messagesFromApi.length === 0) {
                    setCurrentState(descriptionState.WAIT_IMPROVEMENT)
                    return
                }

                const formattedMessages = messagesFromApi.map((message) => {
                    if (!message || message.content.length === 0) return null
                    const text = message.content[0].text.value

                    return {
                        text: text,
                        sender:
                            message.role === 'assistant' ? 'assistant' : 'user',
                    }
                })

                if (experience.Thread.generated_descriptions === 0) {
                    setCurrentState(descriptionState.ASSISTANT_ACTIVE)
                    if (!!experience.Description?.trim()) {
                        formattedMessages.unshift({
                            sender: 'assistant',
                            text: `Your current description is :\n\n${experience.Description}`,
                        })
                    }
                    setChatMessages([...formattedMessages])
                    setTimeout(() => {
                        scrollToBottom(chatContainerRef)
                        scrollToBottom(modalRef)
                    }, 100)
                } else if (experience.Thread.generated_descriptions <= 3) {
                    setCurrentState(descriptionState.ASSISTANT_REGENERATE)
                } else {
                    setCurrentState(descriptionState.ASSISTANT_FINISHED)
                }
            },
            onError: (error) => {
                showAlert(`Error loading messages: ${error.message}`, 'error')
            },
        },
    )

    useEffect(() => {
        if (!experience.Thread) return
        socket.on(
            `description-message-${experience.Thread.threadId}`,
            (message) => {
                if (message.type === 'description') {
                    experience.AssistantFinished = true
                    onChange('Description', message.text)
                    if (experience.Thread.generated_descriptions + 1 > 3) {
                        setCurrentState(descriptionState.ASSISTANT_FINISHED)
                    } else {
                        setCurrentState(descriptionState.ASSISTANT_REGENERATE)
                    }
                    onChange('Thread', {
                        ...experience.Thread,
                        generated_descriptions:
                            experience.Thread.generated_descriptions + 1,
                    })
                    return
                } else if (message.type === 'question') {
                    setCurrentState(descriptionState.ASSISTANT_ACTIVE)
                    onChange('Thread', {
                        ...experience.Thread,
                        generated_questions:
                            experience.Thread.generated_questions + 1,
                    })
                    addMessage(message.text, 'assistant')
                }
            },
        )

        return () => {
            socket.off(`description-message-${experience.Thread.threadId}`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [experience.Thread, socket])

    useEffect(() => {
        scrollToBottom(chatContainerRef)
    }, [chatMessages])

    const handleSendClick = useCallback(
        (instructionType, send = false) => {
            if (!send) {
                addMessage(userMessageRef.current.value, 'user')
            }
            setCurrentState(descriptionState.ASSISTANT_WRITING)
            socket.emit('description-chat', {
                thread: experience.Thread,
                message: userMessageRef.current.value,
                instructionData: InstructionData,
                instructionType: instructionType,
            })
            if (userMessageRef.current) {
                userMessageRef.current.value = ''
                userMessageRef.current.style.height = '83px'
            }
        },
        [experience.Thread, socket, addMessage, InstructionData],
    )

    const handleFirstMessage = useCallback(async () => {
        if (!!InstructionData.CompanyName === false) {
            showAlert('Please add a company name to your experience', 'error')
            return
        }
        if (!!InstructionData.Title === false) {
            showAlert('Please add a title to your experience', 'error')
            return
        }
        setCurrentState(descriptionState.ASSISTANT_WRITING)
        if (experience.isNewExperience) {
            await onSave()
        }
        socket.emit('description-chat', {
            thread: experience.Thread,
            instructionData: InstructionData,
            instructionType: instructionTypes.FIRST_MESSAGE,
        })
        setTimeout(() => {
            scrollToBottom(modalRef)
        }, 100)
        if (!generate && !!InstructionData.Description?.trim()) {
            addMessage(
                `Your current description is :\n\n${InstructionData.Description}`,
                'assistant',
            )
        }
    }, [
        InstructionData,
        experience.Thread,
        experience.isNewExperience,
        onSave,
        generate,
        addMessage,
        modalRef,
        showAlert,
        socket,
    ])

    const handleYesNoResponse = useCallback(
        (response) => {
            if (
                response === 'Yes' ||
                userMessageRef.current.value?.trim().toLowerCase() === 'yes'
            ) {
                handleSendClick(instructionTypes.DESCRIPTION, true)
                setGenerate(false)
                scrollToBottom(modalRef)
            } else if (response === 'No') {
                setGenerate(false)
            } else if (
                userMessageRef.current.value?.trim().toLowerCase() === 'no'
            ) {
                setGenerate(false)
                userMessageRef.current.value = ''
            } else {
                showAlert('Please answer with Yes or No', 'error')
            }
        },
        [showAlert, handleSendClick, setGenerate, modalRef],
    )

    const handleGenerate = useCallback(() => {
        if (generate === true) return
        setGenerate(true)

        setTimeout(() => {
            scrollToBottom(modalRef)
        }, 10)
    }, [generate, modalRef])

    const handleRegenerate = useCallback(() => {
        setCurrentState(descriptionState.REGENERATING)
        addMessage(
            `Your current description is :\n\n${experience.Description}`,
            'assistant',
        )
        addMessage(`How do you want to improve your description ?`, 'assistant')
        setTimeout(() => {
            scrollToBottom(modalRef)
        }, 10)
    }, [experience.Description, addMessage, modalRef])

    const descriptionComp = useCallback(
        () =>
            [
                descriptionState.SHOW_DESCRIPTION,
                descriptionState.WAIT_IMPROVEMENT,
                descriptionState.ASSISTANT_REGENERATE,
                descriptionState.ASSISTANT_FINISHED,
            ].includes(currentState),
        [currentState],
    )

    const assistantComp = useCallback(
        () =>
            [
                descriptionState.ASSISTANT_ACTIVE,
                descriptionState.ASSISTANT_WRITING,
                descriptionState.REGENERATING,
            ].includes(currentState),
        [currentState],
    )

    return (
        <div className="new-info-experience-modal-container">
            <div className="new-info-header">
                <div className="description-title-button">
                    <label
                        className="new-info-experience-modal-label"
                        htmlFor="description"
                    >
                        Description
                    </label>
                    {isLoading && (
                        <div className="desciption-spinner">
                            <SpinnerCircular
                                size={25}
                                thickness={100}
                                speed={100}
                                color="#007AFF"
                                secondaryColor="#66afff"
                            />
                        </div>
                    )}
                    {currentState === descriptionState.WAIT_IMPROVEMENT && (
                        <div className="description-first-time">
                            <div className="hn-buttons-container">
                                <button
                                    className="hn-button-blue"
                                    onClick={handleFirstMessage}
                                >
                                    {!experience.Description?.trim()
                                        ? `Build with AI`
                                        : `Improve with AI`}
                                </button>
                            </div>
                        </div>
                    )}
                    {currentState === descriptionState.ASSISTANT_REGENERATE && (
                        <div className="description-first-time">
                            <div className="hn-buttons-container">
                                <button
                                    className="hn-button-blue"
                                    onClick={handleRegenerate}
                                >
                                    {`Regenerate (${
                                        4 -
                                        experience.Thread.generated_descriptions
                                    }/3)`}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="description-container">
                {descriptionComp() && (
                    <DescriptionInputComponent
                        experience={experience}
                        onChange={onChange}
                        currentState={currentState}
                    />
                )}
                {assistantComp() && (
                    <>
                        <ChatComponent
                            chatMessages={chatMessages}
                            // handleEditClick={handleEditClick}
                            currentState={currentState}
                            chatContainerRef={chatContainerRef}
                        />
                        {generate && (
                            <GenerateComponent
                                handleYesNoResponse={handleYesNoResponse}
                            />
                        )}
                        <div className="chat-container-input">
                            <MessageCreateComponent
                                userMessageRef={userMessageRef}
                                currentState={currentState}
                                handleSendClick={handleSendClick}
                                handleGenerate={handleGenerate}
                                generate={generate}
                                handleYesNoResponse={handleYesNoResponse}
                                generated_questions={
                                    experience.Thread.generated_questions
                                }
                            />
                        </div>
                    </>
                )}
            </div>
            <div className="feedback-message">
                <p>
                    We use AI and the answer may be imprecise, please
                    <span onClick={handleOpenFeedbackModal}>
                        {` send Feedback `}
                    </span>
                    to help us improve.
                </p>
            </div>
        </div>
    )
}

export default DescriptionField
