import React, { useState } from 'react'
import { useCV } from '@context/CVContext'
import { VisibilityButton } from '@compUtils'
import LocationSearch from '@compUtils/LocationSearch'

import { customStylesValid, customStylesInvalid } from '@styles/styles'

function Location({ isModal = false }) {
    const { cvData, updateCV } = useCV()
    const [location, setLocation] = useState(
        cvData.CurrentCity
            ? { value: cvData.CurrentCity, label: cvData.CurrentCity }
            : null,
    )

    const handleLocationChange = (selectedOption) => {
        if (selectedOption.value === 'International') {
            setLocation(selectedOption)
            updateCV((prevData) => ({
                ...prevData,
                CurrentCity: 'International',
            }))
            return
        }

        const splitCity = selectedOption.label.split(',')
        const city = splitCity[0] + ', ' + splitCity[splitCity?.length - 1]

        const newSelectedOption = {
            ...selectedOption,
            value: city,
            label: city,
        }

        setLocation(newSelectedOption)
        updateCV((prevData) => ({ ...prevData, CurrentCity: city }))
    }

    return (
        <div
            className={`new-component ${
                location === null ? 'invalid-border' : 'valid-border'
            }`}
            style={isModal ? { width: 'auto' } : {}}
        >
            <div className="new-info-wrapper">
                <div className="new-info-header">
                    Location
                    <VisibilityButton detailKeyOrPath={'CurrentCity'} />
                </div>
                <div className="new-info-container">
                    <LocationSearch
                        value={location}
                        onChange={handleLocationChange}
                        styleValid={customStylesValid}
                        styleInvalid={customStylesInvalid}
                    />
                </div>
            </div>
        </div>
    )
}

export default Location
