import React, { useState } from 'react'
import { useUserData } from '@context/UserDataContext'
import { VisibilityButton } from '@compUtils'
import { nameRegex } from '@utils'

function Name({ isModal = false }) {
    const { userData, updateUD } = useUserData()
    const [name, setName] = useState(userData.Name)
    const [surname, setSurname] = useState(userData.Surname)

    const [isFirstNameValid, setFirstNameValid] = useState(nameRegex.test(name))
    const [isLastNameValid, setLastNameValid] = useState(
        nameRegex.test(surname),
    )

    // Handler for name input change
    const handleNameChange = (event, attribute, validator) => {
        const value = event.target.value
        updateUD((prevData) => ({ ...prevData, [attribute]: value }))

        if (nameRegex.test(value)) {
            validator(true)
        } else {
            validator(false)
        }
    }

    return (
        <div
            className={`new-component ${
                !isFirstNameValid || !isLastNameValid
                    ? 'invalid-border'
                    : 'valid-border'
            }`}
            style={isModal ? { width: 'auto' } : {}}
        >
            <div className="new-info-wrapper">
                <div className="new-info-header">
                    Name Details
                    <VisibilityButton detailKeyOrPath={'Name'} />
                </div>
                <div className="new-info-container">
                    <input
                        type="text"
                        placeholder="First Name"
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value)
                            handleNameChange(e, 'Name', setFirstNameValid)
                        }}
                    />
                    <input
                        type="text"
                        placeholder="Last Name"
                        value={surname}
                        onChange={(e) => {
                            setSurname(e.target.value)
                            handleNameChange(e, 'Surname', setLastNameValid)
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default Name
