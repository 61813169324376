import React from 'react'
import { useNavigate } from 'react-router-dom'
import { EntireLogo } from '@assets'

function HeaderLogo() {
    const navigate = useNavigate()

    return (
        <div className="cotalent-logo">
            <img
                src={EntireLogo}
                alt="Cotalent Logo"
                onClick={() => navigate('/')}
            />
            <div className="alpha">
                <span>Alpha</span>
            </div>
        </div>
    )
}

export default HeaderLogo
