import React, { createContext, useContext, useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from 'react-query'
import { updateJD as updateJDOnServer } from '@utils'
import { debounce } from 'lodash'

const JDContext = createContext()

export function useJD() {
    return useContext(JDContext)
}

const getRequirements = (jobData) => {
    if (!jobData.Gender) {
        return false
    } else if (
        jobData.Languages?.length === 0 ||
        jobData.TechnicalSkills?.length === 0
    ) {
        return false
    }
    return true
}

const getDetails = (jobData) => {
    if (
        !jobData.Title ||
        !jobData.Department ||
        !jobData.Supervisor ||
        !jobData.ReportingTo ||
        !jobData.Location ||
        !jobData.WorkingHours
    ) {
        return false
    } else if (
        jobData.Subordinates?.length === 0 ||
        jobData.Bonus?.length === 0
    ) {
        return false
    } else if (
        !jobData.Salary?.MinSalary ||
        !jobData.Salary?.MaxSalary ||
        !jobData.Salary?.Currency ||
        !jobData.Salary?.PaymentFrequency
    ) {
        return false
    }
    return true
}

const getStep = (jobData) => {
    const requirements = getDetails(jobData)
    const draft = requirements && getRequirements(jobData)
    const finalize = draft
    return {
        details: true,
        requirements: requirements,
        draft: draft,
        finalize: finalize,
    }
}

export function JDProvider({ children }) {
    const [jd, setJD] = useState(null)
    const [accessibleSteps, setAccessibleSteps] = useState({
        details: true,
        requirements: false,
        draft: false,
        finalize: false,
    })

    const setInitialJDData = (jobData) => {
        setJD(jobData)
        setAccessibleSteps(getStep(jobData))
    }

    const getMostAdvancedStep = () => {
        const stepsOrder = ['details', 'requirements', 'draft', 'finalize']
        for (let i = stepsOrder.length - 1; i >= 0; i--) {
            if (accessibleSteps[stepsOrder[i]]) {
                return stepsOrder[i]
            }
        }
        return 'details'
    }

    const navigate = useNavigate()

    const navigateToMostAdvancedStep = (jobId) => {
        const step = getMostAdvancedStep()
        navigate(`/hire/job/${jobId}/${step}`)
    }

    const { mutate, isLoading, isError, error } = useMutation(
        updateJDOnServer,
        {
            onSuccess: (updatedJD) => {
                if (process.env.REACT_APP_NODE_ENV === 'development') {
                    // console.log('JD updated successfully', updatedJD)
                }
            },
            onError: (error) => {
                if (process.env.REACT_APP_NODE_ENV === 'development') {
                    console.error('Failed to update JD', error)
                }
            },
        },
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedUpdateJD = useCallback(
        debounce((newData) => {
            if (newData?.Title?.trim() === '') {
                newData.Title = 'Untitled'
            }
            mutate(newData)
        }, 5000),
        [],
    )

    const updateJD = (newData) => {
        setJD(newData)
        setAccessibleSteps(getStep(newData))
        debouncedUpdateJD(newData)
    }

    const canAccessStep = (step) => {
        return !!accessibleSteps[step]
    }

    return (
        <JDContext.Provider
            value={{
                jd,
                setInitialJDData,
                updateJD,
                canAccessStep,
                navigateToMostAdvancedStep,
                isLoading,
                isError,
                error,
            }}
        >
            {children}
        </JDContext.Provider>
    )
}
