import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Companies, JobDescriptions, JobDescriptionRoutes } from '@hire'

import '@styles/hire.css'

function Hire() {
    return (
        <Routes>
            <Route path="/" element={<Companies />} />
            <Route path="/company/:companyId" element={<JobDescriptions />} />
            <Route path="/job/:jobId/*" element={<JobDescriptionRoutes />} />
            <Route path="*" element={<Companies />} />
        </Routes>
    )
}

export default Hire
