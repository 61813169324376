import React, { useState } from 'react'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { getPromptByCode, updatePromptById } from '@utils'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function PromptModal({ initialValue }) {
    const queryClient = useQueryClient()
    const [prompt, setPrompt] = useState('')
    const [id, setId] = useState('')

    const { isError, error } = useQuery(
        ['prompt', initialValue],
        () => getPromptByCode(initialValue),
        {
            onSuccess: (data) => {
                setPrompt(data.content)
                setId(data._id)
            },
        },
    )

    const updateMutation = useMutation(updatePromptById, {
        onSuccess: () => {
            toast.success(`Prompt updated successfully!`, { autoClose: 3000 })
            queryClient.invalidateQueries(['prompt', initialValue])
        },
        onError: (error) => {
            toast.error(`Error updating prompt: ${error.message}`, {
                autoClose: 3000,
            })
        },
    })

    const handleClick = () => {
        updateMutation.mutate({ id: id, prompt: prompt })
    }

    if (isError) {
        toast.error(`Error fetching prompt: ${error.message}`, {
            autoClose: 3000,
        })
    }

    return (
        <div className="new-info-exprerience-container">
            <div className="description-container">
                <div className="chat-container-input">
                    <textarea
                        value={prompt}
                        onChange={(e) => setPrompt(e.target.value)}
                        style={{ height: '300px' }}
                    />
                </div>
            </div>
            {prompt && <button onClick={handleClick}>Update Prompt</button>}
        </div>
    )
}

export default PromptModal
