import React, { useState, createContext, useContext } from 'react'

const ContentOpenContext = createContext()

export const ContentOpenProvider = ({ children }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false)
    const [isSecondaryContentOpen, setIsSecondaryContentOpen] = useState(false)
    const [isContentOpen, setIsContentOpen] = useState(false)

    const openSidebar = () => {
        setIsSidebarOpen(true)
        setIsContentOpen(true)
        setIsSecondaryContentOpen(false)
    }

    const openSecondaryContent = () => {
        setIsSecondaryContentOpen(true)
        setIsContentOpen(true)
        setIsSidebarOpen(false)
    }

    const closeContent = () => {
        setIsSidebarOpen(false)
        setIsSecondaryContentOpen(false)
        setIsContentOpen(false)
    }

    return (
        <ContentOpenContext.Provider
            value={{
                isSidebarOpen,
                isSecondaryContentOpen,
                isContentOpen,
                openSidebar,
                openSecondaryContent,
                closeContent,
            }}
        >
            {children}
        </ContentOpenContext.Provider>
    )
}

export const useContentOpen = () => useContext(ContentOpenContext)
