import React, { useState, useEffect } from 'react'
import { Modal } from '@modals'
import { useCV } from '@context'
import { useCVUpdate } from '@hooks'
import ObjectID from 'bson-objectid'
import { updateExperienceInCV } from '@utils'
import ExperiencesModal from '@modals/Experiences/ExperiencesModal'

import { Add } from '@assets'

import './AddSections.css'

function AddSection({ category, hasParent = false, triggerAddExperience }) {
    const { cvData, updateCVSync } = useCV()
    const { handleModalCloseExp } = useCVUpdate()
    const [isExperienceModalOpen, setIsExperienceModalOpen] = useState(false)
    const [initialValue, setInitialValue] = useState({})

    const onClose = async (experienceChanges) => {
        setIsExperienceModalOpen(false)
        if (!experienceChanges) return

        handleModalCloseExp(experienceChanges)
    }

    const onSave = async (experienceChanges) => {
        const updatedUserCV = updateExperienceInCV(cvData, experienceChanges)

        await updateCVSync(updatedUserCV)
    }

    useEffect(() => {
        if (triggerAddExperience) {
            handleAddExperience()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerAddExperience])

    const handleAddExperience = async () => {
        const id = ObjectID().toString()
        setInitialValue({
            CompanyId: 'tmp',
            _id: id,
            id: id,
            isNewExperience: true,
            ExperienceType: category,
        })

        setIsExperienceModalOpen(true)
    }

    return (
        <>
            {!hasParent && (
                <div className="add-section">
                    <img
                        src={Add}
                        alt="Add"
                        className="add-section-icon"
                        onClick={handleAddExperience}
                    />
                </div>
            )}
            <Modal
                isOpen={isExperienceModalOpen}
                onClose={onClose}
                onSave={onSave}
                modal="Experience"
                initialValue={initialValue}
                className="modal-content wide-exp"
                Component={ExperiencesModal}
            />
        </>
    )
}

export default AddSection
