import React, { useEffect } from 'react'
import Modal from 'react-modal'
import { useCV, useTmpData } from '@context'

import './DeleteButton.css'

const DeleteButton = ({ detailKeyOrPath, onClose }) => {
    const { cvData, updateCV } = useCV()
    const { deleteModalOpen, setDeleteModalOpen, setAllowClose } = useTmpData()

    useEffect(() => {
        setAllowClose(!deleteModalOpen)
    }, [deleteModalOpen, setAllowClose])

    if (!deleteModalOpen) return null

    const deleteDetail = async (keyPath) => {
        const keys = keyPath.split('.')
        let updatedUserCV = { ...cvData }

        function deleteById(data, id) {
            if (Array.isArray(data)) {
                return data.filter((item) => {
                    if (item && typeof item === 'object') {
                        if ('_id' in item && item._id === id) {
                            return false
                        } else {
                            const keys = Object.keys(item)
                            for (const key of keys) {
                                item[key] = deleteById(item[key], id)
                            }
                            return true
                        }
                    } else {
                        return true
                    }
                })
            } else if (data && typeof data === 'object') {
                const keys = Object.keys(data)
                for (const key of keys) {
                    data[key] = deleteById(data[key], id)
                }
                return data
            }
            return data
        }

        if (keys?.length === 2) {
            const section = keys[0]
            const idToDelete = keys[1]
            updatedUserCV[section] = deleteById(
                updatedUserCV[section],
                idToDelete,
            )
        }

        updateCV(updatedUserCV)
    }

    return (
        <div>
            <Modal
                isOpen={deleteModalOpen}
                onRequestClose={() => setDeleteModalOpen(false)}
                contentLabel="Delete Confirmation"
                className="delete-modal"
                ariaHideApp={false}
                overlayClassName="delete-modal-overlay"
            >
                <h2>Are you sure you want to delete this item?</h2>
                <div className="modal-buttons">
                    <button
                        onClick={(e) => {
                            e.stopPropagation()
                            setDeleteModalOpen(false)
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        onClick={(e) => {
                            e.stopPropagation()
                            setDeleteModalOpen(false)
                            deleteDetail(detailKeyOrPath)
                            onClose()
                        }}
                    >
                        Confirm
                    </button>
                </div>
            </Modal>
        </div>
    )
}

export default DeleteButton
