import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '@context/AuthContext'

import { EntireLogo } from '@assets'

const Header = () => {
    const { user } = useAuth()
    const navigate = useNavigate()

    const handleSignInClick = () => {
        navigate('/SignIn')
    }

    const handleSignUpClick = () => {
        navigate('/SignUp')
    }

    return (
        <div className="landing-header">
            <div
                className="cotalent-login-logo"
                onClick={() => {
                    if (user) navigate('/profile')
                }}
                style={user && { cursor: 'pointer' }}
            >
                <img
                    src={EntireLogo}
                    alt="Entire Logo"
                    className="landing-header-logo"
                />
                <div className="alpha">
                    <span>Alpha</span>
                </div>
            </div>
            {user ? (
                <div className="hn-buttons-container">
                    <button
                        className="hn-button-white"
                        onClick={() => navigate('/profile')}
                    >
                        Go to profile
                    </button>
                </div>
            ) : (
                <div className="hn-buttons-container">
                    <button
                        className="hn-button-white"
                        onClick={handleSignInClick}
                    >
                        Sign In
                    </button>
                    <button
                        className="hn-button-blue"
                        onClick={handleSignUpClick}
                    >
                        Get Started
                    </button>
                </div>
            )}
        </div>
    )
}

export default Header
