import React, { useMemo } from 'react'
import ExperienceCompany from './ExperienceCompany'
import ExperienceEntry from './ExperienceEntry'
import { useValidateExperiences, useValidation } from '@hooks'
import { ErrorMessage } from '@compUtils'

const ExperienceContainer = ({
    company,
    experiences,
    category,
    isEditing,
    style,
}) => {
    const { className: classNameExp, errorMessage: errorMessageExp } =
        useValidateExperiences(experiences, isEditing, category)

    const validationConfigCompany = useMemo(
        () => [
            {
                key: 'CompanyName',
                validate: (value) =>
                    value ? '' : '!Click the card to add the company name',
            },
            {
                key: 'CompanyWebSite',
                validate: (value) =>
                    value ? '' : 'Click the card to add the company website',
            },
            {
                key: 'CompanyDescription',
                validate: (value) =>
                    value
                        ? ''
                        : '!Click the card to add the company description',
            },
        ],
        [],
    )

    const { className: classNameComp, errorMessage: errorMessageComp } =
        useValidation(company, isEditing, validationConfigCompany)

    let className = ''
    let errorMessage = ''

    if (classNameComp === 'editing-red') {
        className = classNameComp
        errorMessage = errorMessageComp
    } else if (classNameExp === 'editing-red') {
        className = classNameExp
        errorMessage = errorMessageExp
    } else if (classNameComp === 'editing-orange') {
        className = classNameComp
        errorMessage = errorMessageComp
    } else if (classNameExp === 'editing-orange') {
        className = classNameExp
        errorMessage = errorMessageExp
    } else {
        className = classNameComp
        errorMessage = errorMessageComp
    }

    return (
        <>
            <div className={`experience-container ${className}`} style={style}>
                <ExperienceCompany
                    Name={company.CompanyName}
                    WebSite={company.CompanyWebSite}
                    Description={company.CompanyDescription}
                    CompanyId={company._id}
                    experienceType={category}
                />
                {experiences.map((experience) => {
                    if (!experience) return []
                    return (
                        <ExperienceEntry
                            key={experience._id}
                            id={experience._id}
                            CompanyName={company.CompanyName}
                            CompanyWebSite={company.CompanyWebSite}
                            CompanyDescription={company.CompanyDescription}
                            CompanyId={company._id}
                            ExperienceType={category}
                            Title={experience.Title}
                            StartDate={experience.StartDate}
                            EndDate={experience.EndDate}
                            Location={experience.Location}
                            ContractType={experience.ContractType}
                            WorkMode={experience.WorkMode}
                            EngagementTime={experience.EngagementTime}
                            Description={experience.Description}
                            Skills={experience.Skills}
                            Thread={experience.Thread}
                            AssistantFinished={experience.AssistantFinished}
                        />
                    )
                })}
            </div>
            {isEditing && errorMessage !== '' && (
                <ErrorMessage message={errorMessage} className={className} />
            )}
        </>
    )
}

export default ExperienceContainer
