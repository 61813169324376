import React, { useState, useCallback } from 'react'
import { useCV } from '@context/CVContext'
import Select from 'react-select'
import debounce from 'lodash.debounce'
import { VisibilityButton } from '@compUtils'

import { customStylesValid, customStylesInvalid } from '@styles/styles'

function Locations({ isModal = false }) {
    const { cvData, updateCV } = useCV()
    const [locations, setLocations] = useState(
        cvData.PreferedLocation
            ? cvData.PreferedLocation?.map((city) => ({
                  value: city,
                  label: city,
              }))
            : [],
    )
    const [options, setOptions] = useState([
        { value: 'Anywhere', label: 'Anywhere' },
    ])

    const debouncedFetch = debounce((value) => {
        if (value && value?.length > 2) {
            fetch(
                `https://nominatim.openstreetmap.org/search?city=${value}&format=json&limit=10`,
            )
                .then((response) => response.json())
                .then((data) => {
                    const formattedData = data?.map((city) => ({
                        value: city.display_name,
                        label: city.display_name,
                    }))

                    setOptions([
                        { value: 'Anywhere', label: 'Anywhere' },
                        ...formattedData,
                    ])
                })
        } else {
            setOptions([{ value: 'Anywhere', label: 'Anywhere' }])
        }
    }, 300)

    const fetchCities = useCallback(
        (value) => {
            debouncedFetch(value)
        },
        [debouncedFetch],
    )

    const handleInputChange = (value) => {
        fetchCities(value)
    }

    const handleLocationsChange = (selectedOptions) => {
        const formattedOptions = selectedOptions?.map((option) => {
            if (option.value === 'Anywhere') {
                return option
            }

            const splitCity = option.label.split(',')
            const city = splitCity[0] + ', ' + splitCity[splitCity?.length - 1]
            return { value: city, label: city }
        })

        setLocations(formattedOptions)
        updateCV((prevData) => ({
            ...prevData,
            PreferedLocation: formattedOptions?.map((option) => option.value),
        }))
    }

    const customStyles = {
        container: (provided) => ({
            ...provided,
            width: '100%',
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 1000,
        }),
    }

    return (
        <div
            className={`new-component ${
                locations?.length === 0 ? 'invalid-border' : 'valid-border'
            }`}
            style={isModal ? { width: 'auto' } : {}}
        >
            <div className="new-info-wrapper">
                <div className="new-info-header">
                    Locations
                    <VisibilityButton detailKeyOrPath={'PreferedLocation'} />
                </div>
                <div className="new-info-container">
                    <Select
                        isMulti
                        value={locations}
                        onInputChange={handleInputChange}
                        onChange={handleLocationsChange}
                        options={options}
                        isSearchable
                        placeholder="Type to search for cities..."
                        styles={{
                            ...customStyles,
                            ...(locations?.length > 0
                                ? customStylesValid
                                : customStylesInvalid),
                        }}
                        filterOption={null}
                        openMenuOnFocus={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default Locations
