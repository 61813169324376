import React, { useEffect } from 'react'

import { useTmpData } from '@context/TmpDataContext'
import {
    Experiences,
    Information,
    Prefered,
    Education,
    Language,
} from '@sections'

import './Finalize.css'

function Finalize({ exp = true }) {
    const { setIsEditing } = useTmpData()

    useEffect(() => {
        setIsEditing(true)
    }, [setIsEditing])

    return (
        <div className="finalize">
            {exp && <Information />}
            {exp && <Prefered />}
            {/* <Overview /> */}
            <Experiences />
            {exp && <Education />}
            {exp && <Language />}
        </div>
    )
}

export default Finalize
