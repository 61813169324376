import React, { useState } from 'react'
import { useCV } from '@context/CVContext'
import { currencies } from '@utils'
import Select from 'react-select'
import { VisibilityButton } from '@compUtils'

function CurrentBaseSalary({ isModal = false }) {
    const { cvData, updateCV } = useCV()

    const [salaryValue, setSalaryValue] = useState(
        cvData.CurrentBaseSalary?.SalaryValue || '',
    )
    const [currency, setCurrency] = useState({
        value: cvData.CurrentBaseSalary?.Currency || null,
        label: cvData.CurrentBaseSalary?.Currency || null,
    })
    const [paymentFrequency, setPaymentFrequency] = useState({
        value: cvData.CurrentBaseSalary?.PaymentFrequency || null,
        label: cvData.CurrentBaseSalary?.PaymentFrequency || null,
    })

    const currencyOptions = Object.keys(currencies)?.map((key) => ({
        value: key,
        label: `${key} - ${currencies[key].name}`,
    }))

    const frequencyOptions = [
        { value: 'Weekly', label: 'Weekly' },
        { value: 'Monthly', label: 'Monthly' },
        { value: 'Annually', label: 'Annually' },
        { value: 'Project duration', label: 'Project duration' },
    ]

    const formatSalary = (value) => {
        const numericValue = parseFloat(value.replace(/[^0-9.]/g, ''))
        if (isNaN(numericValue)) return ''
        return numericValue.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })
    }

    const handleSalaryBlur = () => {
        const formattedSalary = formatSalary(salaryValue)
        setSalaryValue(formattedSalary)
        updateCV((prevData) => ({
            ...prevData,
            CurrentBaseSalary: {
                ...(prevData.CurrentBaseSalary || {}),
                SalaryValue: formattedSalary,
            },
        }))
    }

    const handleCurrencyChange = (selectedOption) => {
        selectedOption.label = selectedOption.label.split(' - ')[0]
        setCurrency(selectedOption)
        updateCV((prevData) => ({
            ...prevData,
            CurrentBaseSalary: {
                ...(prevData.CurrentBaseSalary || {}),
                Currency: selectedOption.value,
            },
        }))
    }

    const handleFrequencyChange = (selectedOption) => {
        setPaymentFrequency(selectedOption)
        updateCV((prevData) => ({
            ...prevData,
            CurrentBaseSalary: {
                ...(prevData.CurrentBaseSalary || {}),
                PaymentFrequency: selectedOption.value,
            },
        }))
    }

    const customStyles = {
        container: (provided) => ({
            ...provided,
            width: '100%',
        }),
        valueContainer: (provided) => ({
            ...provided,
            maxHeight: '38px',
        }),
        menuList: (provided) => ({
            ...provided,
            maxHeight: '170px',
            overflowY: 'auto',
        }),
    }

    return (
        <div
            className={`new-component ${
                !salaryValue || !currency || !paymentFrequency
                    ? 'facultative-border'
                    : 'valid-border'
            }`}
            style={isModal ? { width: 'auto' } : {}}
        >
            <div className="new-info-wrapper">
                <div className="new-info-header">
                    Current Base Salary
                    <VisibilityButton detailKeyOrPath={'CurrentBaseSalary'} />
                </div>
                <div className="new-info-container">
                    <input
                        type="text"
                        placeholder="Salary"
                        value={salaryValue}
                        onChange={(e) => setSalaryValue(e.target.value)}
                        onBlur={handleSalaryBlur}
                    />
                    <Select
                        value={currency}
                        onChange={handleCurrencyChange}
                        options={currencyOptions}
                        isSearchable
                        placeholder="Currency"
                        styles={customStyles}
                        openMenuOnFocus={true}
                    />
                    <Select
                        value={paymentFrequency}
                        onChange={handleFrequencyChange}
                        options={frequencyOptions}
                        isSearchable
                        placeholder="Frequency"
                        styles={customStyles}
                        openMenuOnFocus={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default CurrentBaseSalary
