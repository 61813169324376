import React, { useState, useMemo } from 'react'
import { useContentOpen, useTmpData } from '@context'
import { ErrorMessage, AddEducation, VisibilityButton } from '@compUtils'
import { formatTime } from '@utils'
import { useValidation, useCVUpdate } from '@hooks'
import { Modal } from '@modals'
import EducationModal from '@modals/Experiences/EducationModal'

import './EducationEntry.css'

const EducationEntry = ({
    id,
    SchoolName,
    SchoolWebSite,
    Field,
    StartDate,
    EndDate,
    SchoolLocation,
    Degree,
    Grade,
    style,
}) => {
    const { isEditing } = useTmpData()
    const { handleModalCloseGeneric } = useCVUpdate()
    const { isContentOpen } = useContentOpen()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [addTrigger, setAddTrigger] = useState(false)

    const validationConfig = useMemo(
        () => [
            {
                key: 'SchoolName',
                validate: (value) =>
                    value
                        ? ''
                        : '!Click the card to add the missing information',
            },
            {
                key: 'SchoolWebSite',
                validate: (value) =>
                    value
                        ? ''
                        : 'Click the card to add the optional information',
            },
            {
                key: 'Field',
                validate: (value) =>
                    value
                        ? ''
                        : '!Click the card to add the missing information',
            },
            {
                key: 'StartDate',
                validate: (value) =>
                    value
                        ? ''
                        : 'Click the card to add the optional information',
            },
            {
                key: 'EndDate',
                validate: (value) =>
                    value
                        ? ''
                        : 'Click the card to add the optional information',
            },
            {
                key: 'SchoolLocation',
                validate: (value) =>
                    value
                        ? ''
                        : 'Click the card to add the optional information',
            },
            {
                key: 'Degree',
                validate: (value) =>
                    value
                        ? ''
                        : 'Click the card to add the optional information',
            },
            {
                key: 'Grade',
                validate: (value) =>
                    value
                        ? ''
                        : 'Click the card to add the optional information',
            },
        ],
        [],
    )

    const validationData = useMemo(
        () => ({
            id,
            SchoolName,
            SchoolWebSite,
            Field,
            StartDate,
            EndDate,
            SchoolLocation,
            Degree,
            Grade,
        }),
        [
            id,
            SchoolName,
            SchoolWebSite,
            Field,
            StartDate,
            EndDate,
            SchoolLocation,
            Degree,
            Grade,
        ],
    )

    const { className, errorMessage } = useValidation(
        validationData,
        isEditing,
        validationConfig,
    )

    const handleModalClose = async (educationChanges) => {
        setIsModalOpen(false)
        if (!educationChanges) return
        handleModalCloseGeneric(
            educationChanges,
            'Education',
            educationChanges.id,
        )
    }

    return (
        <>
            <div className={`education-container ${className}`} style={style}>
                <div
                    className={`education-entry ${
                        isEditing ? 'blue entry-editing' : ''
                    }`}
                    onClick={() =>
                        isEditing && !isContentOpen && setIsModalOpen(true)
                    }
                >
                    <div className="education-header">
                        <div className="education-image-name-container">
                            <h3 className="education-title">{SchoolName}</h3>
                        </div>
                        <VisibilityButton detailKeyOrPath={`Education.${id}`} />
                    </div>

                    <div className="education-infos">
                        <div className="education-field">
                            {Field}
                            {Degree ? ` · ${Degree}` : ''}
                        </div>

                        {Grade && (
                            <div className="education-grade">
                                Grade · {Grade}
                            </div>
                        )}

                        {SchoolLocation && (
                            <div className="education-location">
                                {SchoolLocation}
                            </div>
                        )}

                        {StartDate && EndDate && (
                            <div className="education-time">
                                {formatTime(StartDate, EndDate)}
                            </div>
                        )}
                    </div>
                </div>
                <Modal
                    isOpen={isModalOpen}
                    onClose={handleModalClose}
                    onAdd={(updatedUserData) => {
                        handleModalClose(updatedUserData)
                        setAddTrigger(true)
                        setTimeout(() => {
                            setAddTrigger(false)
                        }, 0)
                    }}
                    initialValue={{
                        id,
                        SchoolName,
                        SchoolWebSite,
                        Field,
                        StartDate,
                        EndDate,
                        SchoolLocation,
                        Degree,
                        Grade,
                    }}
                    className="modal-content wide"
                    Component={EducationModal}
                />
                <AddEducation
                    hasParent={true}
                    triggerAddEducation={addTrigger}
                />
            </div>
            {isEditing && errorMessage !== '' && (
                <ErrorMessage message={errorMessage} className={className} />
            )}
        </>
    )
}

export default EducationEntry
