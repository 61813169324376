import React, { useState } from 'react'
import { Modal } from '@modals'
import InfoCard from '@hire/JobDescription/JDPages/InfoCard'

const ListBlock = ({ header, details }) => {
    const [modalOpen, setModalOpen] = useState({})

    const handleModalOpen = (key) => {
        setModalOpen((prev) => ({ ...prev, [key]: true }))
    }

    const handleModalClose = (key) => {
        setModalOpen((prev) => ({ ...prev, [key]: false }))
    }

    return (
        <div className="jd-finalize-block">
            <h3 className="title">{header}</h3>
            <div className="jd-finalize-content">
                {details.map(
                    ({
                        label,
                        value,
                        key,
                        onChange,
                        placeholder,
                        inputType = null,
                        options = null,
                        formatFunction,
                    }) => (
                        <div key={key} className="jd-finalize-content-item">
                            <span>{label}</span>
                            <span onClick={() => handleModalOpen(key)}>
                                {formatFunction ? formatFunction(value) : value}
                            </span>
                            <Modal
                                isOpen={modalOpen[key]}
                                onClose={() => handleModalClose(key)}
                                Component={InfoCard}
                                className="modal-content"
                                initialProps={{
                                    header: label,
                                    value: value,
                                    onChange: onChange,
                                    placeholder: `${label}...`,
                                    inputType: inputType,
                                    options: options,
                                }}
                            />
                        </div>
                    ),
                )}
            </div>
        </div>
    )
}

export default ListBlock
