import languages from './languages.json'

export const bonusOptions = [
    {
        label: 'No Bonus',
        options: ['None']?.map((bonus) => ({ label: bonus, value: bonus })),
    },
    {
        label: 'Bonus',
        options: [
            'Performance Bonus',
            'Year End Bonus',
            '13th Month',
            '14th Month',
            'Production Bonus',
            'Holiday Bonus',
            'Signing Bonus',
            'Retention Bonus',
            'Business Travel Bonus',
        ]?.map((bonus) => ({ label: bonus, value: bonus })),
    },
    {
        label: 'Benefits',
        options: [
            'Medical Insurance',
            'Dental Insurance',
            'Vision Insurance',
            'Health Insurance for Dependents',
            'Retirement Plan',
            'Retirement Contribution',
            'ESOPs',
            'Paid Leave',
            'Sick Leave',
            'Life Insurance',
            'Life Insurance for Dependents',
            'Maternity Leave',
            'Paternity Leave',
            'On Site Childcare',
            'Childcare Sponsoring',
            'Profit Sharing Plans',
            'Relocation Package',
            'Housing Allowance',
            'Flight Tickets',
            'Company Trip',
            'Scholarship',
            'Training Program',
        ]?.map((benefit) => ({ label: benefit, value: benefit })),
    },
]

export const contractTypes = ['Full-time', 'Part-time', 'Flexible-time']?.map(
    (type) => ({ value: type, label: type }),
)

export const degrees = [
    'Diploma',
    "Bachelor's Degree",
    "Master's Degree",
    'Doctoral Degree',
    'Professional Degree',
    'Associate Degree',
    'Honorary Degree',
    'Other',
]?.map((degree) => ({ value: degree, label: degree }))

export const languagesOptions = Object.entries(languages)?.map(
    ([code, { name, nativeName }]) => ({
        value: name,
        label: `${name} (${nativeName})`,
    }),
)
