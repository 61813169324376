import React, { useState, useEffect } from 'react'
import { useUtils } from '@hooks'
import { handleInputChange, handleMultiInputChange } from '@utils'
import {
    InputField,
    CreatableField,
    SelectField,
    LocationSearch,
    TextField,
} from '@compUtils'

function CompanyModal({ initialValue = {}, onUpdate }) {
    const { options, filterIndustries } = useUtils()
    const [company, setCompany] = useState({ ...initialValue })

    const sizeOptions = [
        '1-10',
        '11-50',
        '51-200',
        '201-500',
        '501-1000',
        '1001-5000',
        '5001-10000',
        '10001+',
    ]

    const onInputChange = handleInputChange(setCompany)
    const onMultiInputChange = handleMultiInputChange(setCompany)

    useEffect(() => {
        if (onUpdate) {
            onUpdate(company)
        }
    }, [company, onUpdate])

    return (
        <div className={`new-component-experience`}>
            <div className="new-info-wrapper">
                <div className="new-info-header">
                    <span className="title">Company Informations</span>
                </div>
                <div className="new-info-exprerience-container">
                    <div className="new-info-experience-modal-container">
                        <div className="new-info-experience-modal">
                            <InputField
                                id="companyName"
                                label="Company Name"
                                type="text"
                                value={company.Name}
                                onChange={onInputChange('Name')}
                                placeholder="Company Name"
                                isValid={!!company.Name}
                            />
                            <InputField
                                id="website"
                                label="Company Website"
                                type="url"
                                value={company.WebSite}
                                onChange={onInputChange('WebSite')}
                                placeholder="Website"
                                isValid={!!company.WebSite}
                            />
                        </div>
                    </div>

                    <div className="new-info-experience-modal-container">
                        <div className="new-info-experience-modal">
                            <CreatableField
                                id="industry"
                                label="Industry"
                                isMulti
                                value={company.Industry}
                                onChange={onMultiInputChange('Industry')}
                                options={options}
                                placeholder="Select industries..."
                                isValid={company.Industry?.length > 0}
                                onInputChange={filterIndustries}
                            />
                            <SelectField
                                id="size"
                                label="Company Size"
                                value={company.Size}
                                onChange={onInputChange('Size')}
                                options={sizeOptions}
                                placeholder="Select size..."
                                isValid={!!company.Size}
                            />
                        </div>
                    </div>

                    <div className="new-info-experience-modal-container">
                        <label
                            className="new-info-experience-modal-label"
                            htmlFor={'location'}
                        >
                            Location
                        </label>
                        <LocationSearch
                            id="location"
                            value={{
                                value: company.Location,
                                label: company.Location,
                            }}
                            onChange={onInputChange('Location')}
                            placeholder="Location"
                        />
                    </div>

                    <div className="new-info-experience-modal-container">
                        <div className="new-info-experience-modal">
                            <TextField
                                id="description"
                                label="Company Description"
                                type="text"
                                value={company.Description}
                                onChange={onInputChange('Description')}
                                placeholder="Company Description"
                                isValid={!!company.Description}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompanyModal
