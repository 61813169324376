import React, { useState, useContext, useEffect } from 'react'

const AlertContext = React.createContext()

export function useAlert() {
    return useContext(AlertContext)
}

export function AlertProvider({ children }) {
    const [message, setMessage] = useState(null)
    const [alertType, setAlertType] = useState(null)
    const [visible, setVisible] = useState(false)

    const showAlert = (newMessage, alertType = 'alert-success') => {
        setMessage(newMessage)
        setAlertType(alertType)
        setVisible(true)
    }

    useEffect(() => {
        if (visible) {
            const hideTimeout = setTimeout(() => {
                setVisible(false)
            }, 3000)

            return () => clearTimeout(hideTimeout)
        }
    }, [visible])

    const alertClasses = `alert-message ${alertType} ${
        visible ? 'fadeIn' : 'fadeOut'
    }`

    return (
        <AlertContext.Provider value={{ showAlert }}>
            {children}
            <div className={alertClasses}>
                <span>{message}</span>
            </div>
        </AlertContext.Provider>
    )
}
