import React, { useRef, useMemo } from 'react'
import { useTmpData, useCV } from '@context'
import { formatSalary, formatList, formatNoticeTime } from '@utils'
import PreferedEntry from './PreferedEntry'
import { useValidation } from '@hooks'
import { ErrorMessage } from '@compUtils'

import './Prefered.css'

function Prefered() {
    const { isEditing } = useTmpData()
    const { cvData } = useCV()

    // handle edit/save
    const clickOutRef = useRef(null)

    const PreferedConfig = [
        {
            title: 'Industry',
            detailKey: 'PreferedIndustry',
            formatter: formatList,
        },
        {
            title: 'Function',
            detailKey: 'PreferedFunction',
            formatter: formatList,
        },
        {
            title: 'Compensation',
            detailKey: 'PreferedBaseSalary',
            formatter: formatSalary,
        },
        {
            title: 'Bonus and Benefits',
            detailKey: 'PreferedBonus',
            formatter: formatList,
        },
        {
            title: 'Engagement',
            detailKey: 'PreferedEngagement',
            formatter: formatList,
        },
        {
            title: 'Location',
            detailKey: 'PreferedLocation',
            formatter: formatList,
        },
        {
            title: 'Notice Time',
            detailKey: 'Availability',
            formatter: formatNoticeTime,
        },
    ]

    const validationConfig = useMemo(
        () => [
            {
                key: 'PreferedIndustry',
                validate: (value) =>
                    value
                        ? ''
                        : 'Click the card to add the optional information',
            },
            {
                key: 'PreferedFunction',
                validate: (value) =>
                    value
                        ? ''
                        : 'Click the card to add the optional information',
            },
            {
                key: 'PreferedBonus',
                validate: (value) =>
                    value === undefined || value?.length === 0
                        ? '!Click the card to add the missing information'
                        : '',
            },
            {
                key: 'PreferedEngagement',
                validate: (value) =>
                    value === undefined || value?.length === 0
                        ? '!Click the card to add the missing information'
                        : '',
            },
            {
                key: 'PreferedLocation',
                validate: (value) =>
                    value === undefined || value?.length === 0
                        ? '!Click the card to add the missing information'
                        : '',
            },
        ],
        [],
    )

    const { className, errorMessage } = useValidation(
        cvData,
        isEditing,
        validationConfig,
    )

    return (
        <div className="prefered" ref={clickOutRef}>
            <h3 className="title">PREFERENCES</h3>
            <div className={`prefered-container ${className}`}>
                {PreferedConfig?.map((detail) => (
                    <PreferedEntry
                        key={detail.detailKey}
                        title={detail.title}
                        detailKey={detail.detailKey}
                        formatter={detail.formatter}
                    />
                ))}
            </div>
            {isEditing && errorMessage !== '' && (
                <ErrorMessage message={errorMessage} className={className} />
            )}
        </div>
    )
}

export default Prefered
