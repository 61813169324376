import React, { useState, useMemo } from 'react'
import { useTmpData, useContentOpen } from '@context'
import { Modal } from '@modals'
import { ErrorMessage, AddLanguage, VisibilityButton } from '@compUtils'
import { useValidation, useCVUpdate } from '@hooks'
import LanguageModal from '@modals/Experiences/LanguageModal'

import './LanguageEntry.css'

const LanguageEntry = ({
    id,
    Language,
    Proficiency,
    Certificate,
    Grade,
    style,
}) => {
    const { isEditing } = useTmpData()
    const { handleModalCloseGeneric } = useCVUpdate()
    const { isContentOpen } = useContentOpen()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [addTrigger, setAddTrigger] = useState(false)

    const modalInitialValue = {
        id,
        Language,
        Proficiency,
        Certificate,
        Grade,
    }

    const validationConfig = useMemo(
        () => [
            {
                key: 'Language',
                validate: (value) =>
                    value
                        ? ''
                        : '!Click the card to add the missing information',
            },
            {
                key: 'Proficiency',
                validate: (value) =>
                    value
                        ? ''
                        : '!Click the card to add the missing informationn',
            },
        ],
        [],
    )

    const validationData = useMemo(
        () => ({
            id,
            Language,
            Proficiency,
            Certificate,
            Grade,
        }),
        [id, Language, Proficiency, Certificate, Grade],
    )

    const { className, errorMessage } = useValidation(
        validationData,
        isEditing,
        validationConfig,
    )

    const handleModalClose = async (languageChanges) => {
        setIsModalOpen(false)
        if (!languageChanges) return
        handleModalCloseGeneric(
            languageChanges,
            'Languages',
            languageChanges.id,
        )
    }

    return (
        <>
            <div className={`language-container ${className}`} style={style}>
                <div
                    className={`language-entry ${
                        isEditing ? 'blue entry-editing' : ''
                    }`}
                    onClick={(e) => {
                        if (!isEditing || isContentOpen) return
                        e.stopPropagation()
                        setIsModalOpen(true)
                    }}
                >
                    <div className="language-header">
                        <div className="language-title">
                            {Language}
                            {Proficiency && ` · ${Proficiency}`}
                        </div>
                        <VisibilityButton detailKeyOrPath={`Languages.${id}`} />
                    </div>

                    {(Certificate || Grade) && (
                        <div className="language-details">
                            <div className="language-certificate">
                                {Certificate && Certificate}
                                {Certificate && Grade && ` · ${Grade}`}
                            </div>
                        </div>
                    )}
                </div>

                <Modal
                    isOpen={isModalOpen}
                    onClose={handleModalClose}
                    onAdd={(updatedUserData) => {
                        handleModalClose(updatedUserData)
                        setAddTrigger(true)
                        setTimeout(() => {
                            setAddTrigger(false)
                        }, 0)
                    }}
                    initialValue={modalInitialValue}
                    className="modal-content wide"
                    Component={LanguageModal}
                />
                <AddLanguage hasParent={true} triggerAddLanguage={addTrigger} />
            </div>
            {isEditing && errorMessage !== '' && (
                <ErrorMessage message={errorMessage} className={className} />
            )}
        </>
    )
}

export default LanguageEntry
