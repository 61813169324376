import React, { useState, useCallback, memo } from 'react'
import Select from 'react-select'
import debounce from 'lodash.debounce'

import { customStylesValidIn, customStylesInvalidIn } from '@styles/styles'

const LocationSearch = memo(
    ({
        value,
        onChange,
        placeholder = 'Type to search for a city...',
        styleValid = customStylesValidIn,
        styleInvalid = customStylesInvalidIn,
    }) => {
        const [options, setOptions] = useState([
            { value: 'International', label: 'International' },
        ])

        const debouncedFetch = debounce((searchValue) => {
            if (searchValue && searchValue?.length > 2) {
                fetch(
                    `https://nominatim.openstreetmap.org/search?q=${searchValue}&format=json&limit=10`,
                )
                    .then((response) => response.json())
                    .then((data) => {
                        const formattedData = data?.map((city) => ({
                            value: city.display_name,
                            label: city.display_name,
                        }))

                        setOptions([
                            { value: 'International', label: 'International' },
                            ...formattedData,
                        ])
                    })
            } else {
                setOptions([{ value: 'International', label: 'International' }])
            }
        }, 300)

        const fetchCities = useCallback(
            (searchValue) => {
                debouncedFetch(searchValue)
            },
            [debouncedFetch],
        )

        const handleInputChange = (searchValue) => {
            fetchCities(searchValue)
        }

        return (
            <Select
                value={value}
                onInputChange={handleInputChange}
                onChange={onChange}
                options={options}
                isSearchable
                placeholder={placeholder}
                styles={value ? styleValid : styleInvalid}
                filterOption={null}
                openMenuOnFocus={true}
            />
        )
    },
)

export default LocationSearch
