import React, { useState, useRef } from 'react'
import { submitFeedback } from '@utils'
import { useAuth, useUserData, useTmpData } from '@context'
import { autoGrowText } from '@utils'

import { Upload } from '@assets'

function FeedbackModal({ onClose }) {
    const { userData } = useUserData()
    const { feedback, setFeedback, feedbackImages, setFeedbackImages } =
        useTmpData()
    const { token } = useAuth()
    const [isSent, setIsSent] = useState(false)
    const [isSending, setIsSending] = useState(false)
    const [sendError, setSendError] = useState(false)
    const textAreaRef = useRef(null)
    const [selectedFileNames, setSelectedFileNames] = useState(
        feedbackImages?.map((image) => image.name) || [],
    )

    const handleImageChange = (event) => {
        if (event.target.files) {
            const newFilesArray = Array.from(event.target.files)
            const newFileNames = newFilesArray.map((file) => file.name)

            setFeedbackImages((prevImages) => [...prevImages, ...newFilesArray])
            setSelectedFileNames((prevFileNames) => [
                ...prevFileNames,
                ...newFileNames,
            ])
        }
    }

    function handleSend() {
        setIsSending(true)

        const formData = new FormData()
        formData.append('feedback', feedback)
        feedbackImages.forEach((file) => {
            formData.append(`images`, file)
        })
        formData.append('email', userData.Email)

        submitFeedback(formData, token)
            .then((success) => {
                setIsSending(false)
                if (success) {
                    setIsSent(true)
                    setTimeout(onClose, 2500)
                    setFeedbackImages([])
                    setFeedback('')
                } else {
                    setSendError(true)
                }
            })
            .catch((error) => {
                setIsSending(false)
                setSendError(true)
                if (process.env.REACT_APP_NODE_ENV === 'development')
                    console.log(error)
            })
    }

    const centerTextStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
    }

    if (isSending) {
        return (
            <div className={`new-component-experience`} style={centerTextStyle}>
                <span className="feedback">Sending feedback...</span>
            </div>
        )
    }

    if (isSent || sendError) {
        const message = isSent
            ? 'Thank you for your feedback!'
            : 'Error sending feedback...'
        return (
            <div className={`new-component-experience`} style={centerTextStyle}>
                <span className="feedback">{message}</span>
            </div>
        )
    }

    const onDrop = (e) => {
        e.preventDefault()
        const files = e.dataTransfer.files
        if (files) {
            const filesArray = Array.from(files)
            handleImageChange({ target: { files: filesArray } })
        }
    }

    const onClick = () => {
        const fileInput = document.getElementById('fileInput')
        fileInput.click()
    }

    function handlePaste(event) {
        const items = (event.clipboardData || event.originalEvent.clipboardData)
            .items
        for (const item of items) {
            if (item.kind === 'file') {
                const file = item.getAsFile()
                if (file && file.type.startsWith('image/')) {
                    handleImageChange({ target: { files: [file] } })
                }
            }
        }
    }

    return (
        <div className={`new-component-experience`}>
            <div className="new-info-wrapper">
                <div className="new-info-header">
                    <span className="title">Feedback</span>
                </div>
                <div className="new-info-exprerience-container">
                    <div className="new-info-experience-modal-container">
                        <textarea
                            ref={textAreaRef}
                            value={feedback}
                            onChange={(e) => {
                                setFeedback(e.target.value)
                            }}
                            onInput={(e) => autoGrowText(e.target, 21)}
                            onPaste={handlePaste}
                            placeholder={`Enter your feedback...\nYou can upload a screenshot of the issue in the grey area below...`}
                            style={{ maxHeight: '30vh' }}
                        />
                    </div>
                </div>
                <div className="selected-file-names">
                    {selectedFileNames.map((name, index) => (
                        <span key={index} className="selected-file-name">
                            {name}
                            <br />
                        </span>
                    ))}
                </div>
                <div
                    className="dropzone"
                    id="dropzone"
                    onDrop={onDrop}
                    onDragOver={(e) => e.preventDefault()}
                    onClick={onClick}
                >
                    <span className="dropzone-span">
                        Drop file here or click to upload
                    </span>
                    <img src={Upload} alt="upload" />
                    <input
                        type="file"
                        id="fileInput"
                        hidden
                        onChange={handleImageChange}
                        accept="image/png, image/jpeg"
                        multiple
                    />
                </div>
                <div className="new-info-experience-modal">
                    <div className="secondary-content-feedback">
                        <button onClick={onClose}>Close</button>
                        <button onClick={handleSend}>Send</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeedbackModal
