import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getCompaniesByUser } from '@utils'
import CompanyCard from './companyCard'

import '@styles/hire.css'

function Companies() {
    const navigate = useNavigate()
    const {
        data: companies,
        isLoading,
        isError,
        error,
    } = useQuery('companies', getCompaniesByUser)
    const [selectedIndex, setSelectedIndex] = useState(-1)

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowDown') {
                setSelectedIndex(
                    (prevIndex) => (prevIndex + 1) % companies.length,
                )
            } else if (event.key === 'ArrowUp') {
                setSelectedIndex(
                    (prevIndex) =>
                        (prevIndex - 1 + companies.length) % companies.length,
                )
            } else if (event.key === 'Enter' && selectedIndex !== -1) {
                navigate(`/hire/company/${companies[selectedIndex]._id}`, {
                    state: companies[selectedIndex],
                })
            }
        }
        document.addEventListener('keydown', handleKeyDown)
        return () => document.removeEventListener('keydown', handleKeyDown)
    }, [companies, navigate, selectedIndex])

    if (isLoading) {
        return (
            <div>
                <h1>Loading...</h1>
            </div>
        )
    }

    if (isError) {
        return <span>Error: {error.message}</span>
    }

    return (
        <div className="hire-companies">
            {companies.map((company, index) => (
                <CompanyCard
                    key={company._id}
                    company={company}
                    isSelected={index === selectedIndex}
                />
            ))}
        </div>
    )
}

export default Companies
