import React from 'react'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { useParams, useNavigate } from 'react-router-dom'

import {
    getJDById,
    formatSalary,
    formatList,
    bonusOptions,
    contractTypes,
    formatLocation,
    fetchNationalities,
    techSkills,
    languagesOptions,
} from '@utils'
import { useJD } from '@context'
import EditableBlock from './EditableBlock'
import ListBlock from './ListlBlock'

function JDFinalize() {
    const { jobId } = useParams()
    const navigate = useNavigate()
    const { jd, setInitialJDData, updateJD } = useJD()

    const { isLoading, isError, error } = useQuery(
        `jobDescription-${jobId}`,
        () => getJDById(jobId),
        {
            enabled: !jd,
            onSuccess: (data) => {
                setInitialJDData(data)
            },
        },
    )

    const { mutate } = useMutation((updatedJD) => updateJD(updatedJD), {
        onError: (error) => {
            toast.error(error.response.data.message)
        },
    })

    const { data: nationalities } = useQuery(
        'nationalities',
        fetchNationalities,
        { staleTime: Infinity },
    )

    if (isLoading) {
        return <h1>Loading...</h1>
    }

    if (isError) {
        toast.error(error.response.data.message)
        navigate('/hire')
        return null
    }

    return (
        <div className="jd-finalize">
            <EditableBlock
                header="Title"
                value={jd.Title}
                placeholder="Title..."
                onValueChange={(value) => mutate({ ...jd, Title: value })}
            />
            <EditableBlock
                header="Summary"
                value={jd.Summary}
                placeholder="Summary..."
                onValueChange={(value) => mutate({ ...jd, Summary: value })}
            />
            <ListBlock
                header="Details"
                details={[
                    {
                        label: 'Department',
                        value: jd.Department,
                        key: 'Department',
                        onChange: (e) =>
                            mutate({ ...jd, Department: e.target.value }),
                    },
                    {
                        label: 'Supervisor',
                        value: jd.Supervisor,
                        key: 'Supervisor',
                        onChange: (e) =>
                            mutate({ ...jd, Supervisor: e.target.value }),
                    },
                    {
                        label: 'Subordinates',
                        value: jd.Subordinates,
                        key: 'Subordinates',
                        onChange: (e) =>
                            mutate({ ...jd, Subordinates: e.target.value }),
                    },
                    {
                        label: 'Dotted Line Reporting',
                        value: jd.ReportingTo,
                        key: 'ReportingTo',
                        onChange: (e) =>
                            mutate({ ...jd, ReportingTo: e.target.value }),
                    },
                    {
                        label: 'Salary',
                        value: jd.Salary,
                        key: 'Salary',
                        onChange: (e, field) => {
                            mutate({
                                ...jd,
                                Salary: {
                                    ...jd.Salary,
                                    [field]: e.target.value,
                                },
                            })
                        },
                        inputType: 'salary',
                        formatFunction: formatSalary,
                    },
                    {
                        label: 'Bonus',
                        value: jd.Bonus,
                        key: 'Bonus',
                        onChange: (e) => mutate({ ...jd, Bonus: e }),
                        inputType: 'creatable-multi',
                        options: bonusOptions,
                        formatFunction: formatList,
                    },
                    {
                        label: 'Engagement Type',
                        value: jd.EngagementType,
                        key: 'EngagementType',
                        onChange: (e) => mutate({ ...jd, EngagementType: e }),
                        inputType: 'creatable',
                        options: contractTypes,
                    },
                    {
                        label: 'Location',
                        value: jd.Location,
                        key: 'Location',
                        onChange: (e) => mutate({ ...jd, Location: e.value }),
                        inputType: 'location',
                        formatFunction: formatLocation,
                    },
                    {
                        label: 'Working Hours',
                        value: jd.WorkingHours,
                        key: 'WorkingHours',
                        onChange: (e) =>
                            mutate({ ...jd, WorkingHours: e.target.value }),
                        inputType: 'number',
                        formatFunction: (value) => `${value} hours`,
                    },
                ]}
            />
            <ListBlock
                header="Requirements"
                details={[
                    {
                        label: 'Age',
                        value: jd.Age,
                        key: 'Age',
                        onChange: (e, field) =>
                            mutate({
                                ...jd,
                                Age: { ...jd.Age, [field]: e.target.value },
                            }),
                        inputType: 'age',
                        formatFunction: (value) =>
                            `${value.MinAge} - ${value.MaxAge} years`,
                    },
                    {
                        label: 'Gender',
                        value: jd.Gender,
                        key: 'Gender',
                        onChange: (e) => mutate({ ...jd, Gender: e }),
                        inputType: 'button',
                        options: [
                            { value: 'Male', label: 'Male' },
                            { value: 'Female', label: 'Female' },
                            { value: 'Any', label: 'Any' },
                        ],
                    },
                    {
                        label: 'Nationality',
                        value: jd.Nationality,
                        key: 'Nationality',
                        onChange: (e) =>
                            mutate({ ...jd, Nationality: e.value }),
                        inputType: 'nationality',
                        options: nationalities,
                    },
                    {
                        label: 'Candidate Location',
                        value: jd.CandidateLocation,
                        key: 'CandidateLocation',
                        onChange: (e) =>
                            mutate({ ...jd, CandidateLocation: e.value }),
                        inputType: 'location',
                        formatFunction: formatLocation,
                    },
                    {
                        label: 'Education',
                        value: jd.Education,
                        key: 'Education',
                        onChange: (e, field) =>
                            mutate({
                                ...jd,
                                Education: { ...jd.Education, [field]: e },
                            }),
                        inputType: 'education',
                        formatFunction: (value) =>
                            `${value.Degree} - ${value.Field}`,
                    },
                    {
                        label: 'Certification',
                        value: jd.Certification,
                        key: 'Certification',
                        onChange: (e, field) =>
                            mutate({
                                ...jd,
                                Certification: {
                                    ...jd.Certification,
                                    [field]: e,
                                },
                            }),
                        inputType: 'certification',
                        formatFunction: (value) =>
                            `${value.Issuer} - ${value.Certificate}`,
                    },
                    {
                        label: 'TechnicalSkills',
                        value: jd.TechnicalSkills,
                        key: 'TechnicalSkills',
                        onChange: (e) => mutate({ ...jd, TechnicalSkills: e }),
                        inputType: 'creatable-multi',
                        formatFunction: formatList,
                        options: techSkills.options,
                    },
                    {
                        label: 'Languages',
                        value: jd.Languages,
                        key: 'Languages',
                        onChange: (e) => mutate({ ...jd, Languages: e }),
                        inputType: 'creatable-multi',
                        formatFunction: formatList,
                        options: languagesOptions,
                    },
                ]}
            />
        </div>
    )
}

export default JDFinalize
