import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useCV, useAlert } from '@context'
import { useMobileScreen } from '@hooks'
import { drawLines } from '@utils'

import { RegistrationRoutes } from '@registration'
import {
    Experiences,
    Information,
    Highlight,
    Summary,
    Prefered,
    Education,
    Language,
} from '@sections'

import '@styles/profile.css'

function Profile() {
    const { isLoading, accessibleSteps, cvData } = useCV()
    const { pathname } = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        if (!isLoading && !cvData?.Finalized && pathname === '/profile') {
            if (accessibleSteps.finalize) {
                navigate('/profile/finalize')
            } else if (accessibleSteps.preference) {
                navigate('/profile/preferences')
            } else if (accessibleSteps.information) {
                navigate('/profile/information')
            } else {
                navigate('/profile/start')
            }
        }
    }, [isLoading, accessibleSteps, cvData, navigate, pathname])

    useEffect(() => {
        drawLines(accessibleSteps)

        window.addEventListener('resize', drawLines)

        return () => window.removeEventListener('resize', drawLines)
    }, [accessibleSteps])

    if (isLoading) {
        return <div>Loading...</div>
    }

    if (!cvData.Finalized) {
        return (
            <div className="new-user">
                <ProgressBar />
                <RegistrationRoutes accessibleSteps={accessibleSteps} />
            </div>
        )
    }

    return (
        <>
            <div className="profile">
                <Information />
                <Prefered />
                <Summary />
                <Highlight />
                <Experiences />
                <Education />
                <Language />
            </div>
        </>
    )
}

function ProgressBar() {
    const isMobile = useMobileScreen()
    const { accessibleSteps } = useCV()
    const { showAlert } = useAlert()
    const navigate = useNavigate()

    const steps = [
        { name: 'Start', key: 'start', path: '/profile/start' },
        {
            name: 'Information',
            key: 'information',
            path: '/profile/information',
        },
        {
            name: 'Preferences',
            key: 'preference',
            path: '/profile/preferences',
        },
        { name: 'Finalize', key: 'finalize', path: '/profile/finalize' },
    ]

    const handleStepClick = (path, key, index) => {
        const isCurrentStepAccessible = accessibleSteps[key]
        const isNextStepAccessible = accessibleSteps[steps[index - 1]?.key]

        if (isCurrentStepAccessible || isNextStepAccessible) {
            navigate(path)
        } else {
            showAlert(
                'Please fill all the sections before continuing.',
                'alert-error',
            )
        }
    }

    const renderStep = (step, index) => {
        const isCurrentStepActive = accessibleSteps[step.key]
        const isPreviousStepActive = accessibleSteps[steps[index - 1]?.key]
        const isStepAccessible = isCurrentStepActive || isPreviousStepActive

        return (
            <div
                key={index}
                className={`step ${isStepAccessible ? 'active' : ''}`}
                data-step={index + 1}
                onClick={() => handleStepClick(step.path, step.key, index)}
                style={{
                    cursor: isStepAccessible ? 'pointer' : 'default',
                }}
            >
                {step.name}
                <span className={isStepAccessible ? 'active' : ''}>
                    {isCurrentStepActive ? '✓' : (index + 1).toString()}
                </span>
            </div>
        )
    }

    if (isMobile) {
        return (
            <div className="progress-bar-wrapper">
                {steps.map(
                    (step, index) =>
                        accessibleSteps[step.key] && renderStep(step, index),
                )}
            </div>
        )
    }

    return <div className="progress-bar-wrapper">{steps.map(renderStep)}</div>
}

export default Profile
