import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useCV, useAlert } from '@context'
import {
    Avaibility,
    Function,
    Industry,
    PreferedLocation,
    PreferedBaseSalary,
    PreferedBonus,
    PreferedEngagement,
} from '@modals/Preference'

function Preferences() {
    const { accessibleSteps } = useCV()
    const { showAlert } = useAlert()
    const navigate = useNavigate()

    return (
        <div className="new-user">
            <div className="new-user-container">
                <Industry />
                <Function />
            </div>

            <PreferedBaseSalary />

            <div className="new-user-container">
                <PreferedBonus />
                <PreferedEngagement />
            </div>

            <div className="new-user-container">
                <PreferedLocation />
                <Avaibility />
            </div>
            <div className="new-user-button">
                <div className="hn-buttons-container">
                    <button
                        className="hn-button-blue"
                        onClick={() => {
                            if (accessibleSteps.preference) {
                                navigate('/profile/finalize')
                            } else {
                                showAlert(
                                    'Please fill all the sections before continuing.',
                                    'alert-error',
                                )
                            }
                        }}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Preferences
