import React, { useState, useEffect } from 'react'
import { useCV } from '@context/CVContext'
import Creatable from 'react-select/creatable'
import { functionData } from '@utils'
import { VisibilityButton } from '@compUtils'

import { customStylesValid, customStylesFacultative } from '@styles/styles'

function Function({ isModal = false }) {
    const { cvData, updateCV } = useCV()
    const [selectedFunctions, setSelectedFunctions] = useState(
        cvData.PreferedFunction?.map((func) => ({
            value: func,
            label: func,
        })) || [],
    )
    const [options, setOptions] = useState([])

    useEffect(() => {
        setOptions(
            functionData.Functions?.map((func) => ({
                value: func,
                label: func,
            })),
        )
    }, [])

    const filterFunctions = (inputValue) => {
        let firstWordMap = new Map()
        let filteredOptions

        if (inputValue.length < 2) {
            // Grouper par le premier mot et garder seulement la première option pour chaque groupe
            functionData.Functions.forEach((func) => {
                const firstWord = func.split(' ')[0].toLowerCase()
                if (!firstWordMap.has(firstWord)) {
                    firstWordMap.set(firstWord, func)
                }
            })
            filteredOptions = Array.from(firstWordMap.values())
        } else {
            // Filtrage normal pour les saisies de 2 caractères ou plus
            filteredOptions = functionData.Functions.filter((func) =>
                func.toLowerCase().includes(inputValue.toLowerCase()),
            )
        }

        setOptions([
            ...filteredOptions.map((func) => ({ value: func, label: func })),
        ])
    }

    const handleFunctionChange = (selectedOptions) => {
        setSelectedFunctions(selectedOptions || [])
        updateCV((prevData) => ({
            ...prevData,
            PreferedFunction: selectedOptions?.map((option) => option.value),
        }))
    }

    return (
        <div
            className={`new-component ${
                selectedFunctions?.length === 0
                    ? 'facultative-border'
                    : 'valid-border'
            }`}
            style={isModal ? { width: 'auto' } : {}}
        >
            <div className="new-info-wrapper">
                <div className="new-info-header">
                    Function
                    <VisibilityButton detailKeyOrPath={'PreferedFunction'} />
                </div>
                <div className="new-info-container">
                    <Creatable
                        isMulti
                        value={selectedFunctions}
                        onInputChange={filterFunctions}
                        onChange={handleFunctionChange}
                        options={options}
                        isSearchable
                        placeholder="Select functions..."
                        styles={{
                            ...(selectedFunctions?.length > 0
                                ? customStylesValid
                                : customStylesFacultative),
                        }}
                        openMenuOnFocus={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default Function
