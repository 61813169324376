import React, { useState } from 'react'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import AsyncSelect from 'react-select/async'
import { getPublicUserData, addEmployee, searchUsers } from '@utils'
import { toast } from 'react-toastify'
import { EmployeeCard } from '@compUtils'

import './collabModal.css'

function CollaboratorModal({ initialValue = {} }) {
    const queryClient = useQueryClient()

    const [selectedUser, setSelectedUser] = useState(null)

    const fetchEmployees = async () => {
        const employeesData = await Promise.all(
            initialValue.Employees.map(async (employee) => {
                try {
                    const publicData = await getPublicUserData(employee.userId)
                    return { ...publicData, role: employee.role }
                } catch (error) {
                    console.error('Error fetching employee data:', error)
                    return null
                }
            }),
        )
        return employeesData.filter(Boolean)
    }

    const employeeIds = initialValue.Employees.map((e) => e.userId)
        .sort()
        .join(',')
    const queryKey = [`employees-${initialValue._id}`, employeeIds]
    const {
        data: employees,
        isLoading,
        error,
    } = useQuery(queryKey, fetchEmployees)

    const addEmployeeMutation = useMutation(
        (newEmployee) => addEmployee(initialValue._id, newEmployee),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(`companies`)
                toast.success('Employee added successfully')
                setSelectedUser(null)
            },
            onError: (error) => {
                toast.error(error.response.data.message)
            },
        },
    )

    const handleAddEmployee = () => {
        if (selectedUser) {
            addEmployeeMutation.mutate({
                userId: selectedUser.userId,
                role: selectedUser.role,
            })
        }
    }

    const loadOptions = async (inputValue) => {
        if (!inputValue) return []

        try {
            const users = await searchUsers(inputValue)
            const filteredUsers = users.filter(
                (user) =>
                    !initialValue.Employees.find(
                        (emp) => emp.userId === user.userId,
                    ),
            )
            return filteredUsers.map((user) => ({
                label: `${user.Name} ${user.Surname} (${user.Email})`,
                value: user.userId,
            }))
        } catch (error) {
            console.error('Error searching users:', error)
            return []
        }
    }

    const handleSelectChange = async (selectedOption) => {
        const userData = await getPublicUserData(selectedOption.value)
        setSelectedUser({ ...userData, role: 'Viewer' })
    }

    if (isLoading) return <div>Loading...</div>
    if (error) return <div>An error occurred: {error.message}</div>

    return (
        <div className={`new-component-experience`}>
            <div className="new-info-wrapper">
                <AsyncSelect
                    cacheOptions
                    loadOptions={loadOptions}
                    defaultOptions
                    placeholder="Search for users..."
                    onChange={handleSelectChange}
                />

                {selectedUser && (
                    <>
                        <EmployeeCard
                            employee={selectedUser}
                            setEmployee={setSelectedUser}
                            index={0}
                        />
                        <button
                            className="add-employee-btn"
                            onClick={handleAddEmployee}
                        >
                            {addEmployeeMutation.isLoading
                                ? 'Adding'
                                : 'Add Employee'}
                            {addEmployeeMutation.isLoading && (
                                <span className="dots">...</span>
                            )}
                        </button>
                    </>
                )}
                {employees.map((employee, index) => (
                    <EmployeeCard
                        key={index}
                        employee={employee}
                        index={index}
                        company={initialValue}
                    />
                ))}
            </div>
        </div>
    )
}

export default CollaboratorModal
