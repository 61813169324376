import React, { useState, useEffect, useRef } from 'react'

import { RemoveIcon, AddHighlight } from '@assets'

const SCKRow = ({ label, items, isEditing, onRemoveItem, onNewItemSubmit }) => {
    const [newItem, setNewItem] = useState('')
    const [isAdding, setIsAdding] = useState(false)
    const inputRef = useRef(null)

    const handleCreate = (inputValue) => {
        const newItem = { Name: inputValue }
        onNewItemSubmit(newItem, label)
        setIsAdding(false)
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleCreate(newItem)
            setNewItem('')
        }
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                setIsAdding(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [inputRef])

    return (
        <div className="highlight-row-container">
            <span className="highlight-label">{label}</span>
            {items.map((item, index) => (
                <div key={index} className="badge skill-badge">
                    <span className="skill-name">{item.Name}</span>
                    {isEditing && (
                        <button
                            className="close-icon"
                            onClick={() => onRemoveItem(item)}
                        >
                            <img
                                src={RemoveIcon}
                                className="highlight-close"
                                alt="remove"
                            />
                        </button>
                    )}
                </div>
            ))}
            {isEditing && (
                <button
                    className="highlight-add-btn"
                    onClick={() => setIsAdding(true)}
                >
                    {isAdding && (
                        <input
                            ref={inputRef}
                            className="creatable-container"
                            type="text"
                            value={newItem}
                            onChange={(e) => setNewItem(e.target.value)}
                            onKeyDown={handleKeyDown}
                            placeholder={`Add new ${label.toLowerCase()}`}
                        />
                    )}
                    <img
                        src={AddHighlight}
                        className="highlight-add-icon"
                        alt="add"
                    />
                </button>
            )}
        </div>
    )
}

export default SCKRow
