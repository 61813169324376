import { dateToComparableNumber } from '@utils'

export const joinWithSeparator = (items, separator = ' · ') =>
    items.filter(Boolean).join(separator)

export const isVisible = (visibility) => visibility !== 'Private'

export const filterListByVisibility = (list, visibility) => {
    return list.filter(
        (item) =>
            !visibility.some(
                (visItem) =>
                    visItem._id === item._id &&
                    visItem.visibility === 'Private',
            ),
    )
}

export const filterExperiencesByVisibility = (
    experiences,
    compVisibility,
    expVisibility,
) => {
    return experiences
        .map((company) => {
            let companyCopy = { ...company }

            const isCompanyPrivate = compVisibility.some(
                (visItem) =>
                    visItem._id === company._id &&
                    visItem.visibility === 'Private',
            )

            if (isCompanyPrivate) {
                companyCopy = {
                    ...companyCopy,
                    CompanyName: 'Confidential',
                    CompanyWebSite: '',
                }
            }

            const filteredExperiencesInCompany =
                company.ExperiencesInCompany.filter(
                    (experience) =>
                        !expVisibility.some(
                            (visItem) =>
                                visItem._id === experience._id &&
                                visItem.visibility === 'Private',
                        ),
                )
                    .map((experience) => ({ ...experience }))
                    .sort((a, b) => {
                        const aEndDate = dateToComparableNumber(a.EndDate)
                        const bEndDate = dateToComparableNumber(b.EndDate)
                        return bEndDate - aEndDate
                    })

            companyCopy = {
                ...companyCopy,
                ExperiencesInCompany: filteredExperiencesInCompany,
            }

            return companyCopy
        })
        .filter((company) => company.ExperiencesInCompany.length > 0)
}
