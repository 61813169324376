import React, { useEffect, memo } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useReleaseNote } from '@hooks'

const ReleaseNotes = () => {
    const { releases, detailsMessage, lastRel } = useReleaseNote()

    useEffect(() => {
        const latestReleaseStored = localStorage.getItem('latestRelease')

        if (!lastRel || latestReleaseStored === lastRel) return

        const showDetails = () => {
            setTimeout(() => {
                toast(detailsMessage, {
                    position: 'bottom-center',
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progressStyle: { background: '#007AFF' },
                    className: 'custom-toast',
                })
            }, 500)
        }

        toast(
            <div onClick={showDetails}>
                {`New version v${lastRel} released! Click to see details.`}
            </div>,
            {
                position: 'bottom-center',
                autoClose: 10000,
                progressStyle: { background: '#007AFF' },
                className: 'custom-toast',
            },
        )
        localStorage.setItem('latestRelease', lastRel)
    }, [detailsMessage, lastRel])

    if (releases.length === 0) {
        return null
    }

    return <ToastContainer />
}

export default memo(ReleaseNotes)
