import React from 'react'
import { useAuth } from '@context/AuthContext'

import { Logout, Cotalent } from '@assets'

import './LoadingPage.css'

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="200"
            height="200"
            fill="none"
            viewBox="0 0 2000 2000"
        >
            <circle
                id="bubble1"
                cx="258.359"
                cy="140.678"
                r="45.593"
                fill="#007AFF"
                fillOpacity="0.5"
            ></circle>
            <path
                id="bubble12"
                fill="#007AFF"
                fillOpacity="0.5"
                d="M1371.43 1441.59c0 25.18-20.41 45.59-45.59 45.59-25.18 0-45.6-20.41-45.6-45.59 0-25.18 20.42-45.59 45.6-45.59s45.59 20.41 45.59 45.59z"
            ></path>
            <circle
                id="bubble2"
                cx="1099.7"
                cy="45.593"
                r="45.593"
                fill="#007AFF"
                fillOpacity="0.5"
            ></circle>
            <circle
                id="bubble3"
                cx="1954.41"
                cy="590.526"
                r="45.593"
                fill="#007AFF"
                fillOpacity="0.5"
            ></circle>
            <circle
                id="bubble4"
                cx="689.362"
                cy="231.863"
                r="91.185"
                fill="#007AFF"
                fillOpacity="0.6"
            ></circle>
            <circle
                id="bubble5"
                cx="1580.55"
                cy="1694.48"
                r="91.185"
                fill="#007AFF"
                fillOpacity="0.6"
            ></circle>
            <circle
                id="bubble6"
                cx="91.185"
                cy="1396"
                r="91.185"
                fill="#007AFF"
                fillOpacity="0.6"
            ></circle>
            <circle
                id="bubble7"
                cx="1762.92"
                cy="1022.74"
                r="182.371"
                fill="#007AFF"
                fillOpacity="0.7"
            ></circle>
            <circle
                id="bubble8"
                cx="246.809"
                cy="693.261"
                r="182.371"
                fill="#007AFF"
                fillOpacity="0.7"
            ></circle>
            <ellipse
                id="bubble9"
                cx="780.547"
                cy="1726.45"
                fill="#007AFF"
                fillOpacity="0.8"
                rx="273.556"
                ry="273.556"
            ></ellipse>
            <ellipse
                id="bubble10"
                cx="1508.21"
                cy="362.562"
                fill="#007AFF"
                fillOpacity="0.9"
                rx="273.556"
                ry="273.556"
            ></ellipse>
            <ellipse
                id="bubble11"
                cx="911.854"
                cy="951.012"
                fill="#007AFF"
                rx="455.927"
                ry="455.927"
            ></ellipse>
        </svg>
    )
}

function LoadingPage() {
    const { logout } = useAuth()

    const handleLogout = () => {
        logout()
    }

    return (
        <div className="loading-container">
            <div className="logo-container">
                <Icon />
                <img src={Cotalent} alt="cotalent" className="cotalent" />
            </div>
            <div className="loading-text">Loading your data...</div>
            <div className="spinner"></div>

            <button className="loading-logout-button" onClick={handleLogout}>
                <img src={Logout} alt="Lougout" className="logout-image" />
                Log Out
            </button>
        </div>
    )
}

export default LoadingPage
