import React from 'react'
import { formatLocation } from '@utils'
import JDCardDots from './jdCardDots'
import { useJD } from '@context'

import '../Company/companyCard.css'

function JDCard({ job, isSelected }) {
    const { setInitialJDData, navigateToMostAdvancedStep } = useJD()

    const handleClick = () => {
        setInitialJDData(job)
        navigateToMostAdvancedStep(job._id)
    }

    const cardStyle = isSelected ? 'company-card selected' : 'company-card'

    return (
        <div className={cardStyle} onClick={handleClick}>
            <div className="company-card-infos">
                <div className="company-card-infos-header">
                    <h2 className="experience-title">{job.Title}</h2>
                    <JDCardDots job={job} />
                </div>
                <span>{job.Department}</span>
                <span>{formatLocation(job.Location)}</span>
            </div>
        </div>
    )
}

export default JDCard
