import { useState, useCallback } from 'react'
import { industryData } from '@utils'

const useUtils = () => {
    const [options, setOptions] = useState(() =>
        industryData.industries.map((ind) => ({ value: ind, label: ind })),
    )

    const filterIndustries = useCallback((inputValue) => {
        const filtered = industryData.industries.filter((ind) =>
            ind.toLowerCase().includes(inputValue.toLowerCase()),
        )
        setOptions(filtered.map((ind) => ({ value: ind, label: ind })))
    }, [])

    return { options, filterIndustries }
}

export default useUtils
