import React from 'react'

function Join() {
    return (
        <div>
            <h1>Coming Soon !</h1>
        </div>
    )
}

export default Join
