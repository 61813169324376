import React, { useState } from 'react'
import { useCV } from '@context/CVContext'
import { VisibilityButton } from '@compUtils'
import { emailRegex } from '@utils'

function Email({ isModal = false }) {
    const { cvData, updateCV } = useCV()
    const [email, setEmail] = useState(cvData.Email || '')

    const [isEmailValid, setEmailValid] = useState(emailRegex.test(email))

    const handleEmailChange = (value) => {
        setEmail(value || '')
        updateCV((prevData) => ({ ...prevData, Email: value }))

        setEmailValid(emailRegex.test(value))
    }

    return (
        <div
            className={`new-component ${
                !isEmailValid ? 'invalid-border' : 'valid-border'
            }`}
            style={isModal ? { width: 'auto' } : {}}
        >
            <div className="new-info-wrapper">
                <div className="new-info-header">
                    Email Address
                    <VisibilityButton detailKeyOrPath={'Email'} />
                </div>
                <div className="new-info-container">
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => handleEmailChange(e.target.value)}
                    />
                </div>
            </div>
        </div>
    )
}

export default Email
