import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useCV, useAlert } from '@context'
import {
    BirthDate,
    CurrentBaseSalary,
    CurrentBonus,
    Gender,
    Location,
    Name,
    Nationality,
    Phone,
} from '@modals/Information'

import './Information.css'

function Information() {
    const { accessibleSteps } = useCV()
    const { showAlert } = useAlert()
    const navigate = useNavigate()

    return (
        <div className="new-user">
            <div className="new-user-container">
                <Name />
                <BirthDate />
            </div>
            <div className="new-user-container">
                <Gender />
                <Phone />
            </div>
            <div className="new-user-container">
                <Location />
                <Nationality />
            </div>
            <div className="new-user-container">
                <CurrentBaseSalary />
                <CurrentBonus />
            </div>
            <div className="new-user-button">
                <div className="hn-buttons-container">
                    <button
                        className="hn-button-blue"
                        onClick={() => {
                            if (accessibleSteps.information) {
                                navigate('/profile/preferences')
                            } else {
                                showAlert(
                                    'Please fill all the sections before continuing.',
                                    'alert-error',
                                )
                            }
                        }}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Information
