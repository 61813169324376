import React, { useMemo } from 'react'
import {
    PDFViewer,
    Document,
    Font,
    Page,
    Text,
    Link,
    View,
    Image,
} from '@react-pdf/renderer'
import { useQuery } from 'react-query'
import {
    formatTime,
    joinWithSeparator,
    isVisible,
    filterListByVisibility,
    filterExperiencesByVisibility,
    extractMainDomain,
    getProfilePicture,
    dateToComparableNumber,
} from '@utils'
import { styles } from '@styles/PDFStyles'

import {
    // InterBlack,
    InterBold,
    InterExtraBold,
    // InterExtraLight,
    // InterLight,
    InterMedium,
    InterRegular,
    InterSemiBold,
    // InterThin,
    InterItalic,
    DMSansBold,
    DMSansMedium,
    DMSansRegular,
    DMSansItalic,
    DMSansExtraBold,
    DMSansSemiBold,
} from '@assets'

Font.register({
    family: 'Inter',
    fonts: [
        {
            src: InterRegular,
            fontWeight: 400,
        },
        {
            src: InterItalic,
            fontWeight: 400,
            fontStyle: 'italic',
        },
        {
            src: InterMedium,
            fontWeight: 500,
        },
        {
            src: InterSemiBold,
            fontWeight: 600,
        },
        {
            src: InterBold,
            fontWeight: 700,
        },
        {
            src: InterExtraBold,
            fontWeight: 800,
        },
    ],
})

Font.register({
    family: 'DMSans',
    fonts: [
        {
            src: DMSansRegular,
            fontWeight: 400,
        },
        {
            src: DMSansItalic,
            fontWeight: 400,
            fontStyle: 'italic',
        },
        {
            src: DMSansMedium,
            fontWeight: 500,
        },
        {
            src: DMSansSemiBold,
            fontWeight: 600,
        },
        {
            src: DMSansBold,
            fontWeight: 700,
        },
        {
            src: DMSansExtraBold,
            fontWeight: 800,
        },
    ],
})

const renderPreference = (title, value) => {
    if (value && value !== '') {
        return (
            <Text style={styles.text}>
                <Text style={styles.semiBlodText}>{`${title}: `}</Text>
                {value}
            </Text>
        )
    }
    return null
}

const renderExperienceInCompany = (exp, index) => {
    const titleText = exp.Title ? (
        <Text style={{ ...styles.semiBlodText }}>{exp.Title}</Text>
    ) : null

    const engagementTimeText = exp.EngagementTime ? (
        <Text style={styles.text}>{exp.EngagementTime}</Text>
    ) : null

    const locationText = exp.Location ? (
        <Text style={styles.semiBlodText}>{exp.Location}</Text>
    ) : null

    const workModeText = exp.WorkMode ? (
        <Text style={styles.text}>{exp.WorkMode}</Text>
    ) : null

    const dateText =
        exp.StartDate && exp.EndDate ? (
            <Text style={styles.semiBlodText}>{`${formatTime(
                exp.StartDate,
                exp.EndDate,
            )}`}</Text>
        ) : null

    const contractTypeText = exp.ContractType ? (
        <Text style={styles.text}>{exp.ContractType}</Text>
    ) : null

    return (
        <View key={index} style={styles.exp}>
            <Text style={styles.text}>{titleText}</Text>
            <Text style={styles.text}>
                {locationText}
                {locationText && workModeText && ' · '}
                {workModeText}
            </Text>
            <Text style={styles.text}>
                {dateText}
                {dateText && contractTypeText && ' · '}
                {contractTypeText}
                {contractTypeText && engagementTimeText && ' · '}
                {engagementTimeText}
            </Text>
            <Text
                style={{
                    ...styles.text,
                    marginTop: '1px',
                }}
            >{`${exp.Description?.replaceAll('- ', '• ')}`}</Text>
        </View>
    )
}

const renderExperience = (comp, index) => {
    const companyNameText = comp.CompanyName ? (
        <Text
            style={{
                ...styles.text,
                fontWeight: 700,
                textTransform: 'uppercase',
            }}
        >
            {comp.CompanyName}
        </Text>
    ) : null

    const companyWebSiteText = comp.CompanyWebSite ? (
        <Link style={styles.link} src={comp.CompanyWebSite}>
            <Text style={styles.text}>
                {extractMainDomain(comp.CompanyWebSite)}
            </Text>
        </Link>
    ) : null

    return (
        <View key={index}>
            <Text style={styles.text}>
                {companyNameText}
                {companyNameText && companyWebSiteText && ' · '}
                {companyWebSiteText}
            </Text>
            <Text
                style={{
                    ...styles.text,
                    fontStyle: 'italic',
                    marginBottom: '4px',
                }}
            >{`${comp.CompanyDescription}`}</Text>
            {comp.ExperiencesInCompany.map((exp, index) =>
                renderExperienceInCompany(exp, index),
            )}
        </View>
    )
}

const renderEducation = (edu, index) => {
    const schoolNameText = edu.SchoolName ? (
        <Text
            style={{
                ...styles.text,
                fontWeight: 600,
                textTransform: 'uppercase',
            }}
        >
            {edu.SchoolName}
        </Text>
    ) : null

    const schoolWebSiteText = edu.SchoolWebSite ? (
        <Link style={styles.link} src={edu.SchoolWebSite}>
            <Text style={styles.text}>{edu.SchoolWebSite}</Text>
        </Link>
    ) : null

    const fieldText = edu.Field ? (
        <Text style={styles.semiBlodText}>{edu.Field}</Text>
    ) : null

    const degreeText = edu.Degree ? (
        <Text style={styles.semiBlodText}>{edu.Degree}</Text>
    ) : null

    const gradeText = edu.Grade ? (
        <Text style={styles.semiBlodText}>
            {`Grade: `}
            <Text style={styles.text}>{edu.Grade}</Text>
        </Text>
    ) : null

    return (
        <View key={index} style={styles.exp}>
            <Text style={styles.text}>
                {schoolNameText}
                {schoolNameText && schoolWebSiteText && ' · '}
                {schoolWebSiteText}
            </Text>
            <Text style={styles.text}>
                {fieldText}
                {fieldText && degreeText && ' · '}
                {degreeText}
                {degreeText && gradeText && ' · '}
                {gradeText}
            </Text>
            <View style={styles.lignedText}>
                <Text
                    style={{
                        ...styles.text,
                        fontWeight: 600,
                    }}
                >
                    {edu.SchoolLocation ? `${edu.SchoolLocation}` : ''}
                </Text>
                <Text style={styles.semiBlodText}>{`${formatTime(
                    edu.StartDate,
                    edu.EndDate,
                )}`}</Text>
            </View>
        </View>
    )
}

const renderLanguage = (lang, index) => {
    return (
        <View key={index}>
            <Text style={styles.text}>
                <Text
                    style={{
                        ...styles.text,
                        fontWeight: 600,
                    }}
                >{`${lang.Language}`}</Text>
                {lang.Proficiency ? ` · ${lang.Proficiency}` : ''}
                {lang.Certificate ? ` · ${lang.Certificate}` : ''}
                {lang.Grade ? ` ${lang.Grade}` : ''}
            </Text>
        </View>
    )
}

function PDF({ userData, userCV, initialValue }) {
    if (!userData) {
        userData = initialValue.userData
    }
    if (!userCV) {
        userCV = initialValue.userCV
    }
    const visibility = userCV.Visibility

    // User Informations
    const {
        Name: firstName,
        Surname: lastName,
        ProfilePicture: profilePicture,
    } = useMemo(() => userData, [userData])
    const isBirthYearVisible = visibility['BirthDate']
    const birthYear = useMemo(
        () =>
            isVisible(isBirthYearVisible) && userCV.BirthDate
                ? new Date(userCV.BirthDate).getFullYear()
                : null,
        [userCV.BirthDate, isBirthYearVisible],
    )
    const isVisibleGender = visibility['Gender']
    const isVisibleNationality = visibility['Nationality']
    const isVisibleCurrentCity = visibility['CurrentCity']
    const details = useMemo(
        () =>
            joinWithSeparator([
                birthYear,
                isVisible(isVisibleGender) ? userCV.Gender : null,
                isVisible(isVisibleNationality) ? userCV.Nationality : null,
                isVisible(isVisibleCurrentCity) ? userCV.CurrentCity : null,
            ]),
        [
            birthYear,
            userCV.Gender,
            userCV.Nationality,
            userCV.CurrentCity,
            isVisibleGender,
            isVisibleNationality,
            isVisibleCurrentCity,
        ],
    )
    const isVisibleEmail = visibility['Email']
    const isVisiblePhoneNumber = visibility['PhoneNumber']
    const contact = useMemo(
        () =>
            joinWithSeparator([
                isVisible(isVisibleEmail) ? userCV.Email : null,
                isVisible(isVisiblePhoneNumber) ? userCV.PhoneNumber : null,
            ]),
        [
            userCV.Email,
            userCV.PhoneNumber,
            isVisibleEmail,
            isVisiblePhoneNumber,
        ],
    )

    // User Experiences
    const isExperienceVisible = visibility['Experiences']
    const isCompanyVisible = visibility['Companies']
    const experiences = useMemo(() => {
        const filteredExperiences = filterExperiencesByVisibility(
            userCV.Experiences,
            isCompanyVisible,
            isExperienceVisible,
        )

        // Sort experiences within each company
        return filteredExperiences.map((company) => ({
            ...company,
            ExperiencesInCompany: company.ExperiencesInCompany.sort((a, b) => {
                const aEndDate = dateToComparableNumber(a.EndDate)
                const bEndDate = dateToComparableNumber(b.EndDate)
                return bEndDate - aEndDate
            }),
        }))
    }, [userCV.Experiences, isCompanyVisible, isExperienceVisible])

    // User Education
    const isEducationVisible = visibility['Education']
    const education = filterListByVisibility(
        userCV.Education,
        isEducationVisible,
    )

    // User Languages
    const isLanguagesVisible = visibility['Languages']
    const languages = filterListByVisibility(
        userCV.Languages,
        isLanguagesVisible,
    )

    // User Preferences
    const isIndustryVisible = visibility['PreferedIndustry']
    const preferedIndustry = useMemo(
        () =>
            isVisible(isIndustryVisible)
                ? userCV.PreferedIndustry.join(' · ')
                : null,
        [userCV.PreferedIndustry, isIndustryVisible],
    )
    const isFunctionVisible = visibility['PreferedFunction']
    const preferedFunction = useMemo(
        () =>
            isVisible(isFunctionVisible)
                ? userCV.PreferedFunction.join(' · ')
                : null,
        [userCV.PreferedFunction, isFunctionVisible],
    )
    const isLocationVisible = visibility['PreferedLocation']
    const preferedLocation = useMemo(
        () =>
            isVisible(isLocationVisible)
                ? userCV.PreferedLocation.join(' · ')
                : null,
        [userCV.PreferedLocation, isLocationVisible],
    )
    const isBonusVisible = visibility['PreferedBonus']
    const preferedBonus = useMemo(
        () =>
            isVisible(isBonusVisible) ? userCV.PreferedBonus.join(' · ') : null,
        [userCV.PreferedBonus, isBonusVisible],
    )

    const formatSalary = (salary) => {
        if (!salary.MinSalary || !salary.Currency || !salary.PaymentFrequency)
            return ''
        return `${salary.MinSalary} ${salary.Currency}${
            salary.MaxSalary ? ` to ${salary.MaxSalary} ${salary.Currency}` : ''
        }, ${salary.PaymentFrequency}`
    }

    const isBaseSalaryVisible = visibility['PreferedBaseSalary']
    const preferedBaseSalary = useMemo(
        () =>
            isVisible(isBaseSalaryVisible)
                ? formatSalary(userCV.PreferedBaseSalary)
                : null,
        [userCV.PreferedBaseSalary, isBaseSalaryVisible],
    )
    const isEngagementVisible = visibility['PreferedEngagement']
    const preferedEngagement = useMemo(
        () =>
            isVisible(isEngagementVisible)
                ? userCV.PreferedEngagement.join(' · ')
                : null,
        [userCV.PreferedEngagement, isEngagementVisible],
    )

    const isAvailabilityVisible = visibility['Availability']
    const avaibility = useMemo(
        () =>
            isVisible(isAvailabilityVisible)
                ? userCV.Availability.Immediate
                    ? 'Immediate'
                    : userCV.Availability.Number && userCV.Availability.Period
                      ? `${userCV.Availability.Number} ${userCV.Availability.Period}`
                      : ''
                : null,
        [userCV.Availability, isAvailabilityVisible],
    )

    const { data: imgSrc, error: imageError } = useQuery(
        ['profilePicture', profilePicture],
        () => getProfilePicture(profilePicture),
        {
            enabled: !!profilePicture,
            refetchOnWindowFocus: false,
            retry: false,
            staleTime: 1000 * 60 * 5,
            cacheTime: 1000 * 60 * 30,
        },
    )

    const MyDocument = () => (
        <Document>
            <Page size="A4" style={styles.page}>
                {/* User Information */}
                <View style={styles.header}>
                    {/* Profile Picture */}
                    {profilePicture && imgSrc && !imageError && (
                        <>
                            <View style={styles.profileBorder} />
                            <Image style={styles.profilePicture} src={imgSrc} />
                        </>
                    )}
                    <View style={styles.userInfo}>
                        <Text
                            style={styles.headerTitle}
                        >{`${firstName} ${lastName}`}</Text>
                        <Text style={styles.userDetails}>{`${details}`}</Text>
                        <Text style={styles.userDetails}>{`${contact}`}</Text>
                    </View>
                </View>

                {/* Summary */}
                <View style={styles.section}>
                    <Text style={styles.sectionTitle}>Summary</Text>
                    <Text style={{ ...styles.text, textAlign: 'justify' }}>
                        {userCV.Summary}
                    </Text>
                </View>

                {/* Work Experience */}
                <View style={styles.section}>
                    <Text style={styles.sectionTitle}>Work Experience</Text>
                    {experiences.map((comp, index) =>
                        renderExperience(comp, index),
                    )}
                </View>

                {/* Education */}
                <View style={styles.section}>
                    <Text style={styles.sectionTitle}>EDUCATION</Text>
                    {education.map((edu, index) => renderEducation(edu, index))}
                </View>

                {/* Languages */}
                <View style={styles.section}>
                    <Text style={styles.sectionTitle}>Language</Text>
                    {languages.map((lang, index) =>
                        renderLanguage(lang, index),
                    )}
                </View>

                {/* Preferences */}
                <View style={styles.section}>
                    <Text style={styles.sectionTitle}>Preferences</Text>
                    {renderPreference('Industry', preferedIndustry)}
                    {renderPreference('Function', preferedFunction)}
                    {renderPreference('Base Salary', preferedBaseSalary)}
                    {renderPreference('Bonus and Benefits', preferedBonus)}
                    {renderPreference('Engagement', preferedEngagement)}
                    {renderPreference('Location', preferedLocation)}
                    {renderPreference('Avaibility', avaibility)}
                </View>

                {/* Footer */}
                <View style={styles.footer} fixed>
                    <Text style={styles.footerText}>
                        {`Powered by `}
                        <Link style={styles.link} src="https://www.cotalent.io">
                            <Text style={styles.blue}>COTALENT</Text>
                        </Link>
                    </Text>
                </View>
            </Page>
        </Document>
    )

    return (
        <PDFViewer>
            <MyDocument />
        </PDFViewer>
    )
}

export default React.memo(PDF)
