import React, { useState } from 'react'
import { useCV } from '@context/CVContext'
import { components } from 'react-select'
import Creatable from 'react-select/creatable'
import makeAnimated from 'react-select/animated'
import { VisibilityButton } from '@compUtils'
import Tooltip from '@mui/material/Tooltip'
import { bonusOptions } from '@utils'

import { Info } from '@assets'

const animatedComponents = makeAnimated()

function CurrentBonus({ isModal = false }) {
    const { cvData, updateCV } = useCV()
    const [selectedBonuses, setSelectedBonuses] = useState(
        cvData.CurrentBonus?.map((bonus) => ({
            value: bonus,
            label: bonus,
        })) || [],
    )

    const [allOptions] = useState(bonusOptions)

    function capitalizeWords(str) {
        return str.replace(/\b(\w)/g, (s) => s.toUpperCase())
    }

    const handleChange = (selectedOptions, { action, option }) => {
        const capitalizedOptions = selectedOptions.map((opt) => ({
            ...opt,
            value: capitalizeWords(opt.value),
            label: capitalizeWords(opt.label),
        }))

        setSelectedBonuses(capitalizedOptions || [])

        const bonusValues = capitalizedOptions?.map((option) => option.value)

        updateCV((prevData) => ({
            ...prevData,
            CurrentBonus: bonusValues,
        }))
    }

    const formatOptionLabel = ({ label, value, data }) => {
        if (data && data.__isNew__) {
            return (
                <div>
                    <components.GroupHeading>New Entry</components.GroupHeading>
                    {label}
                </div>
            )
        }
        return label
    }

    const customStyles = {
        container: (provided) => ({
            ...provided,
            width: '100%',
        }),
        valueContainer: (provided) => ({
            ...provided,
            maxHeight: '38px',
            overflow: 'auto',
        }),
        menuList: (provided) => ({
            ...provided,
            maxHeight: '170px',
            overflowY: 'auto',
        }),
    }

    const placeholder =
        selectedBonuses && selectedBonuses?.length > 0
            ? `${selectedBonuses?.length} selected`
            : 'Select bonuses and benefits or create it'

    return (
        <div
            className={`new-component ${
                selectedBonuses?.length === 0
                    ? 'facultative-border'
                    : 'valid-border'
            }`}
            style={isModal ? { width: 'auto' } : {}}
        >
            <div className="new-info-wrapper">
                <div className="new-info-header">
                    <div className="new-info-header-icon">
                        Current Bonus & Benefits
                        <Tooltip
                            title="You can add your own bonus by typing it and pressing Enter !"
                            placement="top"
                        >
                            <img src={Info} alt="info" className="info-icon" />
                        </Tooltip>
                    </div>
                    <VisibilityButton detailKeyOrPath={'CurrentBonus'} />
                </div>
                <div className="new-info-container">
                    <Creatable
                        isMulti
                        components={animatedComponents}
                        value={selectedBonuses}
                        onChange={handleChange}
                        options={allOptions}
                        classNamePrefix="select"
                        placeholder={placeholder}
                        formatOptionLabel={formatOptionLabel}
                        isSearchable
                        styles={customStyles}
                        isCreatable={true}
                        openMenuOnFocus={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default CurrentBonus
