import React, { useState } from 'react'
import { useCV } from '@context/CVContext'
import Creatable from 'react-select/creatable'
import makeAnimated from 'react-select/animated'
import { VisibilityButton } from '@compUtils'
import Tooltip from '@mui/material/Tooltip'

import { Info } from '@assets'
import { customStylesValid, customStylesInvalid } from '@styles/styles'

const animatedComponents = makeAnimated()

function PreferedEngagement({ isModal = false }) {
    const { cvData, updateCV } = useCV()
    const [selectedEngagements, setSelectedEngagements] = useState(
        cvData.PreferedEngagement?.map((engagement) => ({
            value: engagement,
            label: engagement,
        })) || [],
    )

    const options = [
        {
            label: 'No Preference',
            options: ['Any'].map((type) => ({ label: type, value: type })),
        },
        {
            label: 'Type',
            options: ['Permanent', 'Contract', 'Internship']?.map((type) => ({
                label: type,
                value: type,
            })),
        },
        {
            label: 'Location',
            options: [
                'On Site',
                'Remote',
                'Hybrid',
                'Client Side',
                'Field Site',
            ]?.map((location) => ({ label: location, value: location })),
        },
        {
            label: 'Time',
            options: ['Full Time', 'Part Time', 'Flexible Time']?.map(
                (time) => ({ label: time, value: time }),
            ),
        },
    ]

    const handleChange = (selectedOptions, { action, option }) => {
        setSelectedEngagements(selectedOptions || [])

        const engagementValues = selectedOptions?.map((option) => option.value)

        updateCV((prevData) => ({
            ...prevData,
            PreferedEngagement: engagementValues,
        }))
    }

    const customStyles = {
        container: (provided) => ({
            ...provided,
            width: '100%',
        }),
        valueContainer: (provided) => ({
            ...provided,
            maxHeight: '38px',
            overflow: 'auto',
        }),
        menuList: (provided) => ({
            ...provided,
            maxHeight: '170px',
            overflowY: 'auto',
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 1000,
        }),
    }

    const placeholder =
        selectedEngagements && selectedEngagements?.length > 0
            ? `${selectedEngagements?.length} selected`
            : 'Select preferred engagement'

    return (
        <div
            className={`new-component ${
                selectedEngagements?.length === 0
                    ? 'invalid-border'
                    : 'valid-border'
            }`}
            style={isModal ? { width: 'auto' } : {}}
        >
            <div className="new-info-wrapper">
                <div className="new-info-header">
                    <div className="new-info-header-icon">
                        Preferred Engagement
                        <Tooltip
                            title="You can add your own engagement by typing it and pressing Enter !"
                            placement="top"
                        >
                            <img src={Info} alt="info" className="info-icon" />
                        </Tooltip>
                    </div>
                    <VisibilityButton detailKeyOrPath={'PreferedEngagement'} />
                </div>
                <div className="new-info-container">
                    <Creatable
                        isMulti
                        components={animatedComponents}
                        value={selectedEngagements}
                        onChange={handleChange}
                        options={options}
                        classNamePrefix="select"
                        placeholder={placeholder}
                        isSearchable
                        styles={{
                            ...customStyles,
                            ...(selectedEngagements?.length > 0
                                ? customStylesValid
                                : customStylesInvalid),
                        }}
                        openMenuOnFocus={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default PreferedEngagement
