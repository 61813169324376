import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useInView } from 'react-intersection-observer'
import Tooltip from '@mui/material/Tooltip'
import { LandingHeader } from '@compUtils'

import { HappyPeople } from '@assets'

import '@styles/landing.css'

function Feature({
    children,
    triggerOnce = false,
    animationType = 'slide-in',
}) {
    const { ref, inView } = useInView({
        threshold: 0.1,
        triggerOnce: triggerOnce,
    })
    const animationClass = inView ? `${animationType} in-view` : animationType

    return (
        <div ref={ref} className={`feature-animation ${animationClass}`}>
            {children}
        </div>
    )
}

function Landing() {
    const navigate = useNavigate()

    const handleSignUpClick = () => {
        navigate('/SignUp')
    }

    return (
        <div className="landing">
            <LandingHeader />

            <div className="landing-body">
                <div className="hero-section">
                    <Feature triggerOnce={true}>
                        <div className="hero-text">
                            <h1>
                                Accelerating <br />
                                <span className="blue">competency</span> <br />
                                based hiring
                            </h1>
                            <p>
                                Our cutting-edge tools are built according to
                                the latest HR <br /> best practices and on top
                                of the most capable AI models, <br /> assisting
                                job seekers and employers in creating compelling{' '}
                                <br />
                                and professional Resumes, and highly customized
                                Job Descriptions.
                            </p>
                            <div className="hn-buttons-container">
                                <button
                                    className="hn-button-blue"
                                    onClick={handleSignUpClick}
                                >
                                    Craft a FREE Resume
                                </button>
                                <Tooltip title="Coming soon !" placement="top">
                                    <button
                                        className="hn-button-blue no-anim"
                                        // onClick={handleSignUpClick}
                                    >
                                        Draft a new JD
                                    </button>
                                </Tooltip>
                            </div>
                        </div>
                    </Feature>
                    <Feature triggerOnce={true}>
                        <div className="hero-image">
                            <img
                                src={HappyPeople}
                                alt="Illustration of happy people"
                            />
                        </div>
                    </Feature>
                </div>

                <hr />

                <div className="features-section">
                    <Feature>
                        <div className="features-header">
                            <h1>Resumes Tailored for You</h1>
                        </div>
                    </Feature>
                    <div className="features-body">
                        <div className="feature">
                            <Feature animationType="slide-up">
                                <div className="feature-header">
                                    <h2>Benefits</h2>
                                </div>
                            </Feature>
                            <Feature animationType="slide-up">
                                <div className="feature-entry">
                                    <h3>Less than 10 minutes</h3>
                                    <span>
                                        That’s what it takes to either create a
                                        new Resume or update your existing one.
                                    </span>
                                </div>
                            </Feature>
                            <Feature animationType="slide-up">
                                <div className="feature-entry">
                                    <h3>No writer’s block</h3>
                                    <span>
                                        Receive expert Resume guidance, ensuring
                                        your resume leaves no detail unturned,
                                        for your peace of mind.
                                    </span>
                                </div>
                            </Feature>
                            <Feature animationType="slide-up">
                                <div className="feature-entry">
                                    <h3>ATS optimization</h3>
                                    <span>
                                        Our Resumes are compatible with
                                        Applicant Tracking Systems (ATS), which
                                        many companies use to filter candidates.
                                    </span>
                                </div>
                            </Feature>
                            <Feature animationType="slide-up">
                                <div className="feature-entry">
                                    <h3>Career with a purpose</h3>
                                    <span>
                                        Get exclusive job recommendations,
                                        customize your Resume and prepare for
                                        the interview with our AI assistant.
                                    </span>
                                </div>
                            </Feature>
                        </div>

                        <div className="feature">
                            <Feature animationType="slide-up">
                                <div className="feature-header">
                                    <h2>Use Case</h2>
                                </div>
                            </Feature>
                            <Feature animationType="slide-up">
                                <div className="feature-entry">
                                    <h3>Job Seekers</h3>
                                    <span>
                                        Kickstart your job search with an
                                        ATS-optimized and customizable Resume,
                                        and maximize each opportunity with our
                                        AI-powered interview simulation.
                                    </span>
                                </div>
                            </Feature>
                            <Feature animationType="slide-up">
                                <div className="feature-entry">
                                    <h3>Consultants and Experts</h3>
                                    <span>
                                        Showcasing your expertise is critical
                                        for your success. Let our plateform
                                        elevate your visibility so you can focus
                                        on what you do best.
                                    </span>
                                </div>
                            </Feature>
                            <Feature animationType="slide-up">
                                <div className="feature-entry">
                                    <h3>Highly-Specialized Professionals</h3>
                                    <span>
                                        Effortlessly craft a compelling Resume,
                                        showcasing your deep expertise without
                                        the presentation worry.
                                    </span>
                                </div>
                            </Feature>
                            <Feature animationType="slide-up">
                                <div className="feature-entry">
                                    <h3>
                                        Students, Fresh Graduates and Young
                                        Professionals
                                    </h3>
                                    <span>
                                        Our tool helps share your formative
                                        experiences and career aspirations.
                                        Launch your path to success.
                                    </span>
                                </div>
                            </Feature>
                        </div>
                    </div>
                    <div className="hn-buttons-container">
                        <button
                            className="hn-button-blue"
                            onClick={handleSignUpClick}
                        >
                            Start for FREE
                        </button>
                    </div>
                </div>

                <hr />

                <div className="features-section">
                    <Feature>
                        <div className="features-header">
                            <h1>
                                Job Descriptions Customized to Your Business
                            </h1>
                        </div>
                    </Feature>
                    <div className="features-body">
                        <div className="feature">
                            <Feature animationType="slide-up">
                                <div className="feature-header">
                                    <h2>Features</h2>
                                </div>
                            </Feature>
                            <Feature animationType="slide-up">
                                <div className="feature-entry">
                                    <h3>Specify your hiring needs</h3>
                                    <span>
                                        Craft Job Descriptions tailored to your
                                        company's specific business needs.
                                    </span>
                                </div>
                            </Feature>
                            <Feature animationType="slide-up">
                                <div className="feature-entry">
                                    <h3>Share the link</h3>
                                    <span>
                                        Download the Job Description or share it
                                        on your website, even your LinkedIn
                                        post, for automated candidate screening.
                                    </span>
                                </div>
                            </Feature>
                            <Feature animationType="slide-up">
                                <div className="feature-entry">
                                    <h3>Automated screening</h3>
                                    <span>
                                        Use preset questions and preferences to
                                        reduce the number of interviews, while
                                        improving candidates quality.
                                    </span>
                                </div>
                            </Feature>
                            <Feature animationType="slide-up">
                                <div className="feature-entry">
                                    <h3>Automated recommendation</h3>
                                    <span>
                                        Received fully assessed candidates
                                        recommendations for a more efficient
                                        hiring process.
                                    </span>
                                </div>
                            </Feature>
                        </div>

                        <div className="feature">
                            <Feature animationType="slide-up">
                                <div className="feature-header">
                                    <h2>Use Case</h2>
                                </div>
                            </Feature>
                            <Feature animationType="slide-up">
                                <div className="feature-entry">
                                    <h3>Collaborative Hiring</h3>
                                    <span>
                                        Generate tailored Job Descriptions and
                                        select candidates collaboratively with
                                        your hiring team.
                                    </span>
                                </div>
                            </Feature>
                            <Feature animationType="slide-up">
                                <div className="feature-entry">
                                    <h3>First Hiring</h3>
                                    <span>
                                        For startups with limited resources,
                                        manage the entire hiring process
                                        independently, while we guide you in
                                        identifying the most suitable
                                        candidates.
                                    </span>
                                </div>
                            </Feature>
                            <Feature animationType="slide-up">
                                <div className="feature-entry">
                                    <h3>Expert Hiring</h3>
                                    <span>
                                        If you need an expert or consultant for
                                        short discussions or long-term projects,
                                        define your requirements and we will
                                        make the introduction.
                                    </span>
                                </div>
                            </Feature>
                            <Feature animationType="slide-up">
                                <div className="feature-entry">
                                    <h3>Campus Hiring</h3>
                                    <span>
                                        Organize virtual campus hiring on our
                                        platform to attract fresh graduates and
                                        young professionals.
                                    </span>
                                </div>
                            </Feature>
                        </div>
                    </div>
                    <div className="hn-buttons-container">
                        <Tooltip title="Coming soon !" placement="top">
                            <button
                                className="hn-button-blue no-anim"
                                // onClick={handleSignUpClick}
                            >
                                Learn More
                            </button>
                        </Tooltip>
                    </div>
                    <div className="hn-buttons-container">
                        <button
                            className="hn-button-white"
                            onClick={() => navigate('/Policies')}
                            style={{ color: '#000' }}
                        >
                            Terms & policies
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Landing
