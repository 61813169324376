import React, { useState, useEffect, useRef } from 'react'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { VisibilityButton, DeleteButton } from '@components/Utils'
import { autoGrowText } from '@utils'
import { useTmpData } from '@context'

import { Dots } from '@assets'

function CompanyModal({ initialValue = {}, onUpdate, onClose, onForceClose }) {
    const { menuOpen, setMenuOpen, setAllowClose } = useTmpData()

    useEffect(() => {
        setAllowClose(!menuOpen)
    }, [menuOpen, setAllowClose])

    const [companyInfo, setCompanyInfo] = useState({
        CompanyName: initialValue.Name || '',
        CompanyDescription: initialValue.Description || '',
        CompanyWebSite: initialValue.WebSite || '',
    })

    const textAreaRef = useRef(null)
    const [deleteModal, setDeleteModal] = useState(false)

    useEffect(() => {
        if (textAreaRef.current) {
            autoGrowText(textAreaRef.current, 5)
        }
    }, [])

    useEffect(() => {
        if (onUpdate) {
            onUpdate(companyInfo)
        }
    }, [companyInfo, onUpdate])

    const handleChange = (field, value) => {
        setCompanyInfo((prev) => ({ ...prev, [field]: value }))
    }

    return (
        <div className={`new-component-experience`}>
            <div className="new-info-wrapper">
                <div className="new-info-header">
                    <span className="title">Company Information</span>
                    <div className="new-info-header-buttons">
                        <div className="done-button">
                            <button onClick={() => onForceClose()}>Done</button>
                        </div>
                        <VisibilityButton
                            detailKeyOrPath={`Companies.${initialValue.CompanyId}`}
                        />
                        <DeleteButton
                            detailKeyOrPath={`Experiences.${initialValue.CompanyId}`}
                            onClose={onClose}
                            hasParent={true}
                            modalParent={deleteModal}
                            setModalParent={setDeleteModal}
                        />
                        <IconButton
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={(e) => setMenuOpen(e.currentTarget)}
                        >
                            <img
                                src={Dots}
                                alt="les trois petits points"
                                className="dots-button-image"
                            />
                        </IconButton>
                        <Menu
                            id="simple-menu"
                            anchorEl={menuOpen}
                            keepMounted
                            open={Boolean(menuOpen)}
                            onClose={() => setMenuOpen(false)}
                        >
                            <MenuItem
                                onClick={() => {
                                    setDeleteModal(true)
                                }}
                            >
                                Delete
                            </MenuItem>
                        </Menu>
                    </div>
                </div>

                <div className="new-info-exprerience-container">
                    <div className="new-info-experience-modal-container">
                        <label
                            className="new-info-experience-modal-label"
                            htmlFor="company-name"
                        >
                            Company Name
                        </label>
                        <input
                            id="company-name"
                            type="text"
                            value={companyInfo.CompanyName}
                            onChange={(e) =>
                                handleChange('CompanyName', e.target.value)
                            }
                            placeholder="Enter company name..."
                        />
                    </div>
                    <div className="new-info-experience-modal-container">
                        <label
                            className="new-info-experience-modal-label"
                            htmlFor="company-website"
                        >
                            Company Website
                        </label>
                        <input
                            id="company-website"
                            type="url"
                            value={companyInfo.CompanyWebSite}
                            onChange={(e) =>
                                handleChange('CompanyWebSite', e.target.value)
                            }
                            placeholder="Enter company website URL..."
                        />
                    </div>
                    <div className="new-info-experience-modal-container">
                        <label
                            className="new-info-experience-modal-label"
                            htmlFor="company-description"
                        >
                            Company Description
                        </label>
                        <textarea
                            id="company-description"
                            ref={textAreaRef}
                            value={companyInfo.CompanyDescription}
                            onChange={(e) =>
                                handleChange(
                                    'CompanyDescription',
                                    e.target.value,
                                )
                            }
                            onInput={(e) => autoGrowText(e.target, 5)}
                            placeholder="Enter company description..."
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompanyModal
