import React, { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'

import CompanyModal from './companyModal'
import CollaboratorModal from './collabModal'

import { Modal } from '@modals'
import { useHire } from '@hooks'
import { deleteCompany } from '@utils'
import { Dots } from '@assets'

function CompanyCardDots({ company }) {
    const queryClient = useQueryClient()
    const { getRole, handleCompanyUpdate } = useHire()
    const role = getRole(company)
    const [menuOpen, setMenuOpen] = useState(null)
    const [isCompModalOpen, setIsCompModalOpen] = useState(false)
    const [isCollabModalOpen, setIsCollabModalOpen] = useState(false)

    const mutation = useMutation(handleCompanyUpdate, {
        onMutate: async (updatedCompany) => {
            await queryClient.cancelQueries('companies')
            const previousCompanies = queryClient.getQueryData('companies')

            queryClient.setQueryData('companies', (old) =>
                old.map((c) =>
                    c._id === updatedCompany._id
                        ? { ...c, ...updatedCompany }
                        : c,
                ),
            )

            return { previousCompanies }
        },
        onError: (err, updatedCompany, context) => {
            queryClient.setQueryData('companies', context.previousCompanies)
        },
        onSettled: () => {
            queryClient.invalidateQueries('companies')
        },
    })

    const handleCompanyModalClose = (updatedCompany) => {
        mutation.mutate(updatedCompany)
        setIsCompModalOpen(false)
    }

    const handleDelete = async () => {
        try {
            await deleteCompany(company._id)
            queryClient.invalidateQueries('companies')
            toast.success('Company deleted successfully')
        } catch (error) {
            console.error('Error deleting company:', error)
            toast.error('Error deleting company')
        }
    }

    return (
        <div>
            <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={(e) => {
                    e.stopPropagation()
                    setMenuOpen(e.currentTarget)
                }}
            >
                <img
                    src={Dots}
                    alt="les trois petits points"
                    className="dots-button-image"
                />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={menuOpen}
                keepMounted
                open={Boolean(menuOpen)}
                onClose={(e) => {
                    e.stopPropagation()
                    setMenuOpen(null)
                }}
            >
                <Tooltip
                    title="You need to be editor"
                    disableHoverListener={role >= 2}
                    placement="top"
                >
                    <div>
                        <MenuItem
                            disabled={role < 2}
                            onClick={(e) => {
                                e.stopPropagation()
                                setIsCompModalOpen(true)
                                setMenuOpen(null)
                            }}
                        >
                            Edit Company
                        </MenuItem>
                    </div>
                </Tooltip>
                <div>
                    <MenuItem
                        onClick={(e) => {
                            e.stopPropagation()
                            setIsCollabModalOpen(true)
                            setMenuOpen(null)
                        }}
                    >
                        {`${role >= 3 ? 'Edit' : 'View'} Collaborators`}
                    </MenuItem>
                </div>
                <Tooltip
                    title="You need to be owner"
                    disableHoverListener={role >= 4}
                >
                    <div>
                        <MenuItem
                            disabled={role < 4}
                            onClick={(e) => {
                                e.stopPropagation()
                                setMenuOpen(null)
                                handleDelete()
                            }}
                        >
                            Delete
                        </MenuItem>
                    </div>
                </Tooltip>
            </Menu>

            <Modal
                isOpen={isCompModalOpen}
                onClose={handleCompanyModalClose}
                initialValue={company}
                Component={CompanyModal}
                className="modal-content wide"
            />
            <Modal
                isOpen={isCollabModalOpen}
                onClose={() => setIsCollabModalOpen(false)}
                initialValue={{ ...company, role: role }}
                Component={CollaboratorModal}
                className="modal-content wide"
            />
        </div>
    )
}

export default CompanyCardDots
