import React from 'react'
import { descriptionState } from '@utils'

const MAX_CHAR_EXP = 4000

const DescriptionInputComponent = ({ experience, onChange, currentState }) => {
    const valid =
        experience.Description?.length <= MAX_CHAR_EXP &&
        experience.Description?.length >= 50 &&
        (experience.AssistantFinished || experience.ExperienceType !== 'Work')

    return (
        <div className="description-input" style={{ position: 'relative' }}>
            <textarea
                id="description"
                value={experience.Description}
                onChange={(e) => onChange('Description', e.target.value)}
                placeholder="Description"
                disabled={currentState === descriptionState.ASSISTANT_WRITING}
                className={valid ? 'valid-border' : 'invalid-border'}
                style={{ height: '300px' }}
            />
            <div
                className="new-info-description"
                style={
                    valid
                        ? {}
                        : {
                              color: 'red',
                          }
                }
            >
                {experience.Description?.length} / {MAX_CHAR_EXP}
            </div>
        </div>
    )
}

export default DescriptionInputComponent
