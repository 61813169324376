import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import { OptionItem } from '@sections'
import { useReleaseNote, useMobileScreen } from '@hooks'
import { getAccountType } from '@utils'

import { Person, PersonRight, PersonLeft } from '@assets'

import './Sidebar.css'

function Sidebar() {
    const isMobile = useMobileScreen()
    const { pathname } = useLocation()
    const { data: accountType } = useQuery('accountType', getAccountType, {
        refetchOnWindowFocus: false,
    })
    const { detailsMessage, lastRel } = useReleaseNote()
    const [anchorEl, setAnchorEl] = useState(null)

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleReleaseClick = () => {
        handleClose()
        toast(detailsMessage, {
            position: 'bottom-center',
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progressStyle: { background: '#007AFF' },
            className: 'custom-toast',
        })
    }

    return (
        <>
            <div className={`sidebar`}>
                <div className="sidebar-options">
                    <OptionItem
                        icon={Person}
                        label="Profile"
                        isSelected={/\/profile*/.test(pathname)}
                        path="/profile"
                    />
                    <OptionItem
                        icon={PersonRight}
                        label="Join"
                        isSelected={/\/join*/.test(pathname)}
                        path="/join"
                    />
                    <OptionItem
                        icon={PersonLeft}
                        label="Hire"
                        isSelected={/\/hire*/.test(pathname)}
                        path="/hire"
                    />
                    {accountType === 'admin' && (
                        <OptionItem
                            icon={PersonLeft}
                            label="Admin"
                            isSelected={/\/admin*/.test(pathname)}
                            path="/admin"
                        />
                    )}
                </div>
                {!isMobile && (
                    <div className="sidebar-version">
                        <button
                            className="version-button"
                            onClick={handleMenuClick}
                        >
                            {`v${lastRel}`}
                        </button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleClose}>
                                Terms & Policy
                            </MenuItem>
                            <MenuItem onClick={handleReleaseClick}>
                                Release notes
                            </MenuItem>
                        </Menu>
                    </div>
                )}
            </div>
        </>
    )
}

export default Sidebar
