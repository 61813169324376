import { useCV } from '@context'
import ObjectID from 'bson-objectid'
import { validateAndUpdateCV } from '@utils'

const useCVUpdate = () => {
    const { cvData, updateCV } = useCV()

    const handleModalCloseGeneric = async (changes, sectionKey, id) => {
        if (!changes) return

        let itemFound = false
        const updatedItems = cvData[sectionKey].map((item) => {
            if (item._id === id) {
                itemFound = true
                return { ...item, ...changes }
            }
            return item
        })

        if (!itemFound) {
            updatedItems.push({ ...changes, _id: id || ObjectID().toString() })
        }

        const updatedCV = await validateAndUpdateCV(cvData, {
            ...cvData,
            [sectionKey]: updatedItems,
        })

        updateCV((prevCV) => ({
            ...prevCV,
            ...updatedCV,
        }))
    }

    const handleModalCloseExp = async (changes) => {
        if (!changes) return

        let companyFound = false

        const experiencesWithRemoved = cvData.Experiences.map((company) => ({
            ...company,
            ExperiencesInCompany: company.ExperiencesInCompany.filter(
                (expInCompany) => expInCompany._id !== changes.id,
            ),
        }))

        const updatedExperiences = experiencesWithRemoved.map((company) => {
            if (company._id === changes.CompanyId) {
                companyFound = true
                let experienceFound = false
                const updatedExperiencesInCompany =
                    company.ExperiencesInCompany.map((expInCompany) => {
                        if (expInCompany._id === changes.id) {
                            experienceFound = true
                            return { ...expInCompany, ...changes }
                        }
                        return expInCompany
                    })

                if (!experienceFound) {
                    updatedExperiencesInCompany.push({
                        ...changes,
                        _id: changes.id || ObjectID().toString(),
                    })
                }

                return {
                    ...company,
                    CompanyName: changes.CompanyName,
                    CompanyDescription: changes.CompanyDescription,
                    CompanyWebSite: changes.CompanyWebSite,
                    ExperiencesInCompany: updatedExperiencesInCompany,
                }
            }
            return company
        })

        if (!companyFound) {
            updatedExperiences.push({
                _id: ObjectID().toString(),
                CompanyName: changes.CompanyName,
                CompanyWebSite: changes.CompanyWebSite,
                CompanyDescription: changes.CompanyDescription,
                ExperiencesInCompany: [
                    {
                        ...changes,
                        _id: changes.id,
                    },
                ],
            })
        }

        const updatedCV = await validateAndUpdateCV(cvData, {
            ...cvData,
            Experiences: updatedExperiences,
        })

        updateCV((prevCV) => ({
            ...prevCV,
            ...updatedCV,
        }))
    }

    return { handleModalCloseGeneric, handleModalCloseExp }
}

export default useCVUpdate
