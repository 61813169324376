import { useState, useEffect } from 'react'

export default function useValidateExperiences(
    experiences,
    isEditing,
    category,
) {
    const [status, setStatus] = useState({
        className: 'editing-blue',
        errorMessage: '',
    })

    useEffect(() => {
        if (!isEditing) {
            setStatus({ className: '', errorMessage: '' })
        } else {
            const allErrors = experiences
                ?.flatMap((experience) => {
                    if (!experience) return []
                    const errors = []

                    if (!experience.Title) {
                        errors.push('!Title is missing.')
                    }
                    if (!experience.StartDate) {
                        errors.push('!Start date is missing.')
                    }
                    if (!experience.EndDate) {
                        errors.push('!End date is missing.')
                    }
                    if (category === 'Work' && !experience.AssistantFinished) {
                        errors.push('!AI assistant needs to be completed.')
                    }
                    if (experience.Description?.length < 50) {
                        errors.push(
                            '!Description is too short (at least 50 characters).',
                        )
                    }
                    if (experience.Description?.length > 4000) {
                        errors.push(
                            '!Description is too long (max 4000 characters).',
                        )
                    }

                    return errors
                })
                .filter(Boolean)

            const criticalErrors = allErrors.filter((error) =>
                error.startsWith('!'),
            )
            const normalErrors = allErrors.filter(
                (error) => !error.startsWith('!'),
            )

            if (criticalErrors?.length > 0) {
                setStatus({
                    className: 'editing-red',
                    errorMessage: criticalErrors[0].substring(1), // Remove '!' from the error message
                })
            } else if (normalErrors?.length > 0) {
                setStatus({
                    className: 'editing-orange',
                    errorMessage: normalErrors[0],
                })
            } else {
                setStatus({ className: 'editing-blue', errorMessage: '' })
            }
        }
    }, [experiences, isEditing, category])

    return status
}
