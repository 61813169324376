import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import Modal from 'react-modal'
import ReactDOM from 'react-dom/client'
import { UserDataProvider } from '@context/UserDataContext'
import { CVProvider } from '@context/CVContext'
import { AuthProvider } from '@context/AuthContext'
import { AlertProvider } from '@context/AlertContext'
import { TmpDataProvider } from '@context/TmpDataContext'
import { ContentOpenProvider } from '@context/ContentOpenContext'
import { JDProvider } from '@context/JDContext'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { ReleaseNotes } from '@compUtils'

import '@styles/index.css'

const root = ReactDOM.createRoot(document.getElementById('root'))

Modal.setAppElement('#root')

const queryClient = new QueryClient()

root.render(
    <BrowserRouter>
        <QueryClientProvider client={queryClient}>
            <UserDataProvider>
                <AuthProvider>
                    <CVProvider>
                        <TmpDataProvider>
                            <AlertProvider>
                                <ContentOpenProvider>
                                    <JDProvider>
                                        <App />
                                        <ReleaseNotes />
                                    </JDProvider>
                                </ContentOpenProvider>
                            </AlertProvider>
                        </TmpDataProvider>
                    </CVProvider>
                </AuthProvider>
            </UserDataProvider>
        </QueryClientProvider>
    </BrowserRouter>,
)
