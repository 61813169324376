// import moment from 'moment'
import { isValidPhoneNumber } from 'react-phone-number-input'

export const nameRegex = /^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF\-'\s]{2,30}$/
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

function hasUploadedCV(userCV) {
    return userCV.UploadedCV && userCV.UploadedCV !== ''
}

export function hasValidInfo(userData, userCV) {
    return (
        userData.Name &&
        nameRegex.test(userData.Name) &&
        userData.Surname &&
        nameRegex.test(userData.Surname) &&
        userCV.Email &&
        emailRegex.test(userCV.Email) &&
        userCV.PhoneNumber &&
        isValidPhoneNumber(userCV.PhoneNumber) &&
        userCV.BirthDate &&
        userCV.CurrentCity &&
        userCV.Nationality &&
        userCV.Gender !== ''
    )
}

export function hasValidPref(userCV) {
    return (
        userCV.PreferedBaseSalary &&
        userCV.PreferedBaseSalary.MinSalary &&
        userCV.PreferedBaseSalary.PaymentFrequency &&
        userCV.PreferedBonus?.length > 0 &&
        userCV.PreferedEngagement?.length > 0 &&
        userCV.PreferedLocation?.length > 0 &&
        userCV.Availability &&
        (userCV.Availability.Immediate ||
            (userCV.Availability.Number && userCV.Availability.Period))
    )
}

export function validateExperience(experience, experienceType) {
    if (
        !experience.Title ||
        !experience.StartDate ||
        !experience.EndDate ||
        !experience.Description ||
        experience.Description.length < 50 ||
        experience.Description.length > 4000
    ) {
        return false
    }

    if (experienceType === 'Work' && experience.AssistantFinished !== true) {
        return false
    }

    return true
}

export function checkExperiences(userCV) {
    for (let experience of userCV.Experiences) {
        if (!experience.CompanyName) {
            return false
        }

        for (let expInCompany of experience.ExperiencesInCompany) {
            if (
                !validateExperience(expInCompany, expInCompany.ExperienceType)
            ) {
                return false
            }
        }
    }
    return true
}

export function checkLanguagesAndEducation(userCV) {
    for (let language of userCV.Languages) {
        if (!language.Language || !language.Proficiency) {
            return false
        }
    }

    for (let education of userCV.Education) {
        if (
            !education.SchoolName ||
            !education.Field ||
            !education.StartDate ||
            !education.EndDate
        ) {
            return false
        }
    }

    return true
}

export function getCurrentStep(userCV, userData) {
    if (!hasUploadedCV(userCV)) {
        return 1
    } else if (!hasValidInfo(userData, userCV)) {
        return 2
    } else if (!hasValidPref(userCV)) {
        return 3
    } else if (
        !checkExperiences(userCV) ||
        !checkLanguagesAndEducation(userCV) ||
        !userCV.Finalized
    ) {
        return 4
    }
    return 0
}

export const validateAndUpdateCV = async (prevUserCV, updatedUserCV) => {
    const validExperiences = updatedUserCV.Experiences.filter((exp) => {
        if (!exp.CompanyName) {
            return false
        }

        exp.ExperiencesInCompany = exp.ExperiencesInCompany.filter(
            (subExp) => subExp.Title,
        )
        return exp.ExperiencesInCompany.length > 0
    })

    if (validExperiences.length !== updatedUserCV.Experiences.length) {
        console.error('Some experiences are missing required fields')
    }

    const validEducation = updatedUserCV.Education.filter((edu) => {
        if (!edu.SchoolName) {
            return false
        }
        return true
    })

    if (validEducation.length !== updatedUserCV.Education.length) {
        console.error('Some education entries are missing required fields')
    }

    const validLanguages = updatedUserCV.Languages.filter((lang) => {
        if (!lang.Language || !lang.Proficiency) {
            return false
        }
        return true
    })

    if (validLanguages.length !== updatedUserCV.Languages.length) {
        console.error('Some language entries are missing required fields')
    }

    const updatedCV = {
        ...prevUserCV,
        Experiences: validExperiences,
        Education: validEducation,
        Languages: validLanguages,
    }

    return updatedCV
}
