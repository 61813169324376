import React, { memo } from 'react'
import CreatableSelect from 'react-select/creatable'
import { VisibilityButton } from '@compUtils'

import { customStylesInvalidIn, customStylesValidIn } from '@styles/styles'

const OrganizationInfoComponent = memo(
    ({ experience, onChange, companyOptions }) => {
        const handleCompanyChange = (selectedOption) => {
            onChange('CompanyId', selectedOption ? selectedOption.value : '')
            onChange('CompanyName', selectedOption ? selectedOption.label : '')
            onChange(
                'CompanyWebSite',
                selectedOption ? selectedOption.website : '',
            )
            onChange(
                'CompanyDescription',
                selectedOption ? selectedOption.description : '',
            )
        }

        const handleWebsiteChange = (e) => {
            onChange('CompanyWebSite', e.target.value)
        }

        return (
            <div className="new-info-experience-modal-container">
                <label
                    className="new-info-experience-modal-label"
                    htmlFor="company-website"
                >
                    Organization Name and Website
                    <VisibilityButton
                        detailKeyOrPath={`Companies.${experience.CompanyId}`}
                        isCompany={true}
                    />
                </label>
                <div className="new-info-experience-modal-engagement">
                    <CreatableSelect
                        isClearable
                        value={{
                            value: experience.CompanyId,
                            label: experience.CompanyName,
                        }}
                        onChange={handleCompanyChange}
                        options={companyOptions}
                        placeholder="Select or create a company..."
                        styles={
                            experience.CompanyName
                                ? customStylesValidIn
                                : customStylesInvalidIn
                        }
                    />
                    <div className="new-info-experience-modal-container">
                        <input
                            id="company-website"
                            type="url"
                            value={experience.CompanyWebSite || ''}
                            onChange={handleWebsiteChange}
                            placeholder="Enter company website URL..."
                            style={{ height: '38px' }}
                            className={
                                experience.CompanyWebSite
                                    ? 'valid-border'
                                    : 'facultative-border'
                            }
                        />
                    </div>
                </div>
            </div>
        )
    },
)

export default OrganizationInfoComponent
