import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'

import { useHire } from '@hooks'
import { deleteJD } from '@utils'
import { Dots } from '@assets'

function JDCardDots({ job }) {
    const queryClient = useQueryClient()
    const { companyId } = useParams()
    const { getRole } = useHire()
    const role = getRole(job)
    const [menuOpen, setMenuOpen] = useState(null)

    const deleteMutation = useMutation(deleteJD, {
        onMutate: async (jobId) => {
            const previousJobs = queryClient.getQueryData(
                `jobDescriptions-${companyId}`,
            )
            queryClient.setQueryData(`jobDescriptions-${companyId}`, (old) =>
                old.filter((j) => j._id !== jobId),
            )
            return { previousJobs }
        },
        onError: (err, jobId, context) => {
            queryClient.setQueryData(
                `jobDescriptions-${companyId}`,
                context.previousJobs,
            )
        },
        onSettled: () => {
            queryClient.invalidateQueries(`jobDescriptions-${companyId}`)
            toast.success('Job deleted successfully')
        },
    })

    return (
        <div>
            <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={(e) => {
                    e.stopPropagation()
                    setMenuOpen(e.currentTarget)
                }}
            >
                <img
                    src={Dots}
                    alt="les trois petits points"
                    className="dots-button-image"
                />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={menuOpen}
                keepMounted
                open={Boolean(menuOpen)}
                onClose={(e) => {
                    e.stopPropagation()
                    setMenuOpen(null)
                }}
            >
                <Tooltip
                    title="You need to be owner"
                    disableHoverListener={role >= 4}
                >
                    <div>
                        <MenuItem
                            disabled={role < 4}
                            onClick={(e) => {
                                e.stopPropagation()
                                setMenuOpen(null)
                                deleteMutation.mutate(job._id)
                            }}
                        >
                            Delete
                        </MenuItem>
                    </div>
                </Tooltip>
            </Menu>
        </div>
    )
}

export default JDCardDots
