import React, { useState } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useAuth, useUserData } from '@context'
import { ProfilePicture } from '@sections'
import { Feedback } from '@compUtils'

import { ArrowDown, ArrowRight } from '@assets'

function HeaderProfile() {
    const { userData } = useUserData()
    const { logout } = useAuth()
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    if (!userData) return null

    return (
        <div className="header-profile">
            <div className="header-profile-container">
                <ProfilePicture />
                <div className="text-buttons-container" onClick={handleClick}>
                    <span>Hi, {userData.Name}</span>
                    <img
                        src={anchorEl ? ArrowDown : ArrowRight}
                        alt="Arrow Down"
                    />
                </div>
                <Feedback />
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {/* <MenuItem onClick={handleClose}>My Profile</MenuItem>
                    <MenuItem onClick={handleClose}>Settings</MenuItem> */}
                    <MenuItem
                        onClick={() => {
                            handleClose()
                            logout()
                        }}
                    >
                        Logout
                    </MenuItem>
                </Menu>
            </div>
        </div>
    )
}

export default HeaderProfile
