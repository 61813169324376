import React, { useState } from 'react'
import { useCV } from '@context/CVContext'
import { components } from 'react-select'
import Creatable from 'react-select/creatable'
import makeAnimated from 'react-select/animated'
import { VisibilityButton } from '@compUtils'
import Tooltip from '@mui/material/Tooltip'

import { Info } from '@assets'
import { customStylesValid, customStylesInvalid } from '@styles/styles'

const animatedComponents = makeAnimated()

function PreferedBonus({ isModal = false }) {
    const { cvData, updateCV } = useCV()
    const [selectedBonuses, setSelectedBonuses] = useState(
        cvData.PreferedBonus?.map((bonus) => ({
            value: bonus,
            label: bonus,
        })) || [],
    )

    const options = [
        {
            label: 'No Preference',
            options: ['Any'].map((type) => ({ label: type, value: type })),
        },
        {
            label: 'Bonus',
            options: [
                'Performance Bonus',
                'Year End Bonus',
                '13th Month',
                '14th Month',
                'Production Bonus',
                'Holiday Bonus',
                'Signing Bonus',
                'Retention Bonus',
                'Business Travel Bonus',
            ]?.map((bonus) => ({ label: bonus, value: bonus })),
        },
        {
            label: 'Benefits',
            options: [
                'Medical Insurance',
                'Dental Insurance',
                'Vision Insurance',
                'Health Insurance for Dependents',
                'Retirement Plan',
                'Retirement Contribution',
                'ESOPs',
                'Paid Leave',
                'Sick Leave',
                'Life Insurance',
                'Life Insurance for Dependents',
                'Maternity Leave',
                'Paternity Leave',
                'On Site Childcare',
                'Childcare Sponsoring',
                'Profit Sharing Plans',
                'Relocation Package',
                'Housing Allowance',
                'Flight Tickets',
                'Company Trip',
                'Scholarship',
                'Training Program',
            ]?.map((benefit) => ({ label: benefit, value: benefit })),
        },
    ]

    const [allOptions] = useState(options)

    const handleChange = (selectedOptions, { action, option }) => {
        setSelectedBonuses(selectedOptions || [])

        const bonusValues = selectedOptions?.map((option) => option.value)

        updateCV((prevData) => ({
            ...prevData,
            PreferedBonus: bonusValues,
        }))
    }

    const formatOptionLabel = ({ label, value, data }) => {
        if (data && data.__isNew__) {
            return (
                <div>
                    <components.GroupHeading>New Entry</components.GroupHeading>
                    {label}
                </div>
            )
        }
        return label
    }

    const customStyles = {
        container: (provided) => ({
            ...provided,
            width: '100%',
        }),
        valueContainer: (provided) => ({
            ...provided,
            maxHeight: '38px',
            overflow: 'auto',
        }),
        menuList: (provided) => ({
            ...provided,
            maxHeight: '170px',
            overflowY: 'auto',
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 1000,
        }),
    }

    const placeholder =
        selectedBonuses && selectedBonuses?.length > 0
            ? `${selectedBonuses?.length} selected`
            : 'Select bonuses and benefits'

    return (
        <div
            className={`new-component ${
                selectedBonuses?.length === 0
                    ? 'invalid-border'
                    : 'valid-border'
            }`}
            style={isModal ? { width: 'auto' } : {}}
        >
            <div className="new-info-wrapper">
                <div className="new-info-header">
                    <div className="new-info-header-icon">
                        Preferred Bonus & Benefits
                        <Tooltip
                            title="You can add your own bonus by typing it and pressing Enter !"
                            placement="top"
                        >
                            <img src={Info} alt="info" className="info-icon" />
                        </Tooltip>
                    </div>
                    <VisibilityButton detailKeyOrPath={'PreferedBonus'} />
                </div>
                <div className="new-info-container">
                    <Creatable
                        isMulti
                        components={animatedComponents}
                        value={selectedBonuses}
                        onChange={handleChange}
                        options={allOptions}
                        classNamePrefix="select"
                        placeholder={placeholder}
                        formatOptionLabel={formatOptionLabel}
                        isSearchable
                        styles={{
                            ...customStyles,
                            ...(selectedBonuses?.length > 0
                                ? customStylesValid
                                : customStylesInvalid),
                        }}
                        isCreatable={true}
                        openMenuOnFocus={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default PreferedBonus
