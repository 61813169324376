import React, { createContext, useState, useContext } from 'react'

const TmpDataContext = createContext()

export const useTmpData = () => {
    return useContext(TmpDataContext)
}

export const TmpDataProvider = ({ children }) => {
    const [feedback, setFeedback] = useState('')
    const [feedbackImages, setFeedbackImages] = useState([])
    const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [menuOpen, setMenuOpen] = useState(null)
    const [allowClose, setAllowClose] = useState(true)
    const [isEditing, setIsEditing] = useState(false)

    const handleOpenFeedbackModal = () => setIsFeedbackModalOpen(true)
    const handleCloseFeedbackModal = () => setIsFeedbackModalOpen(false)

    return (
        <TmpDataContext.Provider
            value={{
                feedback,
                setFeedback,
                feedbackImages,
                setFeedbackImages,
                isFeedbackModalOpen,
                handleOpenFeedbackModal,
                handleCloseFeedbackModal,
                deleteModalOpen,
                setDeleteModalOpen,
                menuOpen,
                setMenuOpen,
                allowClose,
                setAllowClose,
                isEditing,
                setIsEditing,
            }}
        >
            {children}
        </TmpDataContext.Provider>
    )
}
