export const instructionTypes = {
    FIRST_MESSAGE: 'first_message',
    QUESTION: 'question',
    DESCRIPTION: 'description',
    REGENERATE: 'regenerate',
}

export const descriptionState = {
    FETCHED: 'FETCHED',
    SHOW_DESCRIPTION: 'SHOW_DESCRIPTION',
    WAIT_IMPROVEMENT: 'WAIT_IMPROVEMENT',
    ASSISTANT_ACTIVE: 'ASSISTANT_ACTIVE',
    ASSISTANT_WRITING: 'ASSISTANT_WRITING',
    ASSISTANT_REGENERATE: 'ASSISTANT_REGENERATE',
    ASSISTANT_FINISHED: 'ASSISTANT_FINISHED',
    REGENERATING: 'REGENERATING',
}
