import React from 'react'
import { useCV } from '@context/CVContext'
import { VisibilityButton } from '@compUtils'

function Gender({ isModal = false }) {
    const { cvData, updateCV } = useCV()

    const handleGenderSelection = (selectedGender) => {
        updateCV((prevData) => ({ ...prevData, Gender: selectedGender }))
    }

    return (
        <div
            className={`new-component ${
                cvData.Gender === '' ? 'invalid-border' : 'valid-border'
            }`}
            style={isModal ? { width: 'auto' } : {}}
        >
            <div className="new-info-wrapper">
                <div className="new-info-header">
                    Gender
                    <VisibilityButton detailKeyOrPath={'Gender'} />
                </div>
                <div className="new-info-container">
                    <button
                        className={`gender-button ${
                            cvData.Gender === 'Male' ? 'active' : ''
                        }`}
                        onClick={() => handleGenderSelection('Male')}
                    >
                        Male
                    </button>
                    <button
                        className={`gender-button ${
                            cvData.Gender === 'Female' ? 'active' : ''
                        }`}
                        onClick={() => handleGenderSelection('Female')}
                    >
                        Female
                    </button>
                    <button
                        className={`gender-button ${
                            cvData.Gender === 'Rather Not Say' ? 'active' : ''
                        }`}
                        onClick={() => handleGenderSelection('Rather Not Say')}
                    >
                        Rather Not Say
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Gender
