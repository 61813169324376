import React from 'react'
import { useNavigate } from 'react-router-dom'
import CompanyCardDots from './companyCardDots'
import { formatLocation } from '@utils'

import './companyCard.css'

function CompanyCard({ company, isSelected }) {
    const navigate = useNavigate()

    const handleClick = () => {
        navigate(`/hire/company/${company._id}`, { state: company })
    }

    const cardStyle = isSelected ? 'company-card selected' : 'company-card'

    return (
        <div className={cardStyle} onClick={handleClick}>
            <div className="company-card-infos">
                <div className="company-card-infos-header">
                    <h2 className="experience-title">{company.Name}</h2>
                    <CompanyCardDots company={company} />
                </div>
                <span>
                    {company.Industry.map((ind) => {
                        return ` ${ind}`
                    })}
                </span>
                <span>{company.Size}</span>
                <span>{formatLocation(company.Location)}</span>
            </div>
        </div>
    )
}

export default CompanyCard
