import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useUserData, useAlert, useCV, useTmpData } from '@context'
import FinalizeModal from '@modals/FinalizeModal'
import { Modal } from '@modals'
import PDF from '@modals/PDF'

function FinalizeButton() {
    const { updateCV, accessibleSteps } = useCV()
    const { showAlert } = useAlert()
    const [isModalOpen, setIsModalOpen] = useState(false)

    return (
        <>
            <button
                className="hn-button-blue"
                onClick={() => {
                    if (!accessibleSteps.finalize) {
                        showAlert(
                            'Please fill all the sections before continuing.',
                            'alert-error',
                        )
                    } else {
                        setIsModalOpen(true)
                    }
                }}
            >
                Finalize
            </button>
            <Modal
                isOpen={isModalOpen}
                onClose={(summary) => {
                    updateCV((prevCV) => ({
                        ...prevCV,
                        Summary: summary,
                        Finalized: true,
                    }))
                    setIsModalOpen(false)
                }}
                Component={FinalizeModal}
                className="modal-content wide"
            />
        </>
    )
}

function PreviewButton() {
    const { userData } = useUserData()
    const { cvData } = useCV()
    const [isPreviewOpen, setIsPreviewOpen] = useState(false)

    return (
        <>
            <button
                className="hn-button-blue"
                onClick={() => {
                    setIsPreviewOpen(true)
                }}
            >
                Preview
            </button>
            <Modal
                isOpen={isPreviewOpen}
                onClose={() => setIsPreviewOpen(false)}
                initialValue={{ userData, userCV: cvData }}
                className="modal-content wide-pdf"
                Component={PDF}
            />
        </>
    )
}

function EditButton() {
    const { isEditing, setIsEditing } = useTmpData()

    return (
        <>
            <button
                className="hn-button-white"
                onClick={() => {
                    setIsEditing(!isEditing)
                }}
            >
                {isEditing ? 'Save' : 'Edit'}
            </button>
        </>
    )
}

function ProfileButtons() {
    const { pathname } = useLocation()
    const { cvData, accessibleSteps } = useCV()
    const { showAlert } = useAlert()
    const navigate = useNavigate()

    const handleNavigation = (path, checkStep) => {
        if (checkStep && !accessibleSteps[checkStep]) {
            showAlert(
                'Please fill all the sections before continuing.',
                'alert-error',
            )
        } else {
            navigate(path)
        }
    }

    const renderNavigationButtons = (
        label,
        backPath,
        nextPath,
        nextStepKey,
    ) => (
        <div className="header-navigation">
            <div className="profile-text">{label}</div>
            <div className="hn-buttons-container">
                {backPath && (
                    <button
                        className="hn-button-white"
                        onClick={() => navigate(backPath)}
                    >
                        Back
                    </button>
                )}
                {nextPath && (
                    <button
                        className="hn-button-blue"
                        onClick={() => handleNavigation(nextPath, nextStepKey)}
                    >
                        Next
                    </button>
                )}
            </div>
        </div>
    )

    const renderStartSection = () => (
        <>{renderNavigationButtons('Start', null, '/profile/information')}</>
    )

    const renderInformationSection = () => (
        <>
            {renderNavigationButtons(
                'Information',
                '/profile/start',
                '/profile/preferences',
                'information',
            )}
        </>
    )

    const renderPreferencesSection = () => (
        <>
            {renderNavigationButtons(
                'Preferences',
                '/profile/information',
                '/profile/finalize',
                'preference',
            )}
        </>
    )

    const renderFinalizeSection = () => (
        <div className="header-navigation">
            <div className="profile-text">{'Finalize'}</div>
            <div className="hn-buttons-container">
                <button
                    className="hn-button-white"
                    onClick={() => navigate('/profile/preferences')}
                >
                    Back
                </button>
                <FinalizeButton />
                <PreviewButton />
            </div>
        </div>
    )

    const renderNotFoundSection = () => (
        <div className="header-navigation">
            <div className="profile-text">{'Loading...'}</div>
        </div>
    )

    if (!cvData?.Finalized) {
        if (/^\/profile\/start/.test(pathname)) {
            return renderStartSection()
        } else if (/^\/profile\/information/.test(pathname)) {
            return renderInformationSection()
        } else if (/^\/profile\/preferences/.test(pathname)) {
            return renderPreferencesSection()
        } else if (/^\/profile\/finalize/.test(pathname)) {
            return renderFinalizeSection()
        } else {
            return renderNotFoundSection()
        }
    }

    return (
        <div className="header-navigation">
            <div className="profile-text">{'Profile'}</div>
            <div className="hn-buttons-container">
                <PreviewButton />
                <EditButton />
            </div>
        </div>
    )
}

export default ProfileButtons
