import React, { useState, useEffect } from 'react'
import { useCV } from '@context/CVContext'
import { useMobileScreen } from '@hooks'
import { currencies } from '@utils'
import Select from 'react-select'
import { VisibilityButton } from '@compUtils'

import { customStylesValidIn, customStylesInvalidIn } from '@styles/styles'

function PreferedBaseSalary({ isModal = false }) {
    const { cvData, updateCV } = useCV()
    const isMobile = useMobileScreen()

    const [minSalary, setMinSalary] = useState(
        cvData.PreferedBaseSalary?.MinSalary || '',
    )
    const [maxSalary, setMaxSalary] = useState(
        cvData.PreferedBaseSalary?.MaxSalary || '',
    )
    const [currency, setCurrency] = useState(
        cvData.PreferedBaseSalary?.Currency
            ? {
                  value: cvData.PreferedBaseSalary.Currency,
                  label: `${cvData.PreferedBaseSalary.Currency}`,
              }
            : cvData.CurrentBaseSalary?.Currency
              ? {
                    value: cvData.CurrentBaseSalary.Currency,
                    label: `${cvData.CurrentBaseSalary.Currency}`,
                }
              : null,
    )
    useEffect(() => {
        if (currency) {
            updateCV((prevData) => ({
                ...prevData,
                PreferedBaseSalary: {
                    ...(prevData.PreferedBaseSalary || {}),
                    Currency: currency.value,
                },
            }))
        }
    }, [currency, updateCV])

    const [paymentFrequency, setPaymentFrequency] = useState(
        cvData.PreferedBaseSalary?.PaymentFrequency
            ? {
                  value: cvData.PreferedBaseSalary.PaymentFrequency,
                  label: cvData.PreferedBaseSalary.PaymentFrequency,
              }
            : cvData.CurrentBaseSalary?.PaymentFrequency
              ? {
                    value: cvData.CurrentBaseSalary.PaymentFrequency,
                    label: cvData.CurrentBaseSalary.PaymentFrequency,
                }
              : null,
    )
    useEffect(() => {
        if (paymentFrequency) {
            updateCV((prevData) => ({
                ...prevData,
                PreferedBaseSalary: {
                    ...(prevData.PreferedBaseSalary || {}),
                    PaymentFrequency: paymentFrequency.value,
                },
            }))
        }
    }, [paymentFrequency, updateCV])

    const currencyOptions = Object.keys(currencies)?.map((key) => ({
        value: key,
        label: `${key} - ${currencies[key].name}`,
    }))

    const frequencyOptions = [
        { value: 'Weekly', label: 'Weekly' },
        { value: 'Monthly', label: 'Monthly' },
        { value: 'Annually', label: 'Annually' },
        { value: 'Project duration', label: 'Project duration' },
    ]

    const formatSalary = (value) => {
        const numericValue = parseFloat(value.replace(/[^0-9.]/g, ''))
        if (isNaN(numericValue)) return ''
        return numericValue.toLocaleString('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        })
    }

    const handleMinSalaryBlur = () => {
        const formattedSalary = formatSalary(minSalary)
        setMinSalary(formattedSalary)
        updateCV((prevData) => ({
            ...prevData,
            PreferedBaseSalary: {
                ...(prevData.PreferedBaseSalary || {}),
                MinSalary: formattedSalary,
            },
        }))
    }

    const handleMaxSalaryBlur = () => {
        const formattedSalary = formatSalary(
            parseInt(maxSalary) < parseInt(minSalary) ? minSalary : maxSalary,
        )
        setMaxSalary(formattedSalary)
        updateCV((prevData) => ({
            ...prevData,
            PreferedBaseSalary: {
                ...(prevData.PreferedBaseSalary || {}),
                MaxSalary: formattedSalary,
            },
        }))
    }

    const handleCurrencyChange = (selectedOption) => {
        selectedOption.label = selectedOption.label.split(' - ')[0]
        setCurrency(selectedOption)
        updateCV((prevData) => ({
            ...prevData,
            PreferedBaseSalary: {
                ...(prevData.PreferedBaseSalary || {}),
                Currency: selectedOption.value,
            },
        }))
    }

    const handleFrequencyChange = (selectedOption) => {
        setPaymentFrequency(selectedOption)
        updateCV((prevData) => ({
            ...prevData,
            PreferedBaseSalary: {
                ...(prevData.PreferedBaseSalary || {}),
                PaymentFrequency: selectedOption.value,
            },
        }))
    }

    return (
        <div
            className={`new-component ${
                !minSalary || !currency || !paymentFrequency
                    ? 'invalid-border'
                    : 'valid-border'
            } ${isMobile ? 'pbs-phone' : ''}`}
            style={isModal ? { width: 'auto' } : { width: `calc(100% - 52px)` }}
        >
            <div className="new-info-wrapper">
                <div className="new-info-header">
                    Preferred Base Salary
                    <VisibilityButton detailKeyOrPath={'PreferedBaseSalary'} />
                </div>
                {isMobile ? (
                    <>
                        <div className="new-info-container preferedbs-mobile">
                            <FirstPart
                                minSalary={minSalary}
                                setMinSalary={setMinSalary}
                                handleMinSalaryBlur={handleMinSalaryBlur}
                                maxSalary={maxSalary}
                                setMaxSalary={setMaxSalary}
                                handleMaxSalaryBlur={handleMaxSalaryBlur}
                            />
                        </div>
                        <div className="new-info-container preferedbs-mobile">
                            <SecondPart
                                currency={currency}
                                handleCurrencyChange={handleCurrencyChange}
                                currencyOptions={currencyOptions}
                                paymentFrequency={paymentFrequency}
                                handleFrequencyChange={handleFrequencyChange}
                                frequencyOptions={frequencyOptions}
                            />
                        </div>
                    </>
                ) : (
                    <div className="new-info-container preferedbs">
                        <FirstPart
                            minSalary={minSalary}
                            setMinSalary={setMinSalary}
                            handleMinSalaryBlur={handleMinSalaryBlur}
                            maxSalary={maxSalary}
                            setMaxSalary={setMaxSalary}
                            handleMaxSalaryBlur={handleMaxSalaryBlur}
                        />
                        <SecondPart
                            currency={currency}
                            handleCurrencyChange={handleCurrencyChange}
                            currencyOptions={currencyOptions}
                            paymentFrequency={paymentFrequency}
                            handleFrequencyChange={handleFrequencyChange}
                            frequencyOptions={frequencyOptions}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

function FirstPart({
    minSalary,
    setMinSalary,
    handleMinSalaryBlur,
    maxSalary,
    setMaxSalary,
    handleMaxSalaryBlur,
}) {
    return (
        <>
            From
            <input
                type="text"
                placeholder="Min"
                value={minSalary}
                onChange={(e) => setMinSalary(e.target.value)}
                onBlur={() => handleMinSalaryBlur()}
                className={minSalary ? 'valid-border' : 'invalid-border'}
            />
            To
            <input
                type="text"
                placeholder="Max"
                value={maxSalary}
                onChange={(e) => setMaxSalary(e.target.value)}
                onBlur={() => handleMaxSalaryBlur()}
                className={maxSalary ? 'valid-border' : 'facultative-border'}
            />
        </>
    )
}

function SecondPart({
    currency,
    handleCurrencyChange,
    currencyOptions,
    paymentFrequency,
    handleFrequencyChange,
    frequencyOptions,
}) {
    return (
        <>
            <Select
                value={currency}
                onChange={handleCurrencyChange}
                options={currencyOptions}
                isSearchable
                placeholder="Currency"
                openMenuOnFocus={true}
                styles={currency ? customStylesValidIn : customStylesInvalidIn}
            />
            <Select
                value={paymentFrequency}
                onChange={handleFrequencyChange}
                options={frequencyOptions}
                isSearchable
                placeholder="Frequency"
                openMenuOnFocus={true}
                styles={
                    paymentFrequency
                        ? customStylesValidIn
                        : customStylesInvalidIn
                }
            />
        </>
    )
}

export default PreferedBaseSalary
