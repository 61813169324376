import React, { useRef, useEffect, memo } from 'react'
import { useLocation } from 'react-router-dom'
import { useContentOpen } from '@context/ContentOpenContext'
import { useMobileScreen } from '@hooks'
import SCProfile from './SCProfile/SCProfile'

import { SideMenuRight } from '@assets'

import './SecondaryContent.css'

function SecondaryContent() {
    const isMobile = useMobileScreen()
    const { isSecondaryContentOpen, openSecondaryContent, closeContent } =
        useContentOpen()
    const { pathname } = useLocation()
    const contentRef = useRef()

    const renderSCBasedOnRoute = () => {
        if (/^\/profile/.test(pathname)) {
            return <SCProfile />
        } else {
            return null
        }
    }

    const toggleContent = () => {
        if (isSecondaryContentOpen) {
            closeContent()
        } else {
            openSecondaryContent()
        }
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                contentRef.current &&
                !contentRef.current.contains(event.target)
            ) {
                event.stopPropagation()
                setTimeout(() => {
                    closeContent()
                }, 10)
            }
        }

        if (isSecondaryContentOpen) {
            document.addEventListener('mousedown', handleClickOutside)
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [isSecondaryContentOpen, closeContent])

    const comp = renderSCBasedOnRoute()

    return (
        <>
            {isMobile && !!comp && (
                <button
                    className="hamburger-button-secondary"
                    onClick={toggleContent}
                >
                    <img
                        src={SideMenuRight}
                        alt="Menu"
                        className="side-menu-icon"
                    />
                </button>
            )}
            <div
                className={`secondary-content ${
                    isSecondaryContentOpen && 'open'
                }`}
                ref={contentRef}
            >
                {comp}
            </div>
        </>
    )
}

export default memo(SecondaryContent)
