import React, { useState, useEffect } from 'react'
import { Modal } from '@modals'
import ObjectID from 'bson-objectid'
import { useCVUpdate } from '@hooks'
import LanguageModal from '@modals/Experiences/LanguageModal'

import { Add } from '@assets'

import './AddSections.css'

function AddLanguage({ hasParent = false, triggerAddLanguage }) {
    const { handleModalCloseGeneric } = useCVUpdate()
    const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false)
    const [initialValue, setInitialValue] = useState({})

    const handleModalClose = async (languageChanges) => {
        setIsLanguageModalOpen(false)
        if (!languageChanges) return
        handleModalCloseGeneric(
            languageChanges,
            'Languages',
            languageChanges.id,
        )
    }

    useEffect(() => {
        if (triggerAddLanguage) {
            handleAddLanguage()
        }
    }, [triggerAddLanguage])

    const handleAddLanguage = () => {
        const id = ObjectID().toString()
        setInitialValue({
            id: id,
            _id: id,
        })
        setIsLanguageModalOpen(true)
    }

    return (
        <>
            {!hasParent && (
                <div className="add-section">
                    <img
                        src={Add}
                        alt="Add"
                        className="add-section-icon"
                        onClick={handleAddLanguage}
                    />
                </div>
            )}
            <Modal
                isOpen={isLanguageModalOpen}
                onClose={handleModalClose}
                modal="Language"
                initialValue={initialValue}
                className="modal-content wide"
                Component={LanguageModal}
            />
        </>
    )
}

export default AddLanguage
