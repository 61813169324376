import React, { useState, useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { SpinnerCircular } from 'spinners-react'
import { createCompany, createJD } from '@utils'
import { Modal } from '@modals'
import { useJD } from '@context'
import { useKeyboardShortcut } from '@hooks'
import CompanyModal from '@hire/Company/companyModal'
import InfoCard from '@hire/JobDescription/JDPages/InfoCard'

function CompanyButtons() {
    const queryClient = useQueryClient()
    const [isCompModalOpen, setIsCompModalOpen] = useState(false)

    const defaultCompany = {
        Name: '',
        Industry: [],
        Size: '',
        Location: '',
        Description: '',
    }

    const handleClose = async (data) => {
        setIsCompModalOpen(false)
        try {
            await createCompany(data)
            toast.success('Company created successfully')
            queryClient.invalidateQueries(`companies`)
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }

    return (
        <div className="header-navigation">
            <div className="profile-text">
                <span>Companies</span>
            </div>

            <div className="hn-buttons-container">
                <button
                    className="hn-button-blue"
                    onClick={() => {
                        setIsCompModalOpen(true)
                    }}
                >
                    Add Company
                </button>
            </div>
            <Modal
                isOpen={isCompModalOpen}
                onClose={handleClose}
                initialValue={defaultCompany}
                Component={CompanyModal}
                className="modal-content wide"
            />
        </div>
    )
}

function JDButtons() {
    const navigate = useNavigate()
    const location = useLocation()
    const { setInitialJDData } = useJD()
    const { useBackNext, useGeneralShortcut } = useKeyboardShortcut()
    const [isCreateOpen, setIsCreateOpen] = useState(false)
    const [jobTitle, setJobTitle] = useState('')

    const match = location.pathname.match(/\/company\/([^/]+)/)
    const companyId = match ? match[1] : null

    const { mutate, isLoading } = useMutation(
        (data) => createJD(data, companyId),
        {
            onSuccess: (data) => {
                setInitialJDData(data)
                navigate(`/hire/job/${data._id}/details`)
            },
            onError: (e) => {
                toast.error(e.response.data.message)
            },
        },
    )

    const handleBackClick = useCallback(() => {
        if (isCreateOpen) return
        navigate('/hire')
    }, [navigate, isCreateOpen])

    const handleCreateClick = useCallback(() => {
        setIsCreateOpen(true)
    }, [setIsCreateOpen])

    const handleEnter = useCallback(
        (e) => {
            if (isCreateOpen && e.key === 'Enter') {
                e.preventDefault()
                mutate({ Title: jobTitle })
            }
        },
        [isCreateOpen, jobTitle, mutate],
    )

    useGeneralShortcut(handleEnter)

    useBackNext(handleBackClick, handleCreateClick)

    return (
        <div className="header-navigation">
            <div className="profile-text">
                <span>Job Description</span>
            </div>

            {isLoading ? (
                <div>
                    Creating{' '}
                    <SpinnerCircular
                        size={15}
                        thickness={100}
                        speed={100}
                        color="#007AFF"
                        secondaryColor="#66afff"
                    />
                </div>
            ) : (
                <div className="hn-buttons-container">
                    <button
                        className="hn-button-white"
                        onClick={handleBackClick}
                    >
                        Back
                    </button>
                    <button
                        className="hn-button-blue"
                        onClick={handleCreateClick}
                    >
                        Create
                    </button>
                </div>
            )}
            <Modal
                isOpen={isCreateOpen}
                onClose={() => setIsCreateOpen(false)}
                Component={InfoCard}
                className="modal-content"
                initialProps={{
                    header: 'Job Title',
                    inputType: isLoading ? 'loading' : 'createJD',
                    value: isLoading ? 'Creating Job Description...' : jobTitle,
                    onChange: setJobTitle,
                    onSubmit: () => mutate({ Title: jobTitle }),
                    placeholder: 'Please enter the job title...',
                }}
            />
        </div>
    )
}

function NavigationButtons({ label, backPath, nextPath, step, isEnabled }) {
    const { canAccessStep } = useJD()
    const { useBackNext } = useKeyboardShortcut()
    const navigate = useNavigate()

    const handleBackClick = useCallback(() => {
        navigate(backPath)
    }, [navigate, backPath])

    const handleNextClick = useCallback(() => {
        if (canAccessStep(step)) {
            navigate(nextPath)
        } else {
            toast.error('Please fill all the required fields')
        }
    }, [navigate, nextPath, canAccessStep, step])

    useBackNext(handleBackClick, handleNextClick)

    return (
        <div className="header-navigation">
            <div className="profile-text">
                <span>{label}</span>
            </div>

            <div className="hn-buttons-container">
                <button
                    className="hn-button-white"
                    onClick={handleBackClick}
                    disabled={!isEnabled}
                >
                    Back
                </button>
                <button className="hn-button-blue" onClick={handleNextClick}>
                    Next
                </button>
            </div>
        </div>
    )
}

function JDDetailsButtons() {
    const { jd } = useJD()

    return (
        <NavigationButtons
            label={jd?.Title || 'Job Description'}
            backPath={`/hire/company/${jd?.Company}`}
            nextPath={`/hire/job/${jd?._id}/requirements`}
            step="requirements"
            isEnabled={!!jd}
        />
    )
}

function JDRequirementsButtons() {
    const { jd } = useJD()

    return (
        <NavigationButtons
            label={jd?.Title || 'Job Description'}
            backPath={`/hire/job/${jd?._id}/details`}
            nextPath={`/hire/job/${jd?._id}/draft`}
            step="draft"
            isEnabled={!!jd}
        />
    )
}

function JDDraftButtons() {
    const { jd } = useJD()

    return (
        <NavigationButtons
            label={jd?.Title || 'Job Description'}
            backPath={`/hire/job/${jd?._id}/requirements`}
            nextPath={`/hire/job/${jd?._id}/finalize`}
            step="finalize"
            isEnabled={!!jd}
        />
    )
}

function JDFinalizeButtons() {
    const { jd } = useJD()

    return (
        <NavigationButtons
            label={jd?.Title || 'Job Description'}
            backPath={`/hire/job/${jd?._id}/draft`}
            nextPath={`/hire/job/${jd?._id}/finalize`}
            step="finalize"
            isEnabled={!!jd}
        />
    )
}

function HireButtons() {
    const { pathname } = useLocation()

    if (/^\/hire$/.test(pathname)) {
        return <CompanyButtons />
    } else if (/^\/hire\/company*/.test(pathname)) {
        return <JDButtons />
    } else if (/^\/hire\/job\/.*\/details/.test(pathname)) {
        return <JDDetailsButtons />
    } else if (/^\/hire\/job\/.*\/requirements/.test(pathname)) {
        return <JDRequirementsButtons />
    } else if (/^\/hire\/job\/.*\/draft/.test(pathname)) {
        return <JDDraftButtons />
    } else if (/^\/hire\/job\/.*\/finalize/.test(pathname)) {
        return <JDFinalizeButtons />
    } else {
        return (
            <div className="header-navigation">
                <div className="profile-text">
                    <span>Loading...</span>
                </div>
            </div>
        )
    }
}

export default HireButtons
