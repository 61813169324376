import React from 'react'
import { EXblue, EXorange, EXred } from '@assets'

const ErrorMessage = ({ message, className }) => {
    const icons = {
        'editing-blue': EXblue,
        'editing-orange': EXorange,
        'editing-red': EXred,
    }

    return (
        <div className={`editing-message ${className}`}>
            <img src={icons[className]} alt="exclamation" />
            {message}
        </div>
    )
}

export default ErrorMessage
