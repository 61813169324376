import React from 'react'
import { useQuery } from 'react-query'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { getAccountType } from '@utils'
import { Profile, Home, Hire, Join, Admin } from '@pages'

import './MainContent.css'

function ProtectedRoute({ children, isAdmin }) {
    return isAdmin ? children : <Navigate to="/profile" replace />
}

function MainContent() {
    const location = useLocation()
    const { data: type } = useQuery('accountType', getAccountType, {
        refetchOnWindowFocus: false,
    })

    const isAdmin = type === 'admin'

    return (
        <div className="main-content">
            <section className="route-section">
                <Routes location={location}>
                    <Route path="/home" element={<Home />} />
                    <Route path="/profile/*" element={<Profile />} />
                    <Route path="/join" element={<Join />} />
                    <Route path="/hire/*" element={<Hire />} />
                    <Route
                        path="/admin"
                        element={
                            <ProtectedRoute isAdmin={isAdmin}>
                                <Admin />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="*"
                        element={<Navigate to="/profile" replace />}
                    />
                </Routes>
            </section>
        </div>
    )
}

export default MainContent
