import React, { memo } from 'react'
import Select from 'react-select'
import { contractTypes } from '@utils'

import { customStylesValidIn, customStylesFacultativeIn } from '@styles/styles'

const ExperienceEngagementComponent = memo(({ experience, onChange }) => {
    let work = ['Remote', 'On-site', 'Hybrid']
    if (experience.ExperienceType === 'Project') {
        work = ['Remote', 'On-site', 'Hybrid', 'Client Site', 'Field Site']
    }
    const workModes = work?.map((mode) => ({
        value: mode,
        label: mode,
    }))

    let eng = ['Permanent', 'Internship', 'Contract']
    if (experience.ExperienceType === 'Association') {
        eng = ['Volunteer', 'Paid', 'Honorarium']
    }
    const engagementTimes = eng?.map((time) => ({ value: time, label: time }))

    const handleContractTypeChange = (option) => {
        onChange('ContractType', option ? option.value : '')
    }

    const handleWorkModeChange = (option) => {
        onChange('WorkMode', option ? option.value : '')
    }

    const handleEngagementTimeChange = (option) => {
        onChange('EngagementTime', option ? option.value : '')
    }

    return (
        <div className="new-info-experience-modal-container">
            <label className="new-info-experience-modal-label" htmlFor="title">
                Engagement
            </label>
            <div className="new-info-experience-modal-engagement">
                {/* Contract Type */}
                <Select
                    value={contractTypes.find(
                        (type) => type.value === experience.ContractType,
                    )}
                    onChange={handleContractTypeChange}
                    options={contractTypes}
                    placeholder="Time"
                    styles={
                        experience.ContractType
                            ? customStylesValidIn
                            : customStylesFacultativeIn
                    }
                />

                {/* Work Mode */}
                <Select
                    value={workModes.find(
                        (mode) => mode.value === experience.WorkMode,
                    )}
                    onChange={handleWorkModeChange}
                    options={workModes}
                    placeholder="Location"
                    styles={
                        experience.WorkMode
                            ? customStylesValidIn
                            : customStylesFacultativeIn
                    }
                />

                {/* Engagement Time */}
                <Select
                    value={engagementTimes.find(
                        (time) => time.value === experience.EngagementTime,
                    )}
                    onChange={handleEngagementTimeChange}
                    options={engagementTimes}
                    placeholder="Type"
                    styles={
                        experience.EngagementTime
                            ? customStylesValidIn
                            : customStylesFacultativeIn
                    }
                />
            </div>
        </div>
    )
})

export default ExperienceEngagementComponent
