import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { languagesOptions } from '@utils'
import { customStylesValidIn, customStylesInvalidIn } from '@styles/styles'
import { HeaderComponent } from './UtilsModal'

function LanguageModal({
    initialValue = {},
    onUpdate,
    onClose,
    onForceClose,
    onAdd,
}) {
    const [language, setLanguage] = useState({ ...initialValue })

    const proficiencyOptions = [
        { value: 'Native', label: 'Native' },
        { value: 'Bilingual', label: 'Bilingual' },
        { value: 'Professional', label: 'Professional' },
        { value: 'Limited', label: 'Limited' },
    ]

    useEffect(() => {
        if (onUpdate) {
            onUpdate(language)
        }
    }, [language, onUpdate])

    const handleChange = (field, value) => {
        setLanguage((prev) => ({ ...prev, [field]: value }))
    }

    return (
        <div className={`new-component-experience`}>
            <div className="new-info-wrapper">
                <HeaderComponent
                    onForceClose={onForceClose}
                    initialValue={initialValue}
                    onClose={onClose}
                    onAdd={onAdd}
                    expType="Languages"
                    typeName="Language"
                />
                <div className="new-info-exprerience-container">
                    <div className="new-info-experience-modal-container">
                        <label
                            className="new-info-experience-modal-label"
                            htmlFor="certificate"
                        >
                            Language
                        </label>
                        <div className="new-info-experience-modal-engagement">
                            <div className="input-container">
                                <Select
                                    value={languagesOptions.find(
                                        (option) =>
                                            option.value === language.Language,
                                    )}
                                    onChange={(option) =>
                                        handleChange('Language', option.value)
                                    }
                                    options={languagesOptions}
                                    placeholder="Language"
                                    styles={
                                        language.Language
                                            ? customStylesValidIn
                                            : customStylesInvalidIn
                                    }
                                />
                            </div>
                            <div className="input-container">
                                <Select
                                    value={proficiencyOptions.find(
                                        (option) =>
                                            option.value ===
                                            language.Proficiency,
                                    )}
                                    onChange={(option) =>
                                        handleChange(
                                            'Proficiency',
                                            option.value,
                                        )
                                    }
                                    options={proficiencyOptions}
                                    placeholder="Proficiency"
                                    styles={
                                        language.Proficiency
                                            ? customStylesValidIn
                                            : customStylesInvalidIn
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="new-info-experience-modal-container">
                        <label
                            className="new-info-experience-modal-label"
                            htmlFor="certificate"
                        >
                            Certificate
                        </label>
                        <div className="new-info-experience-modal">
                            <div className="input-container">
                                <input
                                    id="certificate"
                                    type="text"
                                    value={language.Certificate}
                                    onChange={(e) =>
                                        handleChange(
                                            'Certificate',
                                            e.target.value,
                                        )
                                    }
                                    placeholder="Certificate"
                                    className={
                                        language.Certificate
                                            ? 'valid-border'
                                            : 'facultative-border'
                                    }
                                />
                            </div>
                            <div className="input-container">
                                <input
                                    id="grade"
                                    type="text"
                                    value={language.Grade}
                                    onChange={(e) => {
                                        handleChange('Grade', e.target.value)
                                    }}
                                    placeholder="Grade, format : 2.5 / 5 or 70 / 100"
                                    className={
                                        language.Grade
                                            ? 'valid-border'
                                            : 'facultative-border'
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LanguageModal
