import React, { useState, useEffect } from 'react'
import { Modal } from '@modals'
import ObjectID from 'bson-objectid'
import { useCVUpdate } from '@hooks'
import EducationModal from '@modals/Experiences/EducationModal'

import { Add } from '@assets'

import './AddSections.css'

function AddEducation({ hasParent = false, triggerAddEducation }) {
    const { handleModalCloseGeneric } = useCVUpdate()
    const [isEducationModalOpen, setIsEducationModalOpen] = useState(false)
    const [initialValue, setInitialValue] = useState({})

    const handleModalClose = async (educationChanges) => {
        setIsEducationModalOpen(false)
        if (!educationChanges) return
        handleModalCloseGeneric(
            educationChanges,
            'Education',
            educationChanges.id,
        )
    }

    useEffect(() => {
        if (triggerAddEducation) {
            handleAddEducation()
        }
    }, [triggerAddEducation])

    const handleAddEducation = () => {
        const id = ObjectID().toString()
        setInitialValue({
            id: id,
            _id: id,
        })
        setIsEducationModalOpen(true)
    }

    return (
        <>
            {!hasParent && (
                <div className="add-section">
                    <img
                        src={Add}
                        alt="Add"
                        className="add-section-icon"
                        onClick={handleAddEducation}
                    />
                </div>
            )}
            <Modal
                isOpen={isEducationModalOpen}
                onClose={handleModalClose}
                modal="Education"
                initialValue={initialValue}
                className="modal-content wide"
                Component={EducationModal}
            />
        </>
    )
}

export default AddEducation
