import React from 'react'

const MessageComponent = ({ message, index }) => {
    if (!message || !message.sender || !message.text) return null

    return (
        <div
            key={index}
            className={`message-container ${
                message.sender === 'user' ? 'user' : ''
            }`}
        >
            {/* {message.sender === 'user' && (
            <img
                src={Edit}
                alt="edit"
                className="edit-message-icon"
                onClick={() => handleEditClick(index)}
            />
        )} */}

            <div key={index} className={`message ${message.sender}`}>
                {message.text}
            </div>
        </div>
    )
}

export default MessageComponent
