export const handleInputChange = (setState) => (field) => (event) => {
    const value = event.value || event.target.value
    setState((prevState) => ({
        ...prevState,
        [field]: value,
    }))
}

export const handleMultiInputChange = (setState) => (field) => (option) => {
    const values = option.map((o) => o.value)
    setState((prevState) => ({
        ...prevState,
        [field]: values,
    }))
}
