import { useQuery } from 'react-query'
import { getReleaseNotes } from '@utils'

export const useReleaseNotes = () => {
    const { data } = useQuery('releaseNotes', getReleaseNotes, {
        onError: (error) => {
            if (process.env.NODE_ENV === 'development')
                console.error('Error fetching release notes:', error)
        },
        refetchOnWindowFocus: false,
    })

    let releases = [],
        lastRel = '',
        detailsMessage = ''

    if (data && data.length > 0) {
        releases = data
        const latestRelease = data[0]
        const latestVersionParts = latestRelease.name.match(/(\d+)\.(\d+)\./)

        if (latestVersionParts) {
            const last = latestVersionParts[0].slice(0, -1)
            lastRel = last

            const relevantReleases = data.filter((release) => {
                const versionParts = release.name.match(/(\d+)\.(\d+)\./)
                return (
                    versionParts &&
                    versionParts[1] === latestVersionParts[1] &&
                    versionParts[2] === latestVersionParts[2]
                )
            })
            detailsMessage = relevantReleases
                .map((release) => `• ${release.message}`)
                .join('')
        }
    }

    return { releases, detailsMessage, lastRel }
}

export default useReleaseNotes
