import React from 'react'

const TypingAnimation = () => (
    <div className="typing-animation-container">
        Typing
        <div className="typing-animation">
            <div className="bubble"></div>
            <div className="bubble"></div>
            <div className="bubble"></div>
        </div>
    </div>
)

export default TypingAnimation
