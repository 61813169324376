import React, { useState } from 'react'
import Tooltip from '@mui/material/Tooltip'
import { useNavigate } from 'react-router-dom'
import { useMobileScreen } from '@hooks'

import './OptionItem.css'

function OptionItem({ icon, label, isSelected, path, onClick }) {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false)
    const navigate = useNavigate()
    const isMobile = useMobileScreen()

    const tooltipTitle = path === '/join' ? `Coming soon !` : ''

    const handleTooltipOpen = () => {
        if (isMobile) {
            setIsTooltipOpen(true)
        }
    }

    const handleTooltipClose = () => {
        if (isMobile) {
            setIsTooltipOpen(false)
        }
    }

    const handleClick = () => {
        if (path === '/profile' || path === '/admin' || path === '/hire') {
            navigate(path)
        }
        if (onClick) onClick()
    }

    const tooltipProps = isMobile
        ? {
              open: isTooltipOpen,
              onClose: handleTooltipClose,
              onOpen: handleTooltipOpen,
          }
        : {}

    return (
        <Tooltip
            title={tooltipTitle}
            placement="top"
            {...tooltipProps}
            disableHoverListener={isMobile}
            disableTouchListener={!isMobile}
        >
            <div
                className={`sidebar-option ${isSelected ? 'selected' : ''}`}
                onClick={handleClick}
                onTouchStart={handleTooltipOpen}
                onMouseEnter={!isMobile ? handleTooltipOpen : undefined}
            >
                <img className="option-icon" alt={label} src={icon} />
                <span>{label}</span>
            </div>
        </Tooltip>
    )
}

export default OptionItem
