import React from 'react'
import { useNavigate } from 'react-router-dom'
import { LandingHeader } from '@compUtils'

import '@styles/policies.css'

function Policies() {
    const navigate = useNavigate()

    const navigateToTermsOfUse = () => {
        navigate('/Policies/terms-of-use')
    }

    const navigateToPrivacyPolicy = () => {
        navigate('/Policies/privacy-policy')
    }

    return (
        <>
            <div className="landing policies">
                <LandingHeader />
                <div className="policies-container">
                    <h3 className="policies-title">Terms & policies</h3>
                    <hr />
                    <h3 className="policies-subtitle">Legal</h3>
                    <div className="policies-button-container">
                        <button
                            className="policies-button"
                            onClick={navigateToTermsOfUse}
                        >
                            <h2 className="policies-button-title">
                                Terms of use
                            </h2>
                            <span className="policies-button-text">
                                Terms that govern use of COTALENT for
                                individuals.
                            </span>
                        </button>
                        <button
                            className="policies-button"
                            onClick={navigateToPrivacyPolicy}
                        >
                            <h2 className="policies-button-title">
                                Privacy policy
                            </h2>
                            <span className="policies-button-text">
                                Practices with respect to personal information
                                we collect from or about you.
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Policies
