import React from 'react'
import { useLocation } from 'react-router-dom'
import { useMobileScreen } from '@hooks'

import HeaderLogo from './HeaderLogo'
import HeaderProfile from './HeaderProfile'

import { AdminButtons } from './AdminButtons'
import { ProfileButtons } from './ProfileButtons'
import { JoinButtons } from './JoinButtons'
import { HireButtons } from './HireButtons'

import './Header.css'

function Header() {
    const isMobile = useMobileScreen()
    const { pathname } = useLocation()

    const renderButtonsBasedOnRoute = () => {
        if (/^\/admin/.test(pathname)) {
            return <AdminButtons />
        } else if (/^\/profile/.test(pathname)) {
            return <ProfileButtons />
        } else if (/^\/join/.test(pathname)) {
            return <JoinButtons />
        } else if (/^\/hire*/.test(pathname)) {
            return <HireButtons />
        } else {
            return (
                <div className="header-navigation">
                    <div className="profile-text">
                        <span>Loading...</span>
                    </div>
                </div>
            )
        }
    }

    return (
        <div className="header">
            {isMobile ? (
                <>
                    <div className="up-header-container">
                        <HeaderLogo />
                        <HeaderProfile />
                    </div>
                    {renderButtonsBasedOnRoute()}
                </>
            ) : (
                <>
                    <HeaderLogo />
                    {renderButtonsBasedOnRoute()}
                    <HeaderProfile />
                </>
            )}
        </div>
    )
}

export default Header
