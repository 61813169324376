import { useUserData } from '@context'
import { updateCompany } from '@utils'

const useHire = () => {
    const { userData } = useUserData()

    const getRole = (company) => {
        const employees = company?.Employees
        const emp = employees?.find((e) => e.userId === userData.userId)
        const role = emp?.role

        switch (role) {
            case 'Viewer':
                return 1
            case 'Editor':
                return 2
            case 'Admin':
                return 3
            case 'Owner':
                return 4
            default:
                return 0
        }
    }

    const handleCompanyUpdate = async (changes) => {
        if (!changes) return
        await updateCompany(changes)
    }

    return { getRole, handleCompanyUpdate }
}

export default useHire
