import React, { useState, useEffect } from 'react'
import LocationSearch from '@compUtils/LocationSearch'
import Select from 'react-select'
import { degrees } from '@utils'

import { customStylesValidIn, customStylesFacultativeIn } from '@styles/styles'

import './EducationModal.css'
import { ExperienceTimeComponent, HeaderComponent } from './UtilsModal'

function EducationModal({
    initialValue = {},
    onUpdate,
    onClose,
    onForceClose,
    onAdd,
}) {
    const [education, setEducation] = useState({ ...initialValue })

    useEffect(() => {
        if (onUpdate) {
            onUpdate(education)
        }
    }, [education, onUpdate])

    const handleChange = (field, value) => {
        setEducation((prev) => ({ ...prev, [field]: value }))
    }

    return (
        <div className={`new-component-experience`}>
            <div className="new-info-wrapper">
                <HeaderComponent
                    onForceClose={onForceClose}
                    initialValue={initialValue}
                    onClose={onClose}
                    onAdd={onAdd}
                    expType="Education"
                    typeName="Education"
                />
                <div className="new-info-exprerience-container">
                    <div className="new-info-experience-modal-container">
                        <label
                            className="new-info-experience-modal-label"
                            htmlFor="schoolName"
                        >
                            Institute
                        </label>
                        <div className="new-info-experience-modal">
                            <div className="input-container">
                                <input
                                    id="schoolName"
                                    type="text"
                                    value={education.SchoolName}
                                    onChange={(e) =>
                                        handleChange(
                                            'SchoolName',
                                            e.target.value,
                                        )
                                    }
                                    placeholder="Institute Name"
                                    className={
                                        education.SchoolName
                                            ? 'valid-border'
                                            : 'invalid-border'
                                    }
                                />
                            </div>
                            <div className="input-container">
                                <input
                                    id="website"
                                    type="url"
                                    value={education.SchoolWebSite}
                                    onChange={(e) =>
                                        handleChange(
                                            'SchoolWebSite',
                                            e.target.value,
                                        )
                                    }
                                    placeholder="Website"
                                    className={
                                        education.SchoolWebSite
                                            ? 'valid-border'
                                            : 'facultative-border'
                                    }
                                />
                            </div>
                            <div className="input-container">
                                <LocationSearch
                                    id="location"
                                    value={{
                                        value: education.SchoolLocation,
                                        label: education.SchoolLocation,
                                    }}
                                    onChange={(option) =>
                                        handleChange(
                                            'SchoolLocation',
                                            option.value,
                                        )
                                    }
                                    placeholder="Location"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="new-info-experience-modal-container">
                        <label
                            className="new-info-experience-modal-label"
                            htmlFor="fieldOfStudy"
                        >
                            Degree
                        </label>
                        <div className="new-info-experience-modal-engagement">
                            <div className="input-container">
                                <Select
                                    value={degrees.find(
                                        (degreeOption) =>
                                            degreeOption.value ===
                                            education.Degree,
                                    )}
                                    onChange={(option) => {
                                        handleChange('Degree', option.value)
                                    }}
                                    options={degrees}
                                    placeholder="Degree"
                                    styles={
                                        education.Degree
                                            ? customStylesValidIn
                                            : customStylesFacultativeIn
                                    }
                                />
                            </div>
                            <div className="input-container">
                                <input
                                    id="fieldOfStudy"
                                    type="text"
                                    value={education.Field}
                                    onChange={(e) =>
                                        handleChange('Field', e.target.value)
                                    }
                                    placeholder="Field of study"
                                    className={
                                        education.Field
                                            ? 'valid-border'
                                            : 'invalid-border'
                                    }
                                />
                            </div>
                            <div className="input-container">
                                <input
                                    id="grade"
                                    type="text"
                                    value={education.Grade}
                                    onChange={(e) => {
                                        handleChange('Grade', e.target.value)
                                    }}
                                    placeholder="Grade, format : 2.5 / 5 or 70 / 100"
                                    className={
                                        education.Grade
                                            ? 'valid-border'
                                            : 'facultative-border'
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    <ExperienceTimeComponent
                        experience={education}
                        onChange={handleChange}
                    />
                </div>
            </div>
        </div>
    )
}

export default EducationModal
