import React from 'react'
import Creatable from 'react-select/creatable'
import Select from 'react-select'

import {
    customStylesValidIn,
    customStylesFacultativeIn,
    customStylesInvalidIn,
} from '@styles/styles'

export function InputField({
    id,
    label = null,
    type,
    value,
    onChange,
    placeholder,
    isValid,
}) {
    return (
        <div className="input-container">
            {label && (
                <label htmlFor={id} className="new-info-experience-modal-label">
                    {label}
                </label>
            )}
            <input
                id={id}
                type={type}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                className={isValid ? 'valid-border' : 'invalid-border'}
            />
        </div>
    )
}

export function TextField({
    id,
    label,
    value,
    onChange,
    placeholder,
    isValid,
}) {
    return (
        <div className="input-container">
            {label && (
                <label className="new-info-experience-modal-label" htmlFor={id}>
                    {label}
                </label>
            )}
            <textarea
                id={id}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                className={isValid ? 'valid-border' : 'invalid-border'}
            />
        </div>
    )
}

export function CreatableField({
    id,
    label,
    isMulti = false,
    value,
    onChange,
    options,
    placeholder,
    isValid,
    onInputChange = null,
    invalid = false,
}) {
    const valueProp = isMulti
        ? value.map((val) => ({ value: val, label: val }))
        : value
          ? { value: value, label: value }
          : null

    return (
        <div className="input-container">
            {label && (
                <label className="new-info-experience-modal-label" htmlFor={id}>
                    {label}
                </label>
            )}
            <Creatable
                isMulti={isMulti}
                value={valueProp}
                onChange={onChange}
                onInputChange={onInputChange}
                options={options}
                isClearable
                isSearchable
                placeholder={placeholder}
                styles={{
                    ...(isValid
                        ? customStylesValidIn
                        : invalid
                          ? customStylesInvalidIn
                          : customStylesFacultativeIn),
                }}
                openMenuOnFocus={true}
            />
        </div>
    )
}

export function SelectField({
    id,
    label,
    isMulti = false,
    value,
    onChange,
    options,
    placeholder,
    isValid,
    invalid = false,
}) {
    const valueProp = isMulti
        ? value.map((val) => ({ value: val, label: val }))
        : value
          ? { value: value, label: value }
          : null

    const optionsProp = options.map((option) => ({
        value: option,
        label: option,
    }))

    return (
        <div className="input-container">
            {label && (
                <label className="new-info-experience-modal-label" htmlFor={id}>
                    {label}
                </label>
            )}
            <Select
                isMulti={isMulti}
                value={valueProp}
                onChange={onChange}
                options={optionsProp}
                isClearable
                isSearchable
                placeholder={placeholder}
                styles={{
                    ...(isValid
                        ? customStylesValidIn
                        : invalid
                          ? customStylesInvalidIn
                          : customStylesFacultativeIn),
                }}
                openMenuOnFocus={true}
            />
        </div>
    )
}
