import React, { useState, useRef, useEffect } from 'react'
import { useCV } from '@context'
import { VisibilityButton } from '@compUtils'
import { autoGrowText } from '@utils'

function SummaryModal({ initialValue = {}, onUpdate }) {
    const { cvData } = useCV()
    const [localUserData, setLocalUserData] = useState(cvData)
    const textAreaRef = useRef(null)

    const [summary, setSummary] = useState(
        initialValue?.Summary ? initialValue.Summary : '',
    )

    useEffect(() => {
        if (textAreaRef.current) {
            autoGrowText(textAreaRef.current, 5)
        }
    }, [])

    useEffect(() => {
        const updateSummaryData = (value) => {
            setLocalUserData((prevUserData) => {
                return {
                    ...prevUserData,
                    Summary: value,
                }
            })
        }
        updateSummaryData(summary)
    }, [summary, initialValue])

    useEffect(() => {
        if (onUpdate) {
            onUpdate(localUserData)
        }
    }, [localUserData, onUpdate])

    return (
        <div className={`new-component-experience`}>
            <div className="new-info-wrapper">
                <div className="new-info-header">
                    <span className="title">Summary</span>
                    <VisibilityButton detailKeyOrPath={`Summary`} />
                </div>
                <div className="new-info-exprerience-container">
                    <div className="new-info-experience-modal-container">
                        <textarea
                            ref={textAreaRef}
                            value={summary}
                            onChange={(e) => setSummary(e.target.value)}
                            onInput={(e) => autoGrowText(e.target, 5)}
                            placeholder="Enter your summary..."
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SummaryModal
