import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import { useCV, useTmpData } from '@context'
import LanguageEntry from './LanguageEntry'
import { AddLanguage } from '@compUtils'

import './Language.css'

function Language() {
    const { cvData } = useCV()
    const { isEditing } = useTmpData()

    if (!cvData.Languages && !isEditing) return null

    return (
        <div className="language">
            <div className="language-header">
                <h3 className="title">
                    <Tooltip
                        title={
                            <div style={{ textAlign: 'center' }}>
                                Note your language skills and any official
                                certifications or assessments you've completed.
                            </div>
                        }
                        placement="top"
                    >
                        <span>LANGUAGE</span>
                    </Tooltip>
                </h3>
                {isEditing && <AddLanguage />}
            </div>
            {cvData.Languages?.map((language, index) => (
                <LanguageEntry
                    key={language._id}
                    id={language._id}
                    Language={language.Language}
                    Proficiency={language.Proficiency}
                    Certificate={language.Certificate}
                    Grade={language.Grade}
                    style={index === 0 ? { marginTop: '10px' } : {}}
                />
            ))}
        </div>
    )
}

export default Language
