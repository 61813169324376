import React from 'react'
import { MessageComponent } from './index'
import { TypingAnimation } from '@compUtils'
import { descriptionState } from '@utils'

const ChatComponent = ({
    chatMessages,
    // handleEditClick,
    currentState,
    chatContainerRef,
}) => {
    return (
        <div className="chat-container" ref={chatContainerRef}>
            {chatMessages.map((message, index) => (
                <MessageComponent
                    key={index}
                    message={message}
                    index={index}
                    // handleEditClick={handleEditClick}
                />
            ))}
            {currentState === descriptionState.ASSISTANT_WRITING && (
                <div className="message assistant">
                    <TypingAnimation />
                </div>
            )}
        </div>
    )
}

export default ChatComponent
