import React, {
    createContext,
    useState,
    useEffect,
    useContext,
    useMemo,
    useCallback,
} from 'react'
import { useMutation } from 'react-query'
import { updateUDOnServer, debouncedMutate } from '@utils'

const UserDataContext = createContext()

export const useUserData = () => {
    return useContext(UserDataContext)
}
export const UserDataProvider = ({ children }) => {
    const [userData, setUserData] = useState(null)

    // Update userCV
    const updateUserData = (newUserData) => {
        if (!newUserData) {
            setUserData(null)
            return
        }
        const data = {
            Name: newUserData.Name,
            Surname: newUserData.Surname,
            ProfilePicture: newUserData.ProfilePicture,
            Email: newUserData.Email,
            userId: newUserData.userId,
        }

        setUserData(data)
    }

    const { mutate } = useMutation(updateUDOnServer, {
        onSuccess: (data) => {
            if (process.env.REACT_APP_NODE_ENV === 'development') {
                // console.log('UD updated successfully', data)
            }
        },
        onError: (error) => {
            if (process.env.REACT_APP_NODE_ENV === 'development') {
                // console.error('Error updating UD:', error)
            }
        },
    })

    const updateUD = useCallback(
        (newUserData) => {
            setUserData((prevData) => {
                const updatedData =
                    typeof newUserData === 'function'
                        ? newUserData(prevData)
                        : newUserData
                return updatedData
            })
        },
        [setUserData],
    )

    useEffect(() => {
        if (userData) {
            debouncedMutate(mutate, userData)
        }
    }, [userData, mutate])

    const value = useMemo(() => {
        return {
            userData,
            setUserData,
            updateUserData,
            updateUD,
        }
    }, [userData, updateUD])

    return (
        <UserDataContext.Provider value={value}>
            {children}
        </UserDataContext.Provider>
    )
}
