export const parseDateYearMonth = (dateString) => {
    if (!dateString) return { month: '', year: '' }
    if (dateString === 'present') return { month: 'present', year: '' }

    dateString = dateString.replace('/undefined', '').replace('-undefined', '')

    const splitChar = dateString.includes('/')
        ? '/'
        : dateString.includes('-')
          ? '-'
          : null

    if (!splitChar) return { month: '', year: '' }

    const parts = dateString.split(splitChar)

    if (parts?.length === 2) {
        let [year, month] = parts

        if (year?.length === 4) {
            return {
                month: month.padStart(2, '0'),
                year,
            }
        } else if (month?.length === 4) {
            return {
                month: year.padStart(2, '0'),
                year: month,
            }
        }
    }

    return { month: '', year: '' }
}

export const dateToComparableNumber = (date) => {
    const { year, month } = parseDateYearMonth(date)

    if (month === 'present') {
        const currentYear = new Date().getFullYear().toString()
        const currentMonth = (new Date().getMonth() + 1).toString()
        return parseInt(`${currentYear}${currentMonth.padStart(2, '0')}`, 10)
    }

    if (year && month) {
        return parseInt(`${year}${month.padStart(2, '0')}`, 10)
    }

    return 0
}

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]?.map((month, index) => ({ value: index + 1, label: month }))

const currentYear = new Date().getFullYear()
const years = Array.from({ length: 60 }, (_, i) => currentYear - i)?.map(
    (year) => ({ value: year, label: String(year) }),
)

export const getMonth = (value) => {
    const numValue = parseInt(value, 10)
    const month = months.find((m) => m.value === numValue)
    if (!month) return { value: '', label: '' }
    return month
}

export const getYear = (value) => {
    const numValue = parseInt(value, 10)
    const year = years.find((m) => m.value === numValue)
    if (!year) return { value: '', label: '' }
    return year
}

export const getEndMonthOptions = (startYear, endYear, startMonth) => {
    if (!startYear || !endYear || !startMonth) return months

    if (parseInt(startYear) < parseInt(endYear)) {
        return months
    } else if (startYear.toString() === endYear.toString()) {
        return months.filter((month) => month.value >= startMonth)
    } else {
        return []
    }
}

export const getStartMonthOptions = (startYear, endYear, endMonth) => {
    if (!startYear || !endYear || !endMonth) return months

    if (parseInt(startYear) < parseInt(endYear)) {
        return months
    } else if (startYear.toString() === endYear.toString()) {
        return months.filter((month) => month.value <= endMonth)
    } else {
        return []
    }
}

export const getEndYearOptions = (startYear) => {
    if (!startYear) return years
    return years.filter((year) => year.value >= startYear)
}

export const getStartYearOptions = (endYear) => {
    if (!endYear) return years
    return years.filter((year) => year.value <= endYear)
}
