import { StyleSheet } from '@react-pdf/renderer'

const baseText = {
    fontFamily: 'DMSans',
    fontSize: 10,
    fontWeight: 400,
    color: '#4A4A4A',
    lineHeight: 1.2,
    // marginBottom: 2,
}

export const styles = StyleSheet.create({
    page: {
        fontFamily: 'DMSans',
        color: '#4A4A4A',
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        padding: 50,
    },
    userInfo: {
        textAlign: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 30,
        position: 'relative',
    },
    headerTitle: {
        fontSize: 16,
        fontWeight: 800,
        textAlign: 'center',
        flexGrow: 1,
        marginBottom: 5,
        textTransform: 'uppercase',
    },
    profilePicture: {
        width: 70,
        height: 70,
        borderRadius: 35,
        marginRight: 50,
        position: 'absolute',
        left: 20,
        top: '80%',
        transform: 'translateY(-50%)',
        borderWidth: 1,
        borderColor: '#007AFF',
        objectFit: 'cover',
        objectPosition: 'center center',
        borderStyle: 'solid',
    },
    profileBorder: {
        width: 72,
        height: 72,
        borderRadius: 36,
        position: 'absolute',
        left: 19,
        top: '78.2%',
        transform: 'translateY(-50%)',
        borderTopColor: '#007AFF',
        borderBottomColor: '#30B0C7',
        borderLeftColor: '#007AFF',
        borderRightColor: '#30B0C7',
        borderWidth: 1,
    },
    userDetails: {
        fontSize: 10,
        lineHeight: 1.5,
    },
    section: {
        marginBottom: 15,
    },
    sectionTitle: {
        fontFamily: 'Inter',
        fontSize: 12,
        fontWeight: 800,
        marginBottom: 6,
        textTransform: 'uppercase',
    },
    text: {
        ...baseText,
    },
    lignedText: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    exp: {
        marginBottom: 5,
    },
    footer: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        right: 30,
        textAlign: 'right',
        color: 'black',
    },
    footerText: {
        fontSize: 6,
        color: '#00000099',
    },
    semiBlodText: {
        ...baseText,
        fontWeight: 600,
    },
    link: {
        border: 'none',
        textDecoration: 'none',
    },
    blue: {
        color: '#007AFF',
    },
})
