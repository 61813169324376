import React from 'react'
import Creatable from 'react-select/creatable'
import Select from 'react-select'
import { currencies, degrees } from '@utils'
import { LocationSearch } from '@compUtils'

import { Send } from '@assets'
import { SpinnerCircular } from 'spinners-react'

const InfoCard = ({
    header,
    value,
    onChange,
    onSubmit,
    placeholder,
    inputType = 'input',
    options = [],
    itype = 'text',
}) => {
    const renderInput = () => {
        switch (inputType) {
            case 'select':
                return (
                    <Select value={value} onChange={onChange}>
                        {options.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </Select>
                )
            case 'creatable':
                return (
                    <Creatable
                        value={{ value: value, label: value }}
                        onChange={(e) => onChange(e.value)}
                        options={options}
                        styles={{
                            container: (provided) => ({
                                ...provided,
                                width: '100%',
                            }),
                            control: (provided) => ({
                                ...provided,
                                maxHeight: '38px',
                                overflow: 'auto',
                            }),
                        }}
                        placeholder={placeholder}
                    />
                )
            case 'creatable-multi':
                return (
                    <Creatable
                        value={value.map((v) => ({
                            value: v,
                            label: v,
                        }))}
                        onChange={(e) => onChange(e.map((v) => v.value))}
                        options={options}
                        isMulti
                        styles={{
                            container: (provided) => ({
                                ...provided,
                                width: '100%',
                            }),
                            control: (provided) => ({
                                ...provided,
                                maxHeight: '38px',
                                overflow: 'auto',
                            }),
                        }}
                        placeholder={placeholder}
                    />
                )
            case 'salary':
                return (
                    <>
                        <input
                            type="number"
                            value={value.MinSalary}
                            onChange={(e) => onChange(e, 'MinSalary')}
                            placeholder="Min Salary"
                            style={{ width: '100%' }}
                        />
                        <input
                            type="number"
                            value={value.MaxSalary}
                            onChange={(e) => {
                                onChange(e, 'MaxSalary')
                            }}
                            placeholder="Max Salary"
                            style={{ width: '100%' }}
                            onBlur={(e) => {
                                if (
                                    parseInt(e.target.value) <=
                                    parseInt(value.MinSalary)
                                ) {
                                    onChange(
                                        {
                                            target: {
                                                value: value.MinSalary,
                                            },
                                        },
                                        'MaxSalary',
                                    )
                                }
                            }}
                        />
                        <Select
                            value={{
                                value: value.Currency,
                                label: value.Currency,
                            }}
                            onChange={(e) => onChange(e, 'Currency')}
                            options={Object.keys(currencies)?.map((key) => ({
                                value: key,
                                label: `${key} - ${currencies[key].name}`,
                            }))}
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    minWidth: '150px',
                                }),
                            }}
                            placeholder="Currency"
                        />
                        <Select
                            value={{
                                value: value.PaymentFrequency,
                                label: value.PaymentFrequency,
                            }}
                            onChange={(e) => onChange(e, 'PaymentFrequency')}
                            options={[
                                { value: 'Weekly', label: 'Weekly' },
                                { value: 'Yearly', label: 'Yearly' },
                                { value: 'Monthly', label: 'Monthly' },
                                {
                                    value: 'Project Duration',
                                    label: 'Project Duration',
                                },
                            ]}
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    minWidth: '150px',
                                }),
                            }}
                            placeholder="Frequency"
                        />
                    </>
                )
            case 'location':
                return (
                    <LocationSearch
                        value={{
                            value: value,
                            label: value,
                        }}
                        onChange={onChange}
                        placeholder={placeholder}
                    />
                )
            case 'button':
                return options.map((option) => (
                    <button
                        key={option.value}
                        className={`info-button ${
                            value === option.value ? 'active' : ''
                        }`}
                        onClick={() => onChange(option.value)}
                    >
                        {option.label}
                    </button>
                ))
            case 'nationality':
                return (
                    <Select
                        value={value ? { label: value, value } : null}
                        onChange={onChange}
                        options={options}
                        isSearchable
                        placeholder="Select your nationality"
                        styles={{
                            container: (provided) => ({
                                ...provided,
                                width: '100%',
                            }),
                            menu: (provided) => ({ ...provided, zIndex: 1000 }),
                        }}
                        openMenuOnFocus={true}
                    />
                )
            case 'education':
                return (
                    <>
                        <Select
                            value={{ value: value.Degree, label: value.Degree }}
                            onChange={(e) => onChange(e, 'Degree')}
                            options={degrees}
                            placeholder="Degree"
                            styles={{
                                container: (provided) => ({
                                    ...provided,
                                    width: '100%',
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    zIndex: 1000,
                                }),
                            }}
                            openMenuOnFocus={true}
                        />
                        <input
                            type="text"
                            value={value.Field}
                            onChange={(e) => onChange(e, 'Field')}
                            placeholder="Field of study"
                            style={{ width: '100%' }}
                        />
                    </>
                )
            case 'certification':
                return (
                    <>
                        <input
                            type="text"
                            value={value.Issuer}
                            onChange={(e) => onChange(e, 'Issuer')}
                            placeholder="Issuer..."
                            style={{ width: '100%' }}
                        />
                        <input
                            type="text"
                            value={value.Certificate}
                            onChange={(e) => onChange(e, 'Certificate')}
                            placeholder="Certificate..."
                            style={{ width: '100%' }}
                        />
                    </>
                )
            case 'age':
                return (
                    <>
                        <input
                            type="number"
                            value={value.MinAge}
                            onChange={(e) => onChange(e, 'MinAge')}
                            placeholder="Minimum Age"
                            style={{ width: '100%' }}
                        />
                        <input
                            type="number"
                            value={value.MaxAge}
                            onChange={(e) => onChange(e, 'MaxAge')}
                            placeholder="Maximum Age"
                            style={{ width: '100%' }}
                        />
                    </>
                )
            case 'createJD':
                return (
                    <>
                        <input
                            type="text"
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                            placeholder={placeholder}
                            style={{ width: '100%' }}
                        />
                        <img
                            src={Send}
                            alt="send"
                            className="jd-button-img"
                            onClick={onSubmit}
                        />
                    </>
                )
            case 'loading':
                return (
                    <div className="loading-card">
                        <SpinnerCircular
                            size={30}
                            thickness={100}
                            speed={100}
                            color="#007AFF"
                            secondaryColor="#66afff"
                        />
                        <span>{value}</span>
                    </div>
                )
            default:
                return (
                    <input
                        type={itype}
                        value={value}
                        onChange={onChange}
                        placeholder={placeholder}
                        style={{ width: '100%' }}
                    />
                )
        }
    }

    return (
        <div className="jd-info-card">
            <div className="jd-card-header">{header}</div>
            <div className="jd-card-container">{renderInput()}</div>
        </div>
    )
}

export default InfoCard
