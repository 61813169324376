import React from 'react'

const GenerateComponent = ({ handleYesNoResponse }) => {
    return (
        <div className="generate-description">
            <span>
                You have a few more questions to answer to build a compelling
                work description. Would you like to generate the description
                anyway?
            </span>
            <span>
                <span
                    className="description-yes"
                    onClick={() => handleYesNoResponse('Yes')}
                >
                    Yes
                </span>
                {' or '}
                <span
                    className="description-no"
                    onClick={() => handleYesNoResponse('No')}
                >
                    No
                </span>
            </span>
        </div>
    )
}

export default GenerateComponent
