import React from 'react'
import { Modal } from '@modals'
import { useTmpData } from '@context/TmpDataContext'
import FeedbackModal from '@modals/FeedbackModal'

const Feedback = () => {
    const {
        isFeedbackModalOpen,
        handleOpenFeedbackModal,
        handleCloseFeedbackModal,
    } = useTmpData()

    return (
        <>
            <div className="hn-buttons-container">
                <button
                    className="hn-button-white"
                    onClick={handleOpenFeedbackModal}
                >
                    <span>Feedback</span>
                </button>
            </div>
            <Modal
                isOpen={isFeedbackModalOpen}
                onClose={handleCloseFeedbackModal}
                modal="Feedback"
                className="modal-content wide-feedback"
                Component={FeedbackModal}
            />
        </>
    )
}

export default Feedback
