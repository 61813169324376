import React, { useState } from 'react'
import { useCV } from '@context/CVContext'
import Select from 'react-select'
import { VisibilityButton } from '@compUtils'

import { customStylesValidIn, customStylesInvalidIn } from '@styles/styles'

function Availability({ isModal = false }) {
    const { cvData, updateCV } = useCV()
    const [immediate, setImmediate] = useState(
        cvData.Availability?.Immediate || false,
    )
    const [number, setNumber] = useState(cvData.Availability?.Number || '')
    const [period, setPeriod] = useState(
        cvData.Availability?.Period
            ? {
                  value: cvData.Availability.Period,
                  label: cvData.Availability.Period,
              }
            : '',
    )

    const periodOptions = [
        { value: 'Day(s)', label: 'Day(s)' },
        { value: 'Week(s)', label: 'Week(s)' },
        { value: 'Month(s)', label: 'Month(s)' },
        { value: 'Year(s)', label: 'Year(s)' },
    ]

    const handleImmediateClick = () => {
        setImmediate(true)
        setNumber('')
        setPeriod('')
        updateCV((prev) => ({
            ...prev,
            Availability: {
                ...(prev.Availability || {}),
                Immediate: true,
                Number: '',
                Period: '',
            },
        }))
    }

    const handleNumberBlur = () => {
        if (!number) return
        updateCV((prev) => ({
            ...prev,
            Availability: {
                ...(prev.Availability || {}),
                Number: number,
                Immediate: false,
            },
        }))
    }

    const handlePeriodChange = (option) => {
        setPeriod(option)
        updateCV((prev) => ({
            ...prev,
            Availability: {
                ...(prev.Availability || {}),
                Period: option.value,
                Immediate: false,
            },
        }))
    }

    return (
        <div
            className={`new-component ${
                (number && period) || immediate
                    ? 'valid-border'
                    : 'invalid-border'
            }`}
            style={isModal ? { width: 'auto' } : {}}
        >
            <div className="new-info-wrapper">
                <div className="new-info-header">
                    Notice Time
                    <VisibilityButton detailKeyOrPath={'Availability'} />
                </div>
                <div className="new-info-container">
                    <button
                        className={`gender-button ${immediate ? 'active' : ''}`}
                        onClick={handleImmediateClick}
                    >
                        Immediate
                    </button>
                    OR
                    <input
                        type="text"
                        placeholder="Number"
                        value={number}
                        onChange={(e) => {
                            if (/^\d{0,2}$/.test(e.target.value)) {
                                setImmediate(false)
                                setNumber(e.target.value)
                            }
                        }}
                        onBlur={() => handleNumberBlur()}
                        className={`avaibility-input ${
                            number || immediate
                                ? 'valid-border'
                                : 'invalid-border'
                        }`}
                    />
                    <Select
                        value={period}
                        onChange={handlePeriodChange}
                        options={periodOptions}
                        isSearchable
                        placeholder="Period"
                        openMenuOnFocus={true}
                        styles={
                            period || immediate
                                ? customStylesValidIn
                                : customStylesInvalidIn
                        }
                    />
                </div>
            </div>
        </div>
    )
}

export default Availability
