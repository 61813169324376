import { toast } from 'react-toastify'
import { useUserData } from '@context'
import { createJD } from '@utils'

function AdminButtons() {
    const { userData } = useUserData()

    const handleClick = async () => {
        const data = {
            Title: 'Youhou',
        }

        try {
            await createJD(data, '6612c9133f128d276bada188')
            toast.success('Job created successfully')
        } catch (error) {
            console.error('Error creating job:', error)
            toast.error(error.response.data.message)
        }
    }

    return (
        <div className="header-navigation">
            <div className="profile-text">
                <span>Admin</span>
            </div>

            <div className="hn-buttons-container">
                <button
                    className="hn-button-white"
                    onClick={() => {
                        handleClick()
                        toast.success(`Hello ${userData.Name} !!!`)
                    }}
                >
                    Test
                </button>
            </div>
        </div>
    )
}

export default AdminButtons
