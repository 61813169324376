import React, { memo } from 'react'
import Select from 'react-select'
import { useDateHandler } from '@hooks'
import {
    getEndMonthOptions,
    getStartMonthOptions,
    getEndYearOptions,
    getStartYearOptions,
    getMonth,
    getYear,
} from '@utils'

import {
    customStylesValidInMonth,
    customStylesInvalidInMonth,
    customStylesValidInYear,
    customStylesInvalidInYear,
} from '@styles/styles'

const ExperienceTimeComponent = memo(({ experience, onChange }) => {
    const { startDate, endDate, updateDate, setPresent } = useDateHandler(
        experience.StartDate,
        experience.EndDate,
        onChange,
    )

    return (
        <div className="new-info-experience-modal-container">
            <label className="new-info-experience-modal-label" htmlFor="title">
                Time
            </label>
            <div className="new-info-experience-modal-time">
                From
                <Select
                    value={getMonth(startDate.month)}
                    onChange={(option) =>
                        updateDate('start', 'month', option.value)
                    }
                    options={getStartMonthOptions(
                        startDate.year,
                        endDate.year,
                        endDate.month,
                    )}
                    placeholder="Month"
                    styles={
                        startDate.month
                            ? customStylesValidInMonth
                            : customStylesInvalidInMonth
                    }
                />
                <Select
                    value={getYear(startDate.year)}
                    onChange={(option) =>
                        updateDate('start', 'year', option.value)
                    }
                    options={getStartYearOptions(endDate.year)}
                    placeholder="Year"
                    styles={
                        startDate.year
                            ? customStylesValidInYear
                            : customStylesInvalidInYear
                    }
                />
                To
                <Select
                    value={getMonth(endDate.month)}
                    onChange={(option) =>
                        updateDate('end', 'month', option.value)
                    }
                    options={getEndMonthOptions(
                        startDate.year,
                        endDate.year,
                        startDate.month,
                    )}
                    placeholder="Month"
                    styles={
                        endDate.month
                            ? customStylesValidInMonth
                            : customStylesInvalidInMonth
                    }
                />
                <Select
                    value={getYear(endDate.year)}
                    onChange={(option) =>
                        updateDate('end', 'year', option.value)
                    }
                    options={getEndYearOptions(startDate.year)}
                    placeholder="Year"
                    styles={
                        endDate.year || endDate.month === 'present'
                            ? customStylesValidInYear
                            : customStylesInvalidInYear
                    }
                />
                OR
                <button
                    onClick={setPresent}
                    className={`gender-button ${
                        endDate.month === 'present' ? 'active' : ''
                    }`}
                >
                    Present
                </button>
            </div>
        </div>
    )
})

export default ExperienceTimeComponent
