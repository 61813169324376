import React from 'react'
import { useQuery } from 'react-query'
import { LandingHeader } from '@compUtils'
import { getPrivacy } from '@utils'
import { SpinnerCircular } from 'spinners-react'

import '@styles/policies.css'

function Privacy() {
    const {
        data: privacyUrl,
        isLoading,
        // isError,
        // error,
    } = useQuery('privacyPolicy', getPrivacy)

    return (
        <>
            <div className="landing policies">
                <LandingHeader />
                <div className="policies-container">
                    <h3 className="policies-title">Terms & policies</h3>
                    <hr />
                    <div className="policies-subtitle-container">
                        <h3 className="policies-subtitle">Legal</h3>
                        {isLoading && (
                            <div className="policies-spinner">
                                <SpinnerCircular size={35} color="#000000" />
                            </div>
                        )}
                    </div>
                    {privacyUrl && (
                        <iframe
                            src={privacyUrl}
                            style={{
                                width: '100%',
                                height: '90vh',
                                marginBottom: '50px',
                            }}
                            title="Privacy Policy"
                        ></iframe>
                    )}
                </div>
            </div>
        </>
    )
}

export default Privacy
