import React, { useState, useEffect, useRef } from 'react'
import DescriptionField from './Description/DescriptionField'
import { useCV } from '@context'
import { createNewThread, autoGrowText } from '@utils'
import {
    HeaderComponent,
    OrganizationInfoComponent,
    CompanyDescriptionComponent,
    ExpericeTitleLocationComponent,
    ExperienceTimeComponent,
    ExperienceEngagementComponent,
} from './UtilsModal'

import './ExperiencesModal.css'

function ExperienceModal({
    isModal = false,
    initialValue = {},
    onUpdate,
    onClose,
    onForceClose,
    onAdd,
    onSave,
    modalRef,
}) {
    const { cvData } = useCV()
    const [experience, setExperience] = useState({ ...initialValue })

    const textAreaRef = useRef(null)

    useEffect(() => {
        if (!experience.Thread) {
            createNewThread().then((newThread) => {
                setExperience((exp) => ({ ...exp, Thread: newThread.thread }))
            })
        }
    }, [experience.Thread])

    useEffect(() => {
        if (onUpdate) {
            onUpdate(experience)
        }
    }, [experience, onUpdate])

    useEffect(() => {
        if (textAreaRef.current) {
            autoGrowText(textAreaRef.current, 5)
        }
    }, [])

    const handleChange = (field, value) => {
        setExperience((prev) => ({ ...prev, [field]: value }))
    }

    const companyOptions = cvData?.Experiences?.map((exp) => ({
        value: exp._id,
        label: exp.CompanyName,
        website: exp.CompanyWebSite,
        description: exp.CompanyDescription,
    }))

    const getTypeName = () => {
        if (initialValue.ExperienceType === 'Work') return 'Work Experience'
        else if (initialValue.ExperienceType === 'Volunteer')
            return 'Volunteer Experience'
        else if (initialValue.ExperienceType === 'Project') return 'Project'
        else if (initialValue.ExperienceType === 'Association')
            return 'Association Experience'
        return 'Experience'
    }

    return (
        <div className={`new-component-experience`}>
            <div className="new-info-wrapper">
                <HeaderComponent
                    onForceClose={onForceClose}
                    initialValue={initialValue}
                    onClose={onClose}
                    onAdd={onAdd}
                    expType={'Experiences'}
                    typeName={getTypeName()}
                />
                <div className="new-info-exprerience-container">
                    <OrganizationInfoComponent
                        experience={experience}
                        onChange={handleChange}
                        companyOptions={companyOptions}
                    />

                    <CompanyDescriptionComponent
                        textAreaRef={textAreaRef}
                        experience={experience}
                        onChange={handleChange}
                    />

                    <ExpericeTitleLocationComponent
                        experience={experience}
                        onChange={handleChange}
                    />

                    <ExperienceTimeComponent
                        experience={experience}
                        onChange={handleChange}
                    />

                    <ExperienceEngagementComponent
                        experience={experience}
                        onChange={handleChange}
                    />

                    <DescriptionField
                        experience={experience}
                        onChange={handleChange}
                        modalRef={modalRef}
                        onSave={onSave}
                    />
                </div>
            </div>
        </div>
    )
}

export default ExperienceModal
