import React, { useState, useRef } from 'react'
import { useMutation } from 'react-query'
import { TailSpin } from 'react-loader-spinner'
import { useUserData } from '@context/UserDataContext'
import { uploadProfilePicture } from '@utils'

import { Profile } from '@assets'

import './ProfilePicture.css'

function ProfilePicture() {
    const { userData, setUserData } = useUserData()
    const [profilePic, setProfilePic] = useState(
        userData.ProfilePicture || Profile,
    )
    const inputFileRef = useRef(null)

    const { mutate: handleUpload, isLoading } = useMutation(
        uploadProfilePicture,
        {
            onSuccess: (data) => {
                const imageUrl = data.imageUrl
                setUserData((prevUserData) => ({
                    ...prevUserData,
                    ProfilePicture: imageUrl,
                }))
                setProfilePic(imageUrl)
            },
            onError: (error) => {
                console.error('There was an error uploading the file:', error)
            },
        },
    )

    const handleProfilePictureChange = (event) => {
        const file = event.target.files[0]
        if (file) {
            const reader = new FileReader()
            reader.onloadend = () => {
                setProfilePic(reader.result)
            }
            reader.readAsDataURL(file)

            handleUpload(file)
        }
    }

    return (
        <div className="profile-container">
            <div
                className="profile-picture"
                onClick={() => inputFileRef.current.click()}
            >
                <input
                    type="file"
                    ref={inputFileRef}
                    style={{ display: 'none' }}
                    onChange={handleProfilePictureChange}
                    accept="image/*"
                />
                {isLoading ? (
                    <TailSpin color="#A0A0A0" height={40} width={40} />
                ) : (
                    <img
                        src={profilePic}
                        alt="Profile"
                        className="profile-picture-img"
                    />
                )}
            </div>
        </div>
    )
}

export default ProfilePicture
