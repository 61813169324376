export function autoGrowText(element, px = 21) {
    element.style.height = '10px'
    element.style.height = element.scrollHeight + px + 'px'
}

export function autoGrowTextWithMin(element, px = 21, min = 50) {
    element.style.height = '10px'
    if (element.scrollHeight + px < min) {
        element.style.height = min + 'px'
        return
    }
    element.style.height = element.scrollHeight + px + 'px'
}

export function autoGrowTextWithMinMax(element, px = 21, min = 50, max = 300) {
    element.style.height = '10px'
    if (element.scrollHeight + px < min) {
        element.style.height = min + 'px'
        return
    } else if (element.scrollHeight + px > max) {
        element.style.height = max + 'px'
        return
    }
    element.style.height = element.scrollHeight + px + 'px'
}

export const formatLocation = (location) => {
    if (!location) return ''
    if (location === 'International') return location
    const splitLocation = location.split(',')
    return splitLocation[0] + ', ' + splitLocation[splitLocation?.length - 1]
}

export const formatDescription = (description) => {
    if (!description) return ''
    const lines = description.trim().split('\n')
    return lines?.map((line) => `• ${line.replace(/- /g, '')}`).join('\n')
}

function formatSalaryValue(value) {
    if (!value) return ''

    const numericValue = parseInt(value.replace(/,/g, ''))
    if (numericValue >= 1000000) {
        return (numericValue / 1000000).toFixed(1) + 'M'
    } else if (numericValue >= 1000) {
        return (numericValue / 1000).toFixed(1) + 'k'
    }
    return value
}

export function formatSalary(salary) {
    if (!salary) {
        return ''
    }

    const currency = salary.Currency
    const paymentFrequency = salary.PaymentFrequency
    if (salary.SalaryValue) {
        return `${formatSalaryValue(
            salary.SalaryValue,
        )} ${currency}, ${paymentFrequency}`
    }
    const minSalaryValue = formatSalaryValue(salary.MinSalary)
    const maxSalaryValue = formatSalaryValue(salary.MaxSalary)

    if (maxSalaryValue) {
        return `${minSalaryValue} - ${maxSalaryValue} ${currency}, ${paymentFrequency}`
    } else if (minSalaryValue) {
        return `Minimum ${minSalaryValue} ${currency}, ${paymentFrequency}`
    }

    return 'Not Set'
}

export function formatNoticeTime(noticeTime) {
    if (!noticeTime) {
        return ''
    }

    const immediate = noticeTime.Immediate
    const number = noticeTime.Number
    const period = noticeTime.Period

    if (immediate) {
        return 'Immediate'
    }

    return `${number} ${period}`
}

export function formatList(list) {
    if (!list) {
        return ''
    }

    return list.join(' · ')
}

export const formatWebsiteUrl = (url) => {
    try {
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
            return 'https://' + url
        }
        return url
    } catch (e) {
        console.error('Invalid URL provided:', url)
        return url
    }
}

export const formatTime = (start, end) => {
    const isValidDate = (date) => /^(\d{1,2})\/(\d{4})$/.test(date)

    if (!isValidDate(start)) return ''

    if (end !== 'present' && !isValidDate(end)) return ''

    const parseDate = (str) => {
        const [month, year] = str.split('/')?.map(Number)
        return new Date(year, month - 1)
    }

    const startDt = parseDate(start)
    let endDt = end === 'present' ? new Date() : parseDate(end)

    if (
        startDt.toString() === 'Invalid Date' ||
        endDt.toString() === 'Invalid Date'
    )
        return ''

    let diffYear = endDt.getFullYear() - startDt.getFullYear()
    let diffMonth = endDt.getMonth() - startDt.getMonth()

    if (diffMonth < 0) {
        diffYear -= 1
        diffMonth += 12
    }

    const monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ]

    const formattedStart = `${
        monthNames[startDt.getMonth()]
    } ${startDt.getFullYear()}`
    const formattedEnd =
        end === 'present'
            ? 'now'
            : `${monthNames[endDt.getMonth()]} ${endDt.getFullYear()}`
    const formattedDuration = `${diffYear ? `${diffYear} yrs` : ''} ${
        diffMonth ? `${diffMonth} mos` : ''
    }`.trim()

    return `${formattedStart} to ${formattedEnd}, ${formattedDuration}`
}

export function formatDate(dateString) {
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ]

    const birthDate = new Date(dateString)
    if (birthDate.toString() === 'Invalid Date') {
        return ''
    }

    const formattedDate = `${birthDate.getDate()} ${
        months[birthDate.getMonth()]
    } ${birthDate.getFullYear()}`
    return formattedDate
}

export function formatDateAndCalculateAge(dateString) {
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ]

    const birthDate = new Date(dateString)
    if (birthDate.toString() === 'Invalid Date') {
        return ''
    }
    // const today = new Date()

    // let age = today.getFullYear() - birthDate.getFullYear()
    // const m = today.getMonth() - birthDate.getMonth()

    // if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    //     age--
    // }

    const formattedDate = `${birthDate.getDate()} ${
        months[birthDate.getMonth()]
    } ${birthDate.getFullYear()}`
    return formattedDate
}

export function drawLines(accessibleSteps) {
    const oldLines = document.querySelectorAll(
        '.progress-bar-wrapper .step div',
    )
    oldLines.forEach((line) => line.remove())

    const steps = document.querySelectorAll('.progress-bar-wrapper .step')

    steps.forEach((step, index) => {
        if (index === steps.length - 1) return // Ignore the last step
        const span = step.querySelector('span')
        const nextStep = steps[index + 1]

        const spanRect = span?.getBoundingClientRect()
        const stepRect = step?.getBoundingClientRect()
        const nextStepRect = nextStep?.getBoundingClientRect()

        const width = nextStepRect?.left - spanRect?.right

        // Create the line (tiret)
        const line = document.createElement('div')
        line.style.position = 'absolute'
        line.style.height = '1px'
        line.style.backgroundColor = '#9C9C9C'
        line.style.cursor = 'default'
        line.style.top = `${
            spanRect.top + spanRect.height / 2 - stepRect.top
        }px` // Adjusted for relative positioning
        line.style.left = `${spanRect.right - stepRect.left + width * 0.1}px` // Adjusted for relative positioning
        line.style.width = `${width * 0.8}px`

        step.appendChild(line)
        if (
            index < steps.length - 1 &&
            accessibleSteps[steps[index + 1].dataset.step]
        ) {
            line.style.backgroundColor = '#00A3FF'
        } else {
            line.style.backgroundColor = '#9C9C9C'
        }
    })
}
