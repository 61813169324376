import React, { useState, useEffect } from 'react'
import { Modal } from '@modals'
import { useCV, useTmpData, useContentOpen } from '@context'
import { VisibilityButton } from '@compUtils'

import {
    Avaibility,
    Function,
    Industry,
    PreferedLocation,
    PreferedBaseSalary,
    PreferedBonus,
    PreferedEngagement,
} from '@modals/Preference'

const PreferedEntry = ({ title, detailKey, formatter }) => {
    const { isEditing } = useTmpData()
    const { cvData } = useCV()
    const { isContentOpen } = useContentOpen()
    const [isModelOpen, setIsModelOpen] = useState(false)
    const [hasModalClosed, setHasModalClosed] = useState(false)

    const getModal = (modal) => {
        switch (modal) {
            case 'Availability':
                return Avaibility
            case 'PreferedEngagement':
                return PreferedEngagement
            case 'PreferedBonus':
                return PreferedBonus
            case 'PreferedIndustry':
                return Industry
            case 'PreferedFunction':
                return Function
            case 'PreferedLocation':
                return PreferedLocation
            case 'PreferedBaseSalary':
                return PreferedBaseSalary
            default:
                return null
        }
    }

    const detailValue =
        !!cvData[detailKey] && cvData[detailKey]?.length !== 0
            ? formatter
                ? formatter(cvData[detailKey])
                : cvData[detailKey]
            : 'Not Set'
    const visibilityValue = cvData.Visibility[detailKey]
    const isEditable = isEditing || detailValue === 'Not Set'

    useEffect(() => {
        if (hasModalClosed) {
            setHasModalClosed(false)
        }
    }, [cvData, hasModalClosed])

    return (
        <div className="prefered-detail">
            <span className="prefered-title">{title}</span>
            <div className={`prefered-data-wrapper`}>
                <span
                    className={`prefered-data ${
                        (isEditing ||
                            (isEditing && detailValue === 'Not Set')) &&
                        isEditable
                            ? 'editing'
                            : ''
                    }`}
                    onClick={() => {
                        if (isEditable && !isContentOpen) setIsModelOpen(true)
                    }}
                >
                    {detailValue}
                </span>
                <Modal
                    isOpen={isModelOpen}
                    onClose={() => setIsModelOpen(false)}
                    initialValue={cvData[detailKey]}
                    className="modal-content"
                    Component={getModal(detailKey)}
                />
                <VisibilityButton
                    visibilityValue={visibilityValue}
                    detailKeyOrPath={detailKey}
                />
            </div>
        </div>
    )
}

export default PreferedEntry
