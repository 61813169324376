import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { useJD } from '@context'
import {
    getJDById,
    fetchNationalities,
    techSkills,
    languagesOptions,
} from '@utils'
import InfoCard from './InfoCard'

function JDRequirements() {
    const { jobId } = useParams()
    const navigate = useNavigate()
    const { jd, setInitialJDData, updateJD } = useJD()

    const { isLoading, isError, error } = useQuery(
        `jobDescription-${jobId}`,
        () => getJDById(jobId),
        {
            enabled: !jd,
            onSuccess: (data) => {
                setInitialJDData(data)
            },
        },
    )

    const { data: nationalities } = useQuery(
        'nationalities',
        fetchNationalities,
        { staleTime: Infinity },
    )

    if (isLoading) {
        return <h1>Loading...</h1>
    }

    if (isError) {
        toast.error(error.response.data.message)
        navigate('/hire')
        return null
    }

    return (
        <div className="jd-creation">
            <div className="jd-multiplpe-card">
                <InfoCard
                    header="Age"
                    inputType="age"
                    value={jd.Age}
                    onChange={(e, field) =>
                        updateJD({
                            ...jd,
                            Age: {
                                ...jd.Age,
                                [field]: e.target.value,
                            },
                        })
                    }
                    placeholder={'Age...'}
                />
                <InfoCard
                    header="Gender"
                    value={jd.Gender}
                    onChange={(e) =>
                        updateJD({
                            ...jd,
                            Gender: e,
                        })
                    }
                    options={[
                        { value: 'Male', label: 'Male' },
                        { value: 'Female', label: 'Female' },
                        { value: 'Any', label: 'Any' },
                    ]}
                    inputType="button"
                />
            </div>
            <div className="jd-multiplpe-card">
                <InfoCard
                    header="Nationality"
                    inputType="nationality"
                    value={jd.Nationality}
                    onChange={(e) =>
                        updateJD({
                            ...jd,
                            Nationality: e.value,
                        })
                    }
                    options={nationalities}
                />
                <InfoCard
                    header="Prefered Candidate Location"
                    inputType="location"
                    value={jd.CandidateLocation}
                    onChange={(e) =>
                        updateJD({
                            ...jd,
                            CandidateLocation: e.value,
                        })
                    }
                    placeholder={'Location...'}
                />
            </div>
            <div className="jd-multiplpe-card">
                <InfoCard
                    header="Education"
                    inputType="education"
                    value={jd.Education}
                    onChange={(e, field) =>
                        updateJD({
                            ...jd,
                            Education: {
                                ...jd.Education,
                                [field]: e.target?.value || e.value,
                            },
                        })
                    }
                />
                <InfoCard
                    header="Certification"
                    inputType="certification"
                    value={jd.Certification}
                    onChange={(e, field) =>
                        updateJD({
                            ...jd,
                            Certification: {
                                ...jd.Certification,
                                [field]: e.target?.value || e.value,
                            },
                        })
                    }
                />
            </div>
            <div className="jd-multiplpe-card">
                <InfoCard
                    header="Technical Skills"
                    inputType="creatable-multi"
                    value={jd.TechnicalSkills}
                    onChange={(e) =>
                        updateJD({
                            ...jd,
                            TechnicalSkills: e,
                        })
                    }
                    options={techSkills.options}
                />
                <InfoCard
                    header="Languages"
                    inputType="creatable-multi"
                    value={jd.Languages}
                    onChange={(e) =>
                        updateJD({
                            ...jd,
                            Languages: e,
                        })
                    }
                    options={languagesOptions}
                />
            </div>
        </div>
    )
}

export default JDRequirements
