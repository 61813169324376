import { useEffect } from 'react'

const useKeyboardShortcut = () => {
    function useGeneralShortcut(onShortcut) {
        useEffect(() => {
            const handleKeyPress = (event) => {
                onShortcut(event)
            }

            document.addEventListener('keydown', handleKeyPress)

            return () => {
                document.removeEventListener('keydown', handleKeyPress)
            }
        }, [onShortcut])
    }

    function useBackNext(onBack, onNext) {
        useEffect(() => {
            const handleKeyPress = (event) => {
                const activeElement = document.activeElement.tagName

                if (activeElement === 'INPUT' || activeElement === 'TEXTAREA') {
                    return
                }

                if (event.key === 'Backspace' || event.key === 'b') {
                    onBack()
                }

                if (event.key === 'n') {
                    onNext()
                }
            }

            document.addEventListener('keydown', handleKeyPress)

            return () => {
                document.removeEventListener('keydown', handleKeyPress)
            }
        }, [onBack, onNext])
    }

    return { useBackNext, useGeneralShortcut }
}

export default useKeyboardShortcut
