import React, { useState } from 'react'
import { VisibilityButton } from '@compUtils'
import { Modal } from '@modals'
import { useUserData, useTmpData, useCV, useContentOpen } from '@context'

import {
    BirthDate,
    CurrentBaseSalary,
    CurrentBonus,
    Email,
    Gender,
    Location,
    Nationality,
    Phone,
} from '@modals/Information/index'

const InformationEntry = ({ title, detailKey, formatter, useData }) => {
    const { userData } = useUserData()
    const { isEditing } = useTmpData()
    const { cvData } = useCV()
    const { isContentOpen } = useContentOpen()
    const [isModelOpen, setIsModelOpen] = useState(false)

    const getModal = (modal) => {
        switch (modal) {
            case 'BirthDate':
                return BirthDate
            case 'CurrentBonus':
                return CurrentBonus
            case 'CurrentCity':
                return Location
            case 'CurrentBaseSalary':
                return CurrentBaseSalary
            case 'Email':
                return Email
            case 'Gender':
                return Gender
            case 'Nationality':
                return Nationality
            case 'PhoneNumber':
                return Phone
            default:
                return null
        }
    }

    const data = useData ? userData : cvData

    const detailValue =
        !!data[detailKey] && data[detailKey]?.length !== 0
            ? formatter
                ? formatter(data[detailKey])
                : data[detailKey]
            : 'Not Set'
    const visibilityValue = data.Visibility
        ? data.Visibility[detailKey]
        : 'Public'
    const isEditable = (!useData && isEditing) || detailValue === 'Not Set'

    return (
        <div className="information-detail">
            <span className="information-title">{title}</span>
            <div className={`information-data-wrapper`}>
                <span
                    className={`information-data ${
                        (isEditing ||
                            (isEditing && detailValue === 'Not Set')) &&
                        isEditable
                            ? 'editing'
                            : ''
                    }`}
                    onClick={() => {
                        if (isEditable && !isContentOpen) setIsModelOpen(true)
                    }}
                >
                    {detailValue}
                </span>
                <Modal
                    isOpen={isModelOpen}
                    onClose={() => setIsModelOpen(false)}
                    modal={detailKey}
                    initialValue={data[detailKey]}
                    className="modal-content"
                    Component={getModal(detailKey)}
                />
                <VisibilityButton
                    visibilityValue={visibilityValue}
                    detailKeyOrPath={detailKey}
                />
            </div>
        </div>
    )
}

export default InformationEntry
