import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import { useCV, useTmpData } from '@context'
import EducationEntry from './EducationEntry'
import { AddEducation } from '@compUtils'

import './Education.css'

function Education() {
    const { cvData } = useCV()
    const { isEditing } = useTmpData()

    if (!cvData.Education) return null

    return (
        <div className="education">
            <div className="education-header">
                <h3 className="title">
                    <Tooltip
                        title={
                            <div style={{ textAlign: 'center' }}>
                                List your degrees, schools attended, and major
                                fields of study, focusing on formal education
                                and training.
                            </div>
                        }
                        placement="top"
                    >
                        <span>EDUCATION</span>
                    </Tooltip>
                </h3>
                {isEditing && <AddEducation />}
            </div>
            {cvData.Education?.map((education, index) => (
                <EducationEntry
                    key={education._id}
                    id={education._id}
                    SchoolName={education.SchoolName}
                    SchoolWebSite={education.SchoolWebSite}
                    Field={education.Field}
                    StartDate={education.StartDate}
                    EndDate={education.EndDate}
                    SchoolLocation={education.SchoolLocation}
                    Grade={education.Grade}
                    Degree={education.Degree}
                    style={index === 0 ? { marginTop: '10px' } : {}}
                />
            ))}
        </div>
    )
}

export default Education
