import React from 'react'
import Select from 'react-select'
import { SpinnerCircular } from 'spinners-react'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { removeEmployee, updateEmployeeRole } from '@utils'

import { Delete } from '@assets'

function EmployeeCard({ employee, setEmployee, index, company }) {
    const queryClient = useQueryClient()

    const modifyRoleMutation = useMutation(
        (newRole) => updateEmployeeRole(company._id, newRole),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(`employees-${company._id}`)
                toast.success('Role updated successfully')
            },
            onError: (error) => {
                toast.error(error.response.data.message)
            },
        },
    )

    const removeEmployeeMutation = useMutation(
        () => removeEmployee(company._id, employee.userId),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(`companies`)
                toast.success('Employee removed successfully')
            },
            onError: (error) => {
                toast.error(error.response.data.message)
            },
        },
    )

    const options = {
        role: [
            { value: 'Viewer', label: 'Viewer' },
            { value: 'Editor', label: 'Editor' },
            { value: 'Admin', label: 'Admin' },
        ],
    }

    return (
        <div key={index} className="employee-card">
            <img src={employee.ProfilePicture} alt="profile" />
            <div className="employee-info">
                <p>{`${employee.Name} ${employee.Surname}`}</p>
                <p>{employee.Email}</p>
            </div>
            <div className="select-container">
                {modifyRoleMutation.isLoading && <SpinnerCircular size={25} />}
                <Select
                    isDisabled={company?.role < 3}
                    options={options.role}
                    defaultValue={{
                        value: employee.role,
                        label: employee.role,
                    }}
                    onChange={(selected) => {
                        if (!company) {
                            setEmployee((prev) => ({
                                ...prev,
                                role: selected.value,
                            }))
                        } else {
                            modifyRoleMutation.mutate({
                                userId: employee.userId,
                                role: selected.value,
                            })
                        }
                    }}
                />
                {company?.role >= 3 &&
                    (removeEmployeeMutation.isLoading ? (
                        <SpinnerCircular size={22} />
                    ) : (
                        <img
                            src={Delete}
                            alt="delete"
                            onClick={() =>
                                removeEmployeeMutation.mutate(employee.userId)
                            }
                            style={{ cursor: 'pointer' }}
                        />
                    ))}
            </div>
        </div>
    )
}

export default EmployeeCard
