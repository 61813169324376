import { useState, useEffect } from 'react'

function useMobileScreen() {
    const checkIfMobile = () =>
        window.innerWidth < 600 || window.innerHeight < 600
    const [isMobile, setIsMobile] = useState(checkIfMobile)

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(checkIfMobile)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return isMobile
}

export default useMobileScreen
