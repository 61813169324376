import axios from 'axios'
import { getAuth } from 'firebase/auth'

export const refreshAuthToken = async () => {
    const auth = getAuth()
    const user = auth.currentUser
    if (!user) throw new Error('No authenticated user found.')

    const refreshedToken = await user.getIdToken(true)
    axios.defaults.headers.common['Authorization'] = `Bearer ${refreshedToken}`
    return refreshedToken
}

export const fetchUserData = async () => {
    const response = await axios.get(
        `${process.env.REACT_APP_BACK_URL}/user-data`,
    )
    return response.data
}

export async function getPublicUserData(id) {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_BACK_URL}/user-data/${id}`,
        )
        return response.data
    } catch (error) {
        console.error('Error getting public user data:', error)
        throw error
    }
}

export async function searchUsers(query) {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_BACK_URL}/user/search`,
            {
                params: {
                    q: query,
                },
            },
        )
        return response.data
    } catch (error) {
        console.error('Error searching users:', error)
        throw error
    }
}

export async function updateUDOnServer(updatedUserData) {
    try {
        const reponse = await axios.put(
            `${process.env.REACT_APP_BACK_URL}/user-data`,
            updatedUserData,
        )
        if (reponse.status !== 200) {
            throw new Error('Error updating user data')
        }
        return reponse.data
    } catch (error) {
        console.error('Error updating user data:', error)
        throw error
    }
}

export async function fetchCVData() {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_BACK_URL}/api/cv/`,
        )
        if (response.status !== 200) {
            throw new Error('Error fetching CV data')
        }
        return response.data
    } catch (error) {
        console.error('Error fetching CV data:', error)
        throw error
    }
}

export async function updateCVOnServer(updatedCVData) {
    try {
        const response = await axios.put(
            `${process.env.REACT_APP_BACK_URL}/api/cv`,
            updatedCVData,
        )
        if (response.status !== 200) {
            throw new Error('Error updating CV data')
        }
        return response.data
    } catch (error) {
        console.error('Error updating CV data:', error)
        throw error
    }
}

export async function resetCV() {
    try {
        const response = await axios.put(
            `${process.env.REACT_APP_BACK_URL}/api/admin/cv/reset`,
        )
        if (response.status !== 200) {
            throw new Error('Error resetting CV')
        }
        return response.data
    } catch (error) {
        console.error('Error resetting CV:', error)
        throw error
    }
}

export async function undoUploadCV() {
    try {
        const response = await axios.put(
            `${process.env.REACT_APP_BACK_URL}/api/admin/cv/undo-upload`,
        )
        if (response.status !== 200) {
            throw new Error('Error undoing CV upload')
        }
        return response.data
    } catch (error) {
        console.error('Error undoing CV upload:', error)
        throw error
    }
}

export async function undoFinalized() {
    try {
        const response = await axios.put(
            `${process.env.REACT_APP_BACK_URL}/api/admin/cv/undo-finalized`,
        )
        if (response.status !== 200) {
            throw new Error('Error undoing finalized CV')
        }
        return response.data
    } catch (error) {
        console.error('Error undoing finalized CV:', error)
        throw error
    }
}

export async function getAccountType() {
    const response = await axios.get(
        `${process.env.REACT_APP_BACK_URL}/account-type`,
    )
    return response.data.AccountType
}

export async function createPrompt() {
    const promptData = { content: `test` }
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_BACK_URL}/api/prompt`,
            promptData,
        )
        return response.data
    } catch (error) {
        console.error('Error creating prompt:', error)
        throw error
    }
}

export async function getAllPrompt() {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_BACK_URL}/api/prompt/`,
        )
        return response.data
    } catch (error) {
        console.error('Error getting prompt:', error)
        throw error
    }
}

export async function getPromptByCode(code) {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_BACK_URL}/api/prompt/code/${code}`,
        )
        return response.data
    } catch (error) {
        console.error('Error getting prompt:', error)
        throw error
    }
}

export async function updatePromptById({ id, prompt }) {
    try {
        const promptData = {
            content: prompt,
        }
        const response = await axios.put(
            `${process.env.REACT_APP_BACK_URL}/api/prompt/${id}`,
            promptData,
        )
        return response.data
    } catch (error) {
        console.error('Error updating prompt:', error)
        throw error
    }
}

export async function getAllUsers() {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_BACK_URL}/api/admin/user/`,
        )
        return response.data
    } catch (error) {
        console.error('Error getting users:', error)
        throw error
    }
}

export async function getUserById(id) {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_BACK_URL}/api/admin/user/${id}`,
        )
        return response.data
    } catch (error) {
        console.error('Error getting user:', error)
        throw error
    }
}

export async function updateUserById(id, user) {
    try {
        const userData = {
            ...user,
        }
        const response = await axios.put(
            `${process.env.REACT_APP_BACK_URL}/api/admin/user/${id}`,
            userData,
        )
        return response.data
    } catch (error) {
        console.error('Error updating user:', error)
        throw error
    }
}

export async function exportUsersGS() {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_BACK_URL}/api/admin/export-users`,
        )
        if (response.status !== 200) {
            throw new Error('Error exporting users to Google Sheets')
        }
        return response.data
    } catch (error) {
        console.error('Error exporting users to Google Sheets:', error)
        throw error
    }
}

export async function getMessagesById(id) {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_BACK_URL}/api/thread/${id}`,
        )
        return response.data
    } catch (error) {
        console.error('Error getting messages:', error)
        throw error
    }
}

export async function createNewThread() {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_BACK_URL}/api/thread`,
        )
        return response.data
    } catch (error) {
        console.error('Error creating thread:', error)
        throw error
    }
}

export async function getPrivacy() {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_BACK_URL}/api/google/privacy-policy`,
            {
                responseType: 'blob',
            },
        )
        return URL.createObjectURL(response.data)
    } catch (error) {
        console.error('Error getting privacy:', error)
        throw error
    }
}

export async function getTerms() {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_BACK_URL}/api/google/terms-of-use`,
            {
                responseType: 'blob',
            },
        )
        return URL.createObjectURL(response.data)
    } catch (error) {
        console.error('Error getting terms:', error)
        throw error
    }
}

export async function uploadProfilePicture(file) {
    const formData = new FormData()
    formData.append('image', file)

    const response = await axios.post(
        `${process.env.REACT_APP_BACK_URL}/api/file/upload`,
        formData,
    )
    return response.data
}

export async function getProfilePicture(img) {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_BACK_URL}/api/file/proxy-image`,
            { imageUrl: img },
            {
                responseType: 'blob',
            },
        )
        return URL.createObjectURL(response.data)
    } catch (error) {
        console.error('Error fetching profile picture:', error)
        throw error
    }
}

export const fetchNationalities = async () => {
    const response = await fetch(
        'https://raw.githubusercontent.com/Imagin-io/country-nationality-list/master/countries.json',
    )
    const data = await response.json()
    return data.map((country) => ({
        value: country.nationality,
        label: country.nationality,
    }))
}

export const getReleaseNotes = async () => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_BACK_URL}/api/git/tags`,
        )
        return response.data
    } catch (error) {
        console.error('Error getting release notes:', error)
        throw error
    }
}

export async function createCompany(companyData) {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_BACK_URL}/api/company/create`,
            companyData,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )
        return response.data
    } catch (error) {
        console.error('Error creating company:', error)
        throw error
    }
}

export async function getCompaniesByUser() {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_BACK_URL}/api/company/user`,
        )
        return response.data
    } catch (error) {
        console.error('Error getting companies:', error)
        throw error
    }
}

export async function getCompanyById(companyId) {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_BACK_URL}/api/company/${companyId}`,
        )
        return response.data
    } catch (error) {
        console.error('Error getting company:', error)
        throw error
    }
}

export async function updateCompany(companyData) {
    try {
        const response = await axios.patch(
            `${process.env.REACT_APP_BACK_URL}/api/company/update/infos/${companyData._id}`,
            companyData,
        )
        return response.data
    } catch (error) {
        console.error('Error updating company:', error)
        throw error
    }
}

export async function deleteCompany(companyId) {
    try {
        const response = await axios.delete(
            `${process.env.REACT_APP_BACK_URL}/api/company/delete/${companyId}`,
        )
        return response.data
    } catch (error) {
        console.error('Error deleting company:', error)
        throw error
    }
}

export async function addEmployee(companyId, employeeData) {
    try {
        const response = await axios.patch(
            `${process.env.REACT_APP_BACK_URL}/api/company/${companyId}/addEmployee`,
            employeeData,
        )
        return response.data
    } catch (error) {
        console.error('Error adding employee:', error)
        throw error
    }
}

export async function removeEmployee(companyId, userId) {
    try {
        const response = await axios.patch(
            `${process.env.REACT_APP_BACK_URL}/api/company/${companyId}/removeEmployee`,
            { userId },
        )
        return response.data
    } catch (error) {
        console.error('Error removing employee:', error)
        throw error
    }
}

export async function updateEmployeeRole(companyId, employeeData) {
    try {
        const response = await axios.patch(
            `${process.env.REACT_APP_BACK_URL}/api/company/${companyId}/updateEmployeeRole`,
            employeeData,
        )
        return response.data
    } catch (error) {
        console.error('Error updating employee role:', error)
        throw error
    }
}

export async function createJD(jdData, companyId) {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_BACK_URL}/api/jd/create/${companyId}`,
            jdData,
        )
        return response.data
    } catch (error) {
        console.error('Error creating job description:', error)
        throw error
    }
}

export async function getJDByCompany(companyId) {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_BACK_URL}/api/jd/company/${companyId}`,
        )
        return response.data
    } catch (error) {
        console.error('Error getting job description:', error)
        throw error
    }
}

export async function getJDById(jdId) {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_BACK_URL}/api/jd/${jdId}`,
        )
        return response.data
    } catch (error) {
        console.error('Error getting job description:', error)
        throw error
    }
}

export async function updateJD(jdData) {
    try {
        const response = await axios.patch(
            `${process.env.REACT_APP_BACK_URL}/api/jd/update/infos/${jdData._id}`,
            jdData,
        )
        return response.data
    } catch (error) {
        console.error('Error updating job description:', error)
        throw error
    }
}

export async function deleteJD(jdId) {
    try {
        const response = await axios.delete(
            `${process.env.REACT_APP_BACK_URL}/api/jd/delete/${jdId}`,
        )
        return response.data
    } catch (error) {
        console.error('Error deleting job description:', error)
        throw error
    }
}

export async function getJDMessages(jdId) {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_BACK_URL}/api/jd/${jdId}/messages`,
        )
        return response.data.sort((a, b) => a.timestamp - b.timestamp)
    } catch (error) {
        console.error('Error getting job description messages:', error)
        throw error
    }
}

export async function sendJDMessage(jdId, message) {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_BACK_URL}/api/jd/${jdId}/messages`,
            { message },
        )
        return response.data
    } catch (error) {
        console.error('Error sending job description message:', error)
        throw error
    }
}

export async function testSendJDMessage(jdId, message) {
    try {
        const response = await axios.patch(
            `${process.env.REACT_APP_BACK_URL}/api/jd/${jdId}/messages`,
            { message },
        )
        return response.data
    } catch (error) {
        console.error('Error sending job description message:', error)
        throw error
    }
}
