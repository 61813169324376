import React, { memo, useEffect } from 'react'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { VisibilityButton, DeleteButton } from '@compUtils'
import { useTmpData } from '@context'

import { Dots } from '@assets'

const HeaderComponent = memo(
    ({
        onForceClose,
        initialValue,
        onClose,
        onAdd,
        expType,
        typeName = null,
    }) => {
        const {
            deleteModalOpen,
            setDeleteModalOpen,
            menuOpen,
            setMenuOpen,
            setAllowClose,
        } = useTmpData()

        useEffect(() => {
            setAllowClose(!(menuOpen || deleteModalOpen))
        }, [menuOpen, deleteModalOpen, setAllowClose])

        const handleAdd = () => {
            onAdd()
            setMenuOpen(null)
        }

        return (
            <div className="new-info-header">
                <span className="title">{typeName}</span>
                <div className="new-info-header-buttons">
                    <div className="done-button">
                        <button onClick={onForceClose}>Done</button>
                    </div>
                    <VisibilityButton
                        detailKeyOrPath={`${expType}.${initialValue.id}`}
                    />
                    <DeleteButton
                        detailKeyOrPath={`${expType}.${initialValue.id}`}
                        onClose={onClose}
                    />
                    <IconButton
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(e) => setMenuOpen(e.currentTarget)}
                    >
                        <img
                            src={Dots}
                            alt="les trois petits points"
                            className="dots-button-image"
                        />
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        anchorEl={menuOpen}
                        keepMounted
                        open={Boolean(menuOpen)}
                        onClose={() => setMenuOpen(null)}
                    >
                        <MenuItem onClick={handleAdd}>
                            Add another {typeName}
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                setMenuOpen(null)
                                setDeleteModalOpen(true)
                            }}
                        >
                            Delete
                        </MenuItem>
                    </Menu>
                </div>
            </div>
        )
    },
)

export default HeaderComponent
