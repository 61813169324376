import React, { useState, useEffect, useMemo } from 'react'
import { useTmpData, useContentOpen } from '@context'
import { useCVUpdate } from '@hooks'
import { Modal } from '@modals'
import { formatTime } from '@utils'
import { AddSections, VisibilityButton } from '@compUtils'
import { formatDescription, formatLocation } from '@utils'
import ExperiencesModal from '@modals/Experiences/ExperiencesModal'

import './ExperienceEntry.css'

const ExperienceEntry = ({
    id,
    CompanyName,
    CompanyWebSite,
    CompanyDescription,
    CompanyId,
    ExperienceType,
    Title,
    StartDate,
    EndDate,
    Location,
    ContractType,
    WorkMode,
    EngagementTime,
    Description,
    Skills,
    Thread,
    AssistantFinished,
}) => {
    const { isEditing } = useTmpData()
    const { handleModalCloseExp } = useCVUpdate()
    const [isModelOpen, setIsModelOpen] = useState(false)
    const { isContentOpen } = useContentOpen()
    const [hasModalClosed, setHasModalClosed] = useState(false)
    const [addTrigger, setAddTrigger] = useState(false)

    useEffect(() => {
        if (hasModalClosed) {
            setHasModalClosed(false)
        }
    }, [hasModalClosed])

    const time = useMemo(
        () => formatTime(StartDate, EndDate),
        [StartDate, EndDate],
    )
    const formattedLocation = useMemo(
        () => formatLocation(Location),
        [Location],
    )

    const onClose = async (experienceChanges) => {
        setHasModalClosed(true)
        setIsModelOpen(false)
        if (!experienceChanges) return

        handleModalCloseExp(experienceChanges)
    }

    const onAdd = async (experienceChanges) => {
        setHasModalClosed(true)
        setIsModelOpen(false)

        handleModalCloseExp(experienceChanges)

        setAddTrigger(true)
        setTimeout(() => setAddTrigger(false), 0)
    }

    const modalInitialValue = {
        id,
        CompanyName,
        CompanyWebSite,
        CompanyDescription,
        CompanyId,
        ExperienceType,
        Title,
        StartDate,
        EndDate,
        Location,
        ContractType,
        WorkMode,
        EngagementTime,
        Description,
        Skills,
        Thread,
        AssistantFinished,
    }

    return (
        <>
            <div
                className={`experience-entry ${
                    isEditing ? 'blue entry-editing' : ''
                }`}
                onClick={(e) => {
                    if (isEditing && !isContentOpen) {
                        e.stopPropagation()
                        setIsModelOpen(true)
                    }
                }}
            >
                <div className="experience-entry-header">
                    <h3 className="experience-title">{Title}</h3>
                    <VisibilityButton detailKeyOrPath={`Experiences.${id}`} />
                </div>

                <div className="experience-infos">
                    <div className="experience-location">
                        {formattedLocation}
                        {formattedLocation && WorkMode && ' · '}
                        {WorkMode ? <span> {WorkMode}</span> : null}
                    </div>
                    <div className="experience-time">
                        {time}
                        {time && ContractType && ' · '}
                        {ContractType ? <span> {ContractType}</span> : null}
                        {EngagementTime ? (
                            <span> · {EngagementTime}</span>
                        ) : null}
                    </div>
                    <div className="experience-description">
                        {formatDescription(Description)}
                    </div>
                </div>
            </div>

            <Modal
                isOpen={isModelOpen}
                onClose={onClose}
                onAdd={onAdd}
                onSave={handleModalCloseExp}
                modal="Experience"
                initialValue={modalInitialValue}
                className="modal-content wide-exp"
                Component={ExperiencesModal}
            />
            <AddSections
                category={ExperienceType}
                hasParent={true}
                triggerAddExperience={addTrigger}
            />
        </>
    )
}

export default ExperienceEntry
