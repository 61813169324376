import React, { useEffect, useState, memo } from 'react'
import { useContentOpen, useUserData, useCV } from '@context'
import { useMobileScreen } from '@hooks'
import Tooltip from '@mui/material/Tooltip'
import PDF from '@modals/PDF'

import { Reload } from '@assets'

function SCProfile() {
    const isMobile = useMobileScreen()
    const { isSecondaryContentOpen } = useContentOpen()
    const { userData: contextUserData } = useUserData()
    const { cvData: contextUserCV } = useCV()
    const [userData, setUserData] = useState(contextUserData)
    const [userCV, setUserCV] = useState(contextUserCV)
    const [isAutoRefresh, setIsAutoRefresh] = useState(() => {
        const storedAutoRefresh = localStorage.getItem('isAutoRefresh')
        return storedAutoRefresh !== null ? JSON.parse(storedAutoRefresh) : true
    })

    const handleReload = () => {
        setUserData(contextUserData)
        setUserCV(contextUserCV)
    }

    const handleAutoRefresh = () => {
        setIsAutoRefresh(!isAutoRefresh)
    }

    useEffect(() => {
        localStorage.setItem('isAutoRefresh', JSON.stringify(isAutoRefresh))
    }, [isAutoRefresh])

    useEffect(() => {
        if (isAutoRefresh) {
            setUserData(contextUserData)
            setUserCV(contextUserCV)
        }
    }, [contextUserData, contextUserCV, isAutoRefresh])

    if (!userData || !userCV) {
        return null
    }

    return (
        <div className="secondary-content-pdf-container">
            <div className="secondary-content-title">
                <h3 className="title">PREVIEW</h3>
                <button onClick={handleReload}>
                    <Tooltip title="Refresh" placement="top">
                        <img
                            src={Reload}
                            alt="Reload"
                            className="reload-icon"
                        />
                    </Tooltip>
                </button>
                <button onClick={handleAutoRefresh}>
                    <Tooltip title="Auto Refresh" placement="top">
                        <span
                            className={`${
                                isAutoRefresh ? 'refresh-active' : ''
                            }`}
                        >
                            AUTO
                        </span>
                    </Tooltip>
                </button>
            </div>
            {(!isMobile || (isMobile && isSecondaryContentOpen)) && (
                <div className="secondary-content-pdf">
                    <PDF userData={userData} userCV={userCV} />
                </div>
            )}
        </div>
    )
}

export default memo(SCProfile)
