import React, { memo } from 'react'
import { autoGrowText } from '@utils'

const MAX_CHAR_COMPANY = 2000
const MIN_CHAR_COMPANY = 10

const CompanyDescriptionComponent = memo(
    ({ textAreaRef, experience, onChange }) => {
        const handleDescriptionChange = (e) => {
            onChange('CompanyDescription', e.target.value)
        }

        return (
            <div className="new-info-exprerience-container">
                <div
                    className="new-info-experience-modal-container"
                    style={{ position: 'relative' }}
                >
                    <label
                        className="new-info-experience-modal-label"
                        htmlFor="company-description"
                    >
                        Company Description
                    </label>
                    <textarea
                        id="company-description"
                        ref={textAreaRef}
                        value={experience.CompanyDescription || ''}
                        onChange={handleDescriptionChange}
                        onInput={(e) => autoGrowText(e.target, 5)}
                        placeholder="Enter company description..."
                        className={
                            experience.CompanyDescription?.length <=
                                MAX_CHAR_COMPANY &&
                            experience.CompanyDescription?.length >=
                                MIN_CHAR_COMPANY
                                ? 'valid-border'
                                : 'invalid-border'
                        }
                    />
                    <div
                        className="new-info-description"
                        style={
                            experience.CompanyDescription?.length <=
                                MAX_CHAR_COMPANY &&
                            experience.CompanyDescription?.length >=
                                MIN_CHAR_COMPANY
                                ? {}
                                : { color: 'red' }
                        }
                    >
                        {experience.CompanyDescription?.length} /{' '}
                        {MAX_CHAR_COMPANY}
                    </div>
                </div>
            </div>
        )
    },
)

export default CompanyDescriptionComponent
