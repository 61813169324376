import { useState } from 'react'
import { parseDateYearMonth } from '@utils'

function useDateHandler(initialStartDate, initialEndDate, onChange) {
    const [startDate, setStartDate] = useState(
        parseDateYearMonth(initialStartDate),
    )

    const [endDate, setEndDate] = useState(parseDateYearMonth(initialEndDate))

    const updateDate = (type, field, value) => {
        const date = type === 'start' ? startDate : endDate
        const updatedDate = { ...date, [field]: value }
        type === 'start' ? setStartDate(updatedDate) : setEndDate(updatedDate)

        onChange(
            type === 'start' ? 'StartDate' : 'EndDate',
            `${updatedDate.month}/${updatedDate.year}`,
        )
    }

    const setPresent = () => {
        setEndDate({ month: 'present', year: '' })
        onChange('EndDate', 'present')
    }

    return { startDate, endDate, updateDate, setPresent }
}

export default useDateHandler
