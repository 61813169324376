import { Routes, Route, Navigate } from 'react-router-dom'
import { JDDetails, JDRequirements, JDDraft, JDFinalize } from './JDPages'

function JobDescriptionRoutes({ job }) {
    return (
        <Routes>
            <Route path="details" element={<JDDetails job={job} />} />
            <Route path="requirements" element={<JDRequirements job={job} />} />
            <Route path="draft" element={<JDDraft job={job} />} />
            <Route path="finalize" element={<JDFinalize job={job} />} />
            <Route index element={<Navigate to="details" />} />
        </Routes>
    )
}

export default JobDescriptionRoutes
