import React, { useState, useEffect } from 'react'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { getCompanyById, getJDByCompany } from '@utils'
import { useJD } from '@context'
import JDCard from './jdCard'

import '@styles/hire.css'

function JobDescriptions() {
    const { setInitialJDData, navigateToMostAdvancedStep } = useJD()
    const { companyId } = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const companyFromState = location.state
    const [selectedIndex, setSelectedIndex] = useState(-1)

    const {
        data: jobDescriptions,
        isLoading,
        isError,
        error,
    } = useQuery(`jobDescriptions-${companyId}`, () =>
        getJDByCompany(companyId),
    )

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowDown') {
                setSelectedIndex(
                    (prevIndex) => (prevIndex + 1) % jobDescriptions.length,
                )
            } else if (event.key === 'ArrowUp') {
                setSelectedIndex(
                    (prevIndex) =>
                        (prevIndex - 1 + jobDescriptions.length) %
                        jobDescriptions.length,
                )
            } else if (event.key === 'Enter' && selectedIndex !== -1) {
                setInitialJDData(jobDescriptions[selectedIndex])
                navigateToMostAdvancedStep(jobDescriptions[selectedIndex]._id)
            }
        }
        document.addEventListener('keydown', handleKeyDown)
        return () => document.removeEventListener('keydown', handleKeyDown)
    }, [
        jobDescriptions,
        navigate,
        selectedIndex,
        setInitialJDData,
        navigateToMostAdvancedStep,
    ])

    const { data: company } = useQuery(
        ['company', companyId],
        () => getCompanyById(companyId),
        {
            enabled: !companyFromState,
        },
    )

    const finalCompany = companyFromState || company

    if (isLoading) {
        return (
            <div>
                <h1>Loading...</h1>
            </div>
        )
    }

    if (isError) {
        toast.error(error.response.data.message)
        navigate('/hire')
        return null
    }

    return (
        <div className="hire-companies">
            {jobDescriptions.map((jd, index) => (
                <JDCard
                    key={jd._id}
                    job={jd}
                    company={finalCompany}
                    isSelected={index === selectedIndex}
                />
            ))}
        </div>
    )
}

export default JobDescriptions
