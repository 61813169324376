import React, { memo } from 'react'
import LocationSearch from '@compUtils/LocationSearch'

import { customStylesFacultativeIn } from '@styles/styles'

const ExpericeTitleLocationComponent = memo(({ experience, onChange }) => {
    const handleTitleChange = (e) => {
        onChange('Title', e.target.value)
    }

    const handleLocationChange = (option) => {
        onChange('Location', option.value)
    }

    return (
        <div className="new-info-experience-modal">
            <div className="input-container">
                <label
                    className="new-info-experience-modal-label"
                    htmlFor="title"
                >
                    {experience.ExperienceType === 'Project'
                        ? 'Project Title'
                        : 'Title'}
                </label>
                <input
                    id="title"
                    type="text"
                    value={experience.Title || ''}
                    onChange={handleTitleChange}
                    placeholder="Title"
                    style={{ height: '38px' }}
                    className={
                        experience.Title?.trim()
                            ? 'valid-border'
                            : 'invalid-border'
                    }
                />
            </div>
            <div className="input-container">
                <label
                    className="new-info-experience-modal-label"
                    htmlFor="title"
                >
                    Location
                </label>
                <LocationSearch
                    id="location"
                    value={{
                        value: experience.Location,
                        label: experience.Location,
                    }}
                    onChange={handleLocationChange}
                    placeholder="Location"
                    styleInvalid={customStylesFacultativeIn}
                />
            </div>
        </div>
    )
})

export default ExpericeTitleLocationComponent
