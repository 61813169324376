import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useCV } from '@context/CVContext'
import Select from 'react-select'
import { VisibilityButton } from '@compUtils'
import { fetchNationalities } from '@utils'

function Nationality({ isModal = false }) {
    const { cvData, updateCV } = useCV()
    const [nationality, setNationality] = useState(
        cvData.Nationality
            ? { value: cvData.Nationality, label: cvData.Nationality }
            : null,
    )

    const { data: options } = useQuery('nationalities', fetchNationalities, {
        staleTime: Infinity,
    })

    const handleNationalityChange = (selectedOption) => {
        setNationality(selectedOption)
        updateCV((prevData) => ({
            ...prevData,
            Nationality: selectedOption.value,
        }))
    }

    const customStyles = {
        container: (provided) => ({
            ...provided,
            width: '100%',
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 1000,
        }),
    }

    return (
        <div
            className={`new-component ${
                nationality === null ? 'invalid-border' : 'valid-border'
            }`}
            style={isModal ? { width: 'auto' } : {}}
        >
            <div className="new-info-wrapper">
                <div className="new-info-header">
                    Nationality
                    <VisibilityButton detailKeyOrPath={'Nationality'} />
                </div>
                <div className="new-info-container">
                    <Select
                        value={nationality}
                        onChange={handleNationalityChange}
                        options={options}
                        isSearchable
                        placeholder="Select your nationality"
                        styles={customStyles}
                        openMenuOnFocus={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default Nationality
