import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useCV, useTmpData, useAuth } from '@context'
import { useValidation } from '@hooks'
import { Modal } from '@modals'

import SummaryModal from '@modals/Experiences/SummaryModal'

import './Summary.css'

function Summary() {
    const { cvData, updateCV } = useCV()
    const { isEditing } = useTmpData()
    const { socket } = useAuth()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [summaryMessages, setSummaryMessages] = useState(
        cvData.Summary ? cvData.Summary : '',
    )
    const summaryMessagesRef = useRef(summaryMessages)

    useEffect(() => {
        summaryMessagesRef.current = summaryMessages
    }, [summaryMessages])

    useEffect(() => {
        socket.on('summary-message', (message) => {
            setSummaryMessages((prevMessages) => prevMessages + message)
        })

        socket.on('summary-end', () => {
            const updatedSummary = summaryMessagesRef.current
            const updatedCVData = { ...cvData, Summary: updatedSummary }
            updateCV(updatedCVData)
        })

        socket.on('chat-error', (error) => {
            console.error('Error from server:', error.error)
        })

        return () => {
            socket.off('summary-message')
            socket.off('chat-error')
            socket.off('summary-end')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleModalClose = (updatedData) => {
        setIsModalOpen(false)
        updateCV((prevUserData) => {
            return { ...prevUserData, ...updatedData }
        })
        setSummaryMessages(updatedData.Summary)
    }

    const validationConfig = useMemo(
        () => [
            {
                key: 'Summary',
                validate: (value) => (value ? '' : '!'),
            },
        ],
        [],
    )

    const { className } = useValidation(cvData, isEditing, validationConfig)

    return (
        <div className={`summary`}>
            <h3 className="title">SUMMARY</h3>
            <div className={`summary-container ${className}`}>
                <div
                    className={`summary-entry ${
                        isEditing ? 'entry-editing' : ''
                    }`}
                    onClick={() => isEditing && setIsModalOpen(true)}
                >
                    <span>{summaryMessages}</span>
                </div>
            </div>
            <Modal
                isOpen={isModalOpen}
                onClose={handleModalClose}
                modal="Summary"
                initialValue={{ Summary: summaryMessages }}
                className="modal-content wide"
                Component={SummaryModal}
            />
        </div>
    )
}

export default Summary
