import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import Draggable from 'react-draggable'

import { useTmpData } from '@context'

import { useMobileScreen } from '@hooks'

import './Modal.css'

function Modal({
    isOpen,
    onClose,
    Component,
    className = 'modal-content',
    initialValue,
    initialProps,
    onAdd,
    onSave,
}) {
    const { allowClose } = useTmpData()
    const isMobile = useMobileScreen()

    const contentRef = useRef(null)
    const [isClickInside, setIsClickInside] = useState(false)
    const [modalData, setModalData] = useState(initialValue || null)

    useEffect(() => {
        setModalData(initialValue || null)
    }, [isOpen, initialValue])

    if (!isOpen) return null

    const handleMouseDown = (e) => {
        e.stopPropagation()
        if (!allowClose) return
        if (contentRef.current && contentRef.current.contains(e.target)) {
            setIsClickInside(true)
        } else {
            setIsClickInside(false)
        }
    }

    const handleMouseUp = (e) => {
        e.stopPropagation()
        if (!allowClose) return
        if (!isClickInside && !contentRef.current.contains(e.target)) {
            onClose(modalData)
        }
    }

    const modalContent = Component ? (
        <Component
            {...initialProps}
            isModal={true}
            initialValue={initialValue}
            onUpdate={setModalData}
            onClose={onClose}
            onForceClose={() => onClose(modalData)}
            onAdd={() => onAdd && onAdd(modalData)}
            onSave={() => onSave && onSave(modalData)}
            modalRef={contentRef}
        />
    ) : null

    return ReactDOM.createPortal(
        <div
            className={`modal-overlay ${isOpen ? 'open' : ''}`}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
        >
            {isMobile ? (
                <div
                    className={className}
                    ref={contentRef}
                    onClick={(e) => e.stopPropagation()}
                >
                    {modalContent}
                </div>
            ) : (
                <Draggable bounds="parent" handle=".new-info-header">
                    <div
                        className={className}
                        ref={contentRef}
                        onClick={(e) => e.stopPropagation()}
                    >
                        {modalContent}
                    </div>
                </Draggable>
            )}
        </div>,
        document.getElementById('modal-root'),
    )
}

export default Modal
