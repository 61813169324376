import React, { useState, useEffect } from 'react'
import { useCV } from '@context/CVContext'
import moment from 'moment'
import Select from 'react-select'
import { VisibilityButton } from '@compUtils'

function BirthDate({ isModal = false }) {
    const { cvData, updateCV } = useCV()
    const [isDateValid, setDateValid] = useState(false)
    const birthDateObject = cvData.BirthDate ? new Date(cvData.BirthDate) : null

    const [day, setDay] = useState(
        birthDateObject ? birthDateObject.getDate() : '',
    )
    const [month, setMonth] = useState(
        birthDateObject ? birthDateObject.getMonth() + 1 : '',
    )
    const [year, setYear] = useState(
        birthDateObject ? birthDateObject.getFullYear() : '',
    )

    const days = Array.from({ length: 31 }, (_, i) => ({
        value: i + 1,
        label: String(i + 1),
    }))
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ]?.map((month, index) => ({ value: index + 1, label: month }))

    const currentYear = new Date().getFullYear()
    const years = Array.from(
        { length: 64 },
        (_, i) => currentYear - 16 - i,
    )?.map((year) => ({ value: year, label: String(year) }))

    useEffect(() => {
        const formattedDate = `${year}-${month}-${day}`

        updateCV((prevData) => ({
            ...prevData,
            BirthDate: formattedDate,
        }))

        setDateValid(verifyDate(day, month, year))
    }, [day, month, year, updateCV])

    function verifyDate(day, month, year) {
        const inputDate = moment(`${year}-${month}-${day}`, 'YYYY-MM-DD')

        if (!inputDate.isValid()) {
            return false
        }

        const age = moment().diff(inputDate, 'years')
        return age >= 16 && age <= 80
    }

    const handleSelectClick = (event) => {
        event.stopPropagation()
    }

    return (
        <div
            className={`new-component ${
                !isDateValid ? 'invalid-border' : 'valid-border'
            }`}
            style={isModal ? { width: 'auto' } : {}}
        >
            <div className="new-info-wrapper">
                <div className="new-info-header">
                    Birth Date
                    <VisibilityButton detailKeyOrPath={'BirthDate'} />
                </div>
                <div className="new-info-container" onClick={handleSelectClick}>
                    <Select
                        value={days.find((d) => d.value === day)}
                        onChange={(option) => setDay(option.value)}
                        options={days}
                        placeholder="Day"
                        openMenuOnFocus={true}
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                maxHeight: '38px',
                            }),
                        }}
                    />
                    <Select
                        value={months.find((m) => m.value === month)}
                        onChange={(option) => setMonth(option.value)}
                        options={months}
                        placeholder="Month"
                        openMenuOnFocus={true}
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                width: '150px',
                                maxHeight: '38px',
                            }),
                        }}
                    />
                    <Select
                        value={years.find((y) => y.value === year)}
                        onChange={(option) => setYear(option.value)}
                        options={years}
                        placeholder="Year"
                        openMenuOnFocus={true}
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                maxHeight: '38px',
                            }),
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default BirthDate
