import { Routes, Route, Navigate } from 'react-router-dom'
import { Finalize, Information, Preferences, Start } from './'

function RegistrationRoutes({ accessibleSteps }) {
    return (
        <Routes>
            <Route path="start" element={<Start />} />
            <Route
                path="information"
                element={
                    accessibleSteps.start ? (
                        <Information />
                    ) : (
                        <Navigate to="/profile/start" />
                    )
                }
            />
            <Route
                path="preferences"
                element={
                    accessibleSteps.information ? (
                        <Preferences />
                    ) : (
                        <Navigate to="/profile/information" />
                    )
                }
            />
            <Route
                path="finalize"
                element={
                    accessibleSteps.preference ? (
                        <Finalize />
                    ) : (
                        <Navigate to="/profile/preferences" />
                    )
                }
            />
            <Route path="*" element={<Navigate to="/profile/start" />} />
        </Routes>
    )
}

export default RegistrationRoutes
