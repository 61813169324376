import React, { useState } from 'react'
import { Modal } from '@modals'
import InfoCard from '@hire/JobDescription/JDPages/InfoCard'

const EditableBlock = ({ header, value, placeholder, onValueChange }) => {
    const [isOpen, setIsOpen] = useState(false)

    const handleModalClose = () => {
        setIsOpen(false)
    }

    const handleModalOpen = () => {
        setIsOpen(true)
    }

    return (
        <div className="jd-finalize-block">
            <h3 className="title">{header}</h3>
            <div
                className="jd-finalize-content editable"
                onClick={handleModalOpen}
            >
                <span>{value}</span>
            </div>
            <Modal
                isOpen={isOpen}
                onClose={handleModalClose}
                Component={InfoCard}
                className="modal-content"
                initialProps={{
                    header: header,
                    value: value,
                    onChange: (e) => onValueChange(e.target.value),
                    placeholder: placeholder,
                }}
            />
        </div>
    )
}

export default EditableBlock
