import React, { useEffect, useState, useCallback } from 'react'
import Tooltip from '@mui/material/Tooltip'
import { instructionTypes, descriptionState, autoGrowTextWithMin } from '@utils'
import { CharacterCounter } from '@compUtils'

import { Send } from '@assets'

const MAX_MESSAGE_LENGTH = 1000

const TextareaInput = ({
    userMessageRef,
    handleShortcut,
    handleInput,
    currentState,
}) => (
    <textarea
        id="description"
        type="text"
        ref={userMessageRef}
        className="chat-input"
        placeholder="Message Cotalent..."
        onKeyDown={handleShortcut}
        disabled={currentState === descriptionState.ASSISTANT_WRITING}
        onInput={handleInput}
    />
)

const MessageCreateComponent = ({
    userMessageRef,
    currentState,
    handleSendClick,
    handleGenerate,
    generate,
    handleYesNoResponse,
    generated_questions,
}) => {
    const [characterCount, setCharacterCount] = useState(0)

    useEffect(() => {
        if (userMessageRef.current) {
            setCharacterCount(userMessageRef.current.value.length)
        }
    }, [userMessageRef, userMessageRef.current?.value])

    const handleInput = useCallback((e) => {
        autoGrowTextWithMin(e.target, 21, 83)
        let text = e.target.value
        if (text.length > MAX_MESSAGE_LENGTH) {
            text = text.substring(0, MAX_MESSAGE_LENGTH)
            e.target.value = text
        }
        setCharacterCount(text.length)
    }, [])

    const handleShortcut = useCallback(
        (e) => {
            if (e.key === 'Enter') {
                e.preventDefault()
                if (e.shiftKey) {
                    if (generate) {
                        handleYesNoResponse('')
                    } else if (currentState === descriptionState.REGENERATING) {
                        handleSendClick(instructionTypes.REGENERATE)
                    } else {
                        handleSendClick(instructionTypes.QUESTION)
                    }
                } else if (e.altKey) {
                    handleGenerate()
                }
            }
        },
        [
            generate,
            currentState,
            handleGenerate,
            handleYesNoResponse,
            handleSendClick,
        ],
    )

    return (
        <div className="textarea-send-container">
            <TextareaInput
                userMessageRef={userMessageRef}
                handleShortcut={handleShortcut}
                handleInput={handleInput}
                currentState={currentState}
            />
            <div className="chat-input-send-container">
                {currentState === descriptionState.ASSISTANT_ACTIVE &&
                    !generate &&
                    generated_questions >= 3 && (
                        <Tooltip
                            title="Press Alt + Enter to generate description"
                            placement="top"
                        >
                            <div className="hn-buttons-container">
                                <button
                                    className="hn-button-blue"
                                    onClick={handleGenerate}
                                >
                                    Generate Description
                                </button>
                            </div>
                        </Tooltip>
                    )}
                <CharacterCounter
                    characterCount={characterCount}
                    maxCount={MAX_MESSAGE_LENGTH}
                />
                {currentState === descriptionState.REGENERATING && (
                    <Tooltip
                        title="Press Shift + Enter to regenerate a description"
                        placement="top"
                    >
                        <button
                            className="chat-input-send"
                            onClick={() => {
                                handleSendClick(instructionTypes.REGENERATE)
                            }}
                        >
                            <img src={Send} alt="send" />
                        </button>
                    </Tooltip>
                )}
                {currentState === descriptionState.ASSISTANT_ACTIVE && (
                    <>
                        <Tooltip
                            title="Press Shift + Enter to ask another question"
                            placement="top"
                        >
                            <button
                                className="chat-input-send"
                                onClick={() => {
                                    if (generate) {
                                        handleYesNoResponse('')
                                    } else {
                                        handleSendClick(
                                            instructionTypes.QUESTION,
                                        )
                                    }
                                }}
                            >
                                <img src={Send} alt="send" />
                            </button>
                        </Tooltip>
                    </>
                )}
            </div>
        </div>
    )
}

export default MessageCreateComponent
