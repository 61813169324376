import React, { useState, useEffect } from 'react'
import { useCV } from '@context/CVContext'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { VisibilityButton } from '@compUtils'

import 'react-phone-number-input/style.css'

function Phone({ isModal = false }) {
    const { cvData, updateCV } = useCV()
    const [phone, setPhone] = useState(cvData.PhoneNumber || '')
    const [isPhoneValid, setPhoneValid] = useState(isValidPhoneNumber(phone))
    const [countryCode, setCountryCode] = useState('VN')

    useEffect(() => {
        const fetchIpAndCountry = async () => {
            try {
                const ipResponse = await fetch(
                    'https://api.ipify.org?format=json',
                )
                if (!ipResponse.ok) {
                    throw new Error('Error while fetching IP')
                }
                const { ip } = await ipResponse.json()

                const countryResponse = await fetch(
                    `http://ip-api.com/json/${ip}`,
                )
                if (!countryResponse.ok) {
                    throw new Error('Error while fetching country')
                }
                const { countryCode } = await countryResponse.json()

                setCountryCode(countryCode)
            } catch (error) {
                console.error('Error while fetching country code', error)
            }
        }

        fetchIpAndCountry()
    }, [])

    const handlePhoneChange = (value) => {
        setPhone(value || '')
        updateCV((prevData) => ({ ...prevData, PhoneNumber: value }))

        if (value && isValidPhoneNumber(value)) {
            setPhoneValid(true)
        } else {
            setPhoneValid(false)
        }
    }

    return (
        <div
            className={`new-component ${
                !isPhoneValid ? 'invalid-border' : 'valid-border'
            }`}
            style={isModal ? { width: 'auto' } : {}}
        >
            <div className="new-info-wrapper">
                <div className="new-info-header">
                    Phone Number
                    <VisibilityButton detailKeyOrPath={'PhoneNumber'} />
                </div>
                <div className="new-info-container">
                    <PhoneInput
                        international
                        defaultCountry={countryCode}
                        value={phone}
                        onChange={handlePhoneChange}
                    />
                </div>
            </div>
        </div>
    )
}

export default Phone
