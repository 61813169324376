import React, { useState } from 'react'
import { useTmpData, useContentOpen } from '@context'
import { Modal } from '@modals'
import { VisibilityButton } from '@compUtils'
import { useCVUpdate } from '@hooks'
import CompanyModal from '@modals/Experiences/CompanyModal'

import './ExperienceCompany.css'

const ExperienceCompany = ({
    Name,
    WebSite,
    Description,
    CompanyId,
    experienceType,
}) => {
    const { isEditing } = useTmpData()
    const { handleModalCloseGeneric } = useCVUpdate()
    const { isContentOpen } = useContentOpen()
    const [isModalOpen, setIsModalOpen] = useState(false)

    const handleModalClose = async (companyChanges) => {
        setIsModalOpen(false)
        if (!companyChanges) return
        handleModalCloseGeneric(companyChanges, 'Experiences', CompanyId)
    }

    return (
        <>
            <div
                className={`experience-company-container ${
                    isEditing ? 'blue entry-editing' : ''
                }`}
                onClick={() => {
                    isEditing && !isContentOpen && setIsModalOpen(true)
                }}
            >
                <div className="company-wrapper">
                    <div className="company-image-name-container">
                        <h3 className="company-name">{Name}</h3>
                    </div>
                    <VisibilityButton
                        detailKeyOrPath={`Companies.${CompanyId}`}
                        isCompany={true}
                    />
                </div>
                <span>{Description}</span>
            </div>
            <Modal
                isOpen={isModalOpen}
                onClose={handleModalClose}
                modal="Company"
                initialValue={{ Name, WebSite, Description, CompanyId }}
                className="modal-content wide"
                Component={CompanyModal}
            />
        </>
    )
}

export default ExperienceCompany
