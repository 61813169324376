import axios from 'axios'
import { validateAndUpdateCV } from '@utils'
import ObjectID from 'bson-objectid'
import { debounce } from 'lodash'

export const debouncedMutate = debounce((mutate, newData) => {
    mutate(newData)
}, 3000)

export async function updateUserDataOnServer(updatedUserData) {
    try {
        const userCVCopy = { ...updatedUserData }

        const response = await axios.put(
            `${process.env.REACT_APP_BACK_URL}/user-data`,
            userCVCopy,
        )

        if (response.status !== 200) {
            console.error('Error updating user data:', response)
            return false
        }
        return true
    } catch (error) {
        console.error('Error updating user data:', error)
        return false
    }
}

export const debouncedUpdateUserDataOnServer = debounce(
    updateUserDataOnServer,
    3000,
)

export async function submitFeedback(feedbackData, token) {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_BACK_URL}/api/feedback/submit`,
            feedbackData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
        )

        if (response.status === 200) {
            if (process.env.REACT_APP_NODE_ENV === 'development')
                console.log(
                    'Feedback submitted successfully:',
                    response.data.message,
                )
            return true
        } else {
            console.error('Error submitting feedback:', response)
            return false
        }
    } catch (error) {
        console.error('Error submitting feedback:', error)
        return false
    }
}

const toggleVisibility = (currentVisibility) => {
    switch (currentVisibility) {
        case 'Public':
            return 'Limited'
        case 'Limited':
            return 'Private'
        case 'Private':
            return 'Public'
        default:
            return 'Limited'
    }
}

export function handleVisibilityClick(cvData, field) {
    // Clone the cvData object
    const newUserData = { ...cvData }

    // Cut the path to see if it's an object
    const pathArray = field.split('.')
    const key = pathArray[0]
    const id = pathArray?.length > 1 ? pathArray[1] : null

    // If the element is an object
    if (id) {
        if (!newUserData.Visibility[key]) {
            newUserData.Visibility[key] = []
        }

        const index = newUserData.Visibility[key].findIndex(
            (item) => item._id === id,
        )
        if (index !== -1) {
            // Change the visibility of the element
            newUserData.Visibility[key][index].visibility = toggleVisibility(
                newUserData.Visibility[key][index].visibility,
            )
        } else {
            // If the element doesn't exist, create it with default visibility
            newUserData.Visibility[key].push({
                _id: id,
                visibility: 'Limited',
            })
        }
    } else {
        // Handle the visibility of a direct property
        newUserData.Visibility[key] = toggleVisibility(
            newUserData.Visibility[key],
        )
    }

    return newUserData
}

export function getFaviconUrl(website) {
    const domain = extractMainDomain(website)
    return `https://s2.googleusercontent.com/s2/favicons?domain_url=https://${domain}`
}

export const extractMainDomain = (url) => {
    try {
        const parsedUrl = new URL(url)
        let hostname = parsedUrl.hostname

        return hostname
    } catch (e) {
        return url
    }
}

export const simplifyDescription = (description) => {
    const parts = description.split(',')
    if (parts?.length >= 3) {
        return `${parts[0]},${parts[parts?.length - 1]}`.trim()
    }
    return description
}

export function showAlert(alertRef) {
    if (alertRef.current) {
        // Start the fadeIn animation
        alertRef.current.style.visibility = 'visible'
        alertRef.current.classList.add('fadeIn')
        alertRef.current.classList.remove('fadeOut')

        // After 3 seconds, start the fade-out
        setTimeout(() => {
            alertRef.current.classList.remove('fadeIn')
            alertRef.current.classList.add('fadeOut')

            // Ensure the alert is hidden after animation
            setTimeout(() => {
                if (alertRef.current) {
                    alertRef.current.style.visibility = 'hidden'
                }
            }, 500) // 0.5s is the animation duration
        }, 3000)
    }
}

export const scrollToBottom = (ref) => {
    if (ref.current) {
        ref.current.scrollTo({
            top: ref.current.scrollHeight,
            behavior: 'smooth',
        })
    }
}

export const handleCVUpdate = async (
    userCV,
    updatedUserCV,
    updateUserDataOnServer,
    userData,
    setUserCV,
) => {
    try {
        const updatedCV = await validateAndUpdateCV(userCV, updatedUserCV)

        setUserCV(updatedCV)

        await updateUserDataOnServer({
            ...userData,
            CV: updatedCV,
        })
    } catch (error) {
        console.error('Error while updating CV:', error.message)
    }
}

export const updateExperienceInCV = (userCV, experienceChanges) => {
    let companyFound = false

    const experiencesWithRemoved = userCV.Experiences.map((company) => ({
        ...company,
        ExperiencesInCompany: company.ExperiencesInCompany.filter(
            (expInCompany) => expInCompany._id !== experienceChanges.id,
        ),
    }))

    const updatedExperiences = experiencesWithRemoved.map((company) => {
        if (company._id === experienceChanges.CompanyId) {
            companyFound = true
            let experienceFound = false
            const updatedExperiencesInCompany =
                company.ExperiencesInCompany.map((expInCompany) => {
                    if (expInCompany._id === experienceChanges.id) {
                        experienceFound = true
                        return { ...expInCompany, ...experienceChanges }
                    }
                    return expInCompany
                })

            if (!experienceFound) {
                updatedExperiencesInCompany.push({
                    ...experienceChanges,
                    _id: experienceChanges.id || ObjectID().toString(),
                })
            }

            return {
                ...company,
                CompanyName: experienceChanges.CompanyName,
                CompanyDescription: experienceChanges.CompanyDescription,
                CompanyWebSite: experienceChanges.CompanyWebSite,
                ExperiencesInCompany: updatedExperiencesInCompany,
            }
        }
        return company
    })

    if (!companyFound) {
        updatedExperiences.push({
            _id: ObjectID().toString(),
            CompanyName: experienceChanges.CompanyName,
            CompanyWebSite: experienceChanges.CompanyWebSite,
            CompanyDescription: experienceChanges.CompanyDescription,
            ExperiencesInCompany: [
                {
                    ...experienceChanges,
                    _id: experienceChanges.id,
                },
            ],
        })
    }

    return { ...userCV, Experiences: updatedExperiences }
}
