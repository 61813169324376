import React, { useState, useRef, useEffect } from 'react'
import Tooltip from '@mui/material/Tooltip'
import { useUserData, useCV, useAuth, useAlert } from '@context'
import { autoGrowTextWithMinMax } from '@utils'
import { CharacterCounter } from '@compUtils'

import { Send } from '@assets'

function FinalizeModal({ onUpdate, onClose }) {
    const { updateCV } = useCV()
    const { showAlert } = useAlert()
    const { userData } = useUserData()
    const { socket } = useAuth()

    const MAX_MESSAGE_LENGTH = 5000

    const [userMessage, setUserMessage] = useState('')
    const [characterCount, setCharacterCount] = useState(0)
    const [isEditingSummary, setIsEditingSummary] = useState(false)
    const [isAssistantWriting, setIsAssistantWriting] = useState(true)
    const [chatMessages, setChatMessages] = useState([
        {
            sender: 'assistant',
            text:
                'Hi, ' +
                userData.Name +
                '.\nFantastic job in finalizing your profile. Before we proceed with your Summary, can you share more about your career aspirations?',
        },
    ])

    const [summary, setSummary] = useState('')
    const summaryRef = useRef(summary)

    useEffect(() => {
        setCharacterCount(userMessage.length)
    }, [userMessage])

    useEffect(() => {
        summaryRef.current = summary
        if (!!summary && summary.length > 0) {
            onUpdate(summary)
        }
    }, [summary, onUpdate])

    useEffect(() => {
        const handleStreamMessage = (message) => {
            setSummary((prev) => prev + message)
        }

        const handleSummaryEnd = () => {
            updateCV((prevData) => ({
                ...prevData,
                Summary: summaryRef.current,
            }))
            setIsAssistantWriting(false)
        }

        const handleError = (error) => {
            console.log(error)
            showAlert(error.error, 'alert-error')
            onClose()
        }

        socket.on('summary-message', handleStreamMessage)
        socket.on('summary-end', handleSummaryEnd)
        socket.on('summary-error', handleError)

        return () => {
            socket.off('summary-message', handleStreamMessage)
            socket.off('summary-end', handleSummaryEnd)
            socket.off('summary-error', handleError)
        }
    }, [socket, updateCV, onClose, showAlert])

    const handleUserMessageSubmit = () => {
        setIsEditingSummary(true)
        socket.emit('create-summary', { userMessage })
    }

    const handleInput = (e) => {
        autoGrowTextWithMinMax(e.target, 21, 150, 200)
        let text = e.target.value
        if (text.length > MAX_MESSAGE_LENGTH) {
            text = text.substring(0, MAX_MESSAGE_LENGTH)
            setUserMessage(text)
        } else {
            setUserMessage(text)
        }
    }

    const handleSummaryChange = (e) => {
        setSummary(e.target.value)
    }

    const handleSendClick = () => {
        const newMessages = [
            ...chatMessages,
            { text: userMessage, sender: 'user' },
        ]
        setChatMessages(newMessages)
        handleUserMessageSubmit()
        setUserMessage('')
    }

    const handleMessageChange = (e) => {
        if (e.key === 'Enter' && e.shiftKey) {
            handleSendClick()

            e.target.value = ''
            e.preventDefault()
        }
    }

    return (
        <div className="new-info-exprerience-container">
            <div className="description-container">
                {!isEditingSummary ? (
                    <>
                        <div className="chat-container">
                            {chatMessages.map((message, index) => (
                                <div
                                    key={index}
                                    className={`message-container ${
                                        message.sender === 'user' ? 'user' : ''
                                    }`}
                                >
                                    <div
                                        key={index}
                                        className={`message ${message.sender}`}
                                    >
                                        {message.text}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="textarea-send-container">
                            <textarea
                                value={userMessage}
                                onInput={handleInput}
                                onKeyDown={handleMessageChange}
                                className="chat-input"
                            />
                            <div className="chat-input-send-container">
                                <CharacterCounter
                                    characterCount={characterCount}
                                    maxCount={MAX_MESSAGE_LENGTH}
                                />
                                <Tooltip
                                    title="Press Shit + Enter to regenerate a description"
                                    placement="top"
                                >
                                    <button
                                        className="chat-input-send"
                                        onClick={() => {
                                            handleSendClick()
                                        }}
                                    >
                                        <img src={Send} alt="send" />
                                    </button>
                                </Tooltip>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="chat-container-input">
                        <textarea
                            value={summary}
                            onChange={handleSummaryChange}
                            disabled={isAssistantWriting}
                            style={{ height: '300px' }}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default FinalizeModal
