import React, { useState, useRef } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import Tooltip from '@mui/material/Tooltip'
import { useJD } from '@context'
import { getJDById, getJDMessages, sendJDMessage, scrollToBottom } from '@utils'
import { TypingAnimation } from '@compUtils'

import { Send } from '@assets'

function JDDraft() {
    const { jobId } = useParams()
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const { jd, setInitialJDData } = useJD()
    const messageContainerRef = useRef(null)

    const [draftMessage, setDraftMessage] = useState('')

    useQuery(`jobDescription-${jobId}`, () => getJDById(jobId), {
        enabled: !jd,
        onSuccess: (data) => {
            setInitialJDData(data)
        },
    })

    const {
        data: messages,
        isLoading,
        isError,
        error,
    } = useQuery(`JDMessages-${jobId}`, () => getJDMessages(jobId), {
        onSuccess: () => {
            setTimeout(() => {
                scrollToBottom(messageContainerRef)
            }, 100)
        },
    })

    const sendMessageMutation = useMutation(
        (messageContent) => sendJDMessage(jobId, messageContent),
        {
            onMutate: async (messageContent) => {
                await queryClient.cancelQueries(`JDMessages-${jobId}`)

                const previousMessages = queryClient.getQueryData(
                    `JDMessages-${jobId}`,
                )
                queryClient.setQueryData(`JDMessages-${jobId}`, (old) => [
                    ...old,
                    { role: 'user', content: messageContent },
                ])

                scrollToBottom(messageContainerRef)

                return { previousMessages }
            },
            onSuccess: (response) => {
                queryClient.setQueryData(`JDMessages-${jobId}`, (old) => [
                    ...old,
                    { role: 'assistant', content: response.lastMessage },
                ])

                scrollToBottom(messageContainerRef)
            },
            onError: (error, _, context) => {
                if (context.previousMessages) {
                    queryClient.setQueryData(
                        `JDMessages-${jobId}`,
                        context.previousMessages,
                    )
                }

                toast.error(error.response.data.message)
            },
        },
    )

    const handleSendMessage = () => {
        if (draftMessage.trim() === '') {
            toast.error('Message cannot be empty')
            return
        }

        if (sendMessageMutation.isLoading) {
            toast.error('Please wait for the assistant to finish typing')
            return
        }

        sendMessageMutation.mutate(draftMessage)

        setDraftMessage('')
    }

    const handleKeyDown = (e) => {
        if (e.shiftKey && e.key === 'Enter') {
            e.preventDefault()
            handleSendMessage()
        }
    }

    if (isLoading) {
        return <h1>Loading...</h1>
    }

    if (isError) {
        toast.error(error.response.data.message)
        navigate('/hire')
        return null
    }

    return (
        <div className="jd-draft">
            <div className="jd-chat">
                <div className="jd-chat-messages" ref={messageContainerRef}>
                    {messages.map((message, index) => (
                        <div
                            key={index}
                            className={`jd-message-container ${
                                message.role === 'user' ? 'user' : 'assistant'
                            }`}
                        >
                            <div className={`jd-message ${message.role}`}>
                                {message.content}
                            </div>
                        </div>
                    ))}

                    {sendMessageMutation.isLoading && (
                        <div className="jd-message-container assistant">
                            <div className="jd-message assistant">
                                <TypingAnimation />
                            </div>
                        </div>
                    )}
                </div>
                <textarea
                    id="draft-message"
                    value={draftMessage}
                    onKeyDown={handleKeyDown}
                    onChange={(e) => setDraftMessage(e.target.value)}
                    type="text"
                    className="jd-draft-input"
                    placeholder="Don't worry about grammar and structure; we'll handle that for you."
                />
                <Tooltip
                    title="Press Shit + Enter to send"
                    placement="top"
                    arrow
                >
                    <div className="jd-chat-button">
                        <img
                            className="jd-button-img"
                            src={Send}
                            onClick={handleSendMessage}
                        />
                    </div>
                </Tooltip>
            </div>
        </div>
    )
}

export default JDDraft
