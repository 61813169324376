import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAlert, useAuth, useCV } from '@context'
import ObjectID from 'bson-objectid'

import { Upload } from '@assets'

import './Start.css'

function Start() {
    const { cvData, updateCV } = useCV()
    const { socket } = useAuth()
    const { showAlert } = useAlert()
    const navigate = useNavigate()

    const onDrop = (e) => {
        e.preventDefault()
        const files = e.dataTransfer.files
        if (files?.length) {
            const file = files[0]
            if (file.type === 'application/pdf') {
                handleFileUpload({ target: { files } })
            } else {
                showAlert('Please upload a PDF file.', 'warning')
            }
        }
    }

    const onClick = () => {
        const fileInput = document.getElementById('fileInput')
        fileInput.click()
    }

    const handleFileUpload = async (event) => {
        const file = event.target.files[0]
        if (!file) return

        const fileSizeInBytes = file.size
        const maxFileSizeInBytes = 15 * 1024 * 1024 // 15 MB

        if (fileSizeInBytes > maxFileSizeInBytes) {
            showAlert(
                'The uploaded file is too large.\nPlease upload a file smaller than 15MB.',
                'warning',
            )
            return
        }

        const reader = new FileReader()
        reader.onload = (loadEvent) => {
            const fileContent = loadEvent.target.result

            updateCV((prevUserData) => ({
                ...prevUserData,
                UploadedCV: 'uploading',
            }))
            navigate('/profile/information')

            showAlert('Parsing your CV...')

            // send file to server via socket
            socket.emit('upload-cv', { fileBuffer: fileContent })

            // listen event answer from server
            socket.on('parsed-data', (data) => {
                const parsed_content = data.parsed_content

                const withIds = {
                    ...parsed_content,
                    Education: parsed_content.Education?.map((edu) => ({
                        ...edu,
                        _id: ObjectID().toString(),
                    })),
                    Experiences: parsed_content.Experiences?.map((exp) => ({
                        ...exp,
                        _id: ObjectID().toString(),
                        ExperiencesInCompany: exp.ExperiencesInCompany?.map(
                            (expComp) => ({
                                ...expComp,
                                _id: ObjectID().toString(),
                            }),
                        ),
                    })),
                    Languages: parsed_content.Languages?.map((lang) => ({
                        ...lang,
                        _id: ObjectID().toString(),
                    })),
                    Certifications: parsed_content.Certifications?.map(
                        (cert) => ({
                            ...cert,
                            _id: ObjectID().toString(),
                        }),
                    ),
                }

                updateCV((prevUserData) => ({
                    ...prevUserData,
                    ...withIds,
                    UploadedCV: 'yes',
                }))
                showAlert('Your CV has been parsed successfully!')
            })

            // handle errors
            socket.on('parsing-error', (error) => {
                updateCV((prevUserData) => ({
                    ...prevUserData,
                    UploadedCV: 'no',
                }))
                console.error('Error uploading and parsing CV:', error)
                showAlert('The was an error parsing your CV...', 'error')
            })
        }

        reader.readAsArrayBuffer(file)
        event.target.value = null
    }

    return (
        <div className="start">
            <span className="explain">
                Let's craft your resume with{' '}
                <span className="blue">COTALENT</span>
            </span>
            {cvData.UploadedCV !== 'yes' ? (
                <>
                    <span className="upload-cv">
                        You can upload your old resume or you Linkedin profile
                    </span>
                    <span className="linkedin-tuto">
                        Go to your{' '}
                        <a
                            href="https://www.linkedin.com/in/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="blue"
                        >
                            Linkedin profile
                        </a>{' '}
                        and under your picture click More and you will find the
                        <br /> option to Save your profile as a PDF.
                    </span>
                    <div
                        className="dropzone"
                        id="dropzone"
                        onDrop={onDrop}
                        onDragOver={(e) => e.preventDefault()}
                        onClick={onClick}
                    >
                        <span className="dropzone-span">
                            Drop file here or click to upload
                        </span>
                        <img src={Upload} alt="upload" />
                        <input
                            type="file"
                            id="fileInput"
                            hidden
                            onChange={handleFileUpload}
                            accept=".pdf"
                        />
                    </div>
                </>
            ) : null}

            <span className="manual">
                {cvData.UploadedCV !== 'yes' ? 'Otherwise you ' : 'You '}
                can build your profile with us from scratch. <br />{' '}
                <span className="blue">In less than 10 minutes.</span>
            </span>
            <div className="hn-buttons-container">
                <button
                    className="hn-button-blue"
                    onClick={() => navigate('/profile/information')}
                >
                    Next
                </button>
            </div>
        </div>
    )
}

export default Start
