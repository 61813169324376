import React, { useRef, useMemo } from 'react'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { useTmpData, useCV } from '@context'
import { formatDateAndCalculateAge, formatSalary, formatList } from '@utils'
import { useValidation } from '@hooks'
import InformationEntry from './InformationEntry'
import { ErrorMessage } from '@compUtils'
import { emailRegex } from '@utils'

import './Information.css'

function Information() {
    const { isEditing } = useTmpData()
    const { cvData } = useCV()

    // handle click outside
    const clickOutRef = useRef(null)

    const informationConfig = [
        { title: 'First Name', detailKey: 'Name', useData: true },
        { title: 'Last Name', detailKey: 'Surname', useData: true },
        {
            title: 'Date of Birth',
            detailKey: 'BirthDate',
            formatter: formatDateAndCalculateAge,
        },
        { title: 'Gender', detailKey: 'Gender' },
        { title: 'Nationality', detailKey: 'Nationality' },
        { title: 'Location', detailKey: 'CurrentCity' },
        { title: 'Email', detailKey: 'Email' },
        { title: 'Phone Number', detailKey: 'PhoneNumber' },
        {
            title: 'Current Base Salary',
            detailKey: 'CurrentBaseSalary',
            formatter: formatSalary,
        },
        {
            title: 'Current Bonus and Benefits',
            detailKey: 'CurrentBonus',
            formatter: formatList,
        },
    ]

    const validationConfig = useMemo(
        () => [
            {
                key: 'BirthDate',
                validate: (value) =>
                    value
                        ? ''
                        : '!Click the card to add the missing information',
            },
            {
                key: 'Gender',
                validate: (value) =>
                    value
                        ? ''
                        : '!Click the card to add the missing information',
            },
            {
                key: 'Nationality',
                validate: (value) =>
                    value
                        ? ''
                        : '!Click the card to add the missing information',
            },
            {
                key: 'CurrentCity',
                validate: (value) =>
                    value
                        ? ''
                        : '!Click the card to add the missing information',
            },
            {
                key: 'Email',
                validate: (value) =>
                    value && emailRegex.test(value)
                        ? ''
                        : '!Click the card to add the missing information',
            },
            {
                key: 'PhoneNumber',
                validate: (value) =>
                    value && isValidPhoneNumber(value)
                        ? ''
                        : '!Click the card to add the missing information',
            },
            {
                key: 'CurrentBaseSalary',
                validate: (value) =>
                    value
                        ? ''
                        : 'Click the card to add the optional information',
            },
            {
                key: 'CurrentBonus',
                validate: (value) =>
                    value === undefined || value?.length === 0
                        ? 'Click the card to add the optional information'
                        : '',
            },
        ],
        [],
    )

    const { className, errorMessage } = useValidation(
        cvData,
        isEditing,
        validationConfig,
    )

    return (
        <div className="information" ref={clickOutRef}>
            <h3 className="title">INFORMATION</h3>
            <div className={`information-container ${className}`}>
                {informationConfig?.map((detail) => (
                    <InformationEntry
                        key={detail.detailKey}
                        title={detail.title}
                        detailKey={detail.detailKey}
                        formatter={detail.formatter}
                        useData={detail.useData || false}
                    />
                ))}
            </div>
            {isEditing && errorMessage !== '' && (
                <ErrorMessage message={errorMessage} className={className} />
            )}
        </div>
    )
}

export default React.memo(Information)
