import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { useJD } from '@context'
import { getJDById, bonusOptions, contractTypes } from '@utils'
import InfoCard from './InfoCard'
import './JD.css'

function JDDetails() {
    const { jobId } = useParams()
    const navigate = useNavigate()
    const { jd, setInitialJDData, updateJD } = useJD()

    const { isLoading, isError, error } = useQuery(
        `jobDescription-${jobId}`,
        () => getJDById(jobId),
        {
            enabled: !jd,
            onSuccess: (data) => {
                setInitialJDData(data)
            },
        },
    )

    if (isLoading) {
        return <h1>Loading...</h1>
    }

    if (isError) {
        toast.error(error.response.data.message)
        navigate('/hire')
        return null
    }

    return (
        <div className="jd-creation">
            <div className="jd-multiplpe-card">
                <InfoCard
                    header="Department"
                    value={jd.Department}
                    onChange={(e) =>
                        updateJD({
                            ...jd,
                            Department: e.target.value,
                        })
                    }
                    placeholder={'Department...'}
                />
                <InfoCard
                    header="Direct Supervisor"
                    value={jd.Supervisor}
                    onChange={(e) =>
                        updateJD({
                            ...jd,
                            Supervisor: e.target.value,
                        })
                    }
                    placeholder={'Supervisor...'}
                />
            </div>
            <div className="jd-multiplpe-card">
                <InfoCard
                    header="Dotted Line Reporting"
                    value={jd.ReportingTo}
                    onChange={(e) =>
                        updateJD({
                            ...jd,
                            ReportingTo: e.target.value,
                        })
                    }
                    placeholder={'Reporting To...'}
                />
                <InfoCard
                    header="Subordinates"
                    value={jd.Subordinates}
                    onChange={(e) =>
                        updateJD({
                            ...jd,
                            Subordinates: e.target.value,
                        })
                    }
                    placeholder={'Subordinates...'}
                />
            </div>
            <InfoCard
                header="Salary Budget"
                inputType="salary"
                value={jd.Salary}
                onChange={(e, field) => {
                    updateJD({
                        ...jd,
                        Salary: {
                            ...jd.Salary,
                            [field]: e.target?.value || e.value,
                        },
                    })
                }}
                placeholder={'Salary...'}
            />
            <div className="jd-multiplpe-card">
                <InfoCard
                    header="Bonus and Benefits"
                    inputType="creatable-multi"
                    value={jd.Bonus}
                    onChange={(e) => {
                        updateJD({
                            ...jd,
                            Bonus: e,
                        })
                    }}
                    options={bonusOptions}
                />
                <InfoCard
                    header="Engagement Type"
                    inputType="creatable"
                    value={jd.EngagementType}
                    options={contractTypes}
                    onChange={(e) =>
                        updateJD({
                            ...jd,
                            EngagementType: e,
                        })
                    }
                />
            </div>
            <div className="jd-multiplpe-card">
                <InfoCard
                    header="Location"
                    inputType="location"
                    value={jd.Location}
                    onChange={(e) => {
                        updateJD({
                            ...jd,
                            Location: e.value,
                        })
                    }}
                    placeholder={'Location...'}
                />
                <InfoCard
                    header="Working Hours"
                    value={jd.WorkingHours}
                    onChange={(e) =>
                        updateJD({
                            ...jd,
                            WorkingHours: e.target.value,
                        })
                    }
                    itype="number"
                    placeholder={'Working Hours...'}
                />
            </div>
        </div>
    )
}

export default JDDetails
