import React, { useCallback } from 'react'
import { useCV, useTmpData } from '@context'
import SCKRow from './SCKRow'

import './Highlight.css'

function Highlight() {
    const { cvData, updateCV } = useCV()
    const { isEditing } = useTmpData()

    const { Skills, Competencies, Knowledge } = cvData.SCKs

    const removeSCK = useCallback(
        (itemToRemove, category) => {
            updateCV((prevUserCV) => ({
                ...prevUserCV,
                SCKs: {
                    ...prevUserCV.SCKs,
                    [category]: prevUserCV.SCKs[category].filter(
                        (item) => item.Name !== itemToRemove.Name,
                    ),
                },
            }))
        },
        [updateCV],
    )

    const handleNewItemSubmit = (newItem, category) => {
        updateCV((prevUserCV) => ({
            ...prevUserCV,
            SCKs: {
                ...prevUserCV.SCKs,
                [category]: [...prevUserCV.SCKs[category], newItem],
            },
        }))
    }

    return (
        <div className={`highlight`}>
            <h3 className="title">HIGHLIGHT</h3>
            <div
                className={`highlight-container ${isEditing && 'editing-blue'}`}
            >
                <SCKRow
                    label="Skills"
                    items={Skills}
                    isEditing={isEditing}
                    onRemoveItem={(item) => removeSCK(item, 'Skills')}
                    onNewItemSubmit={handleNewItemSubmit}
                />
                <SCKRow
                    label="Competencies"
                    items={Competencies}
                    isEditing={isEditing}
                    onRemoveItem={(item) => removeSCK(item, 'Competencies')}
                    onNewItemSubmit={handleNewItemSubmit}
                />
                <SCKRow
                    label="Knowledge"
                    items={Knowledge}
                    isEditing={isEditing}
                    onRemoveItem={(item) => removeSCK(item, 'Knowledge')}
                    onNewItemSubmit={handleNewItemSubmit}
                />
            </div>
        </div>
    )
}

export default Highlight
