import React, { useState } from 'react'
import { useCV } from '@context/CVContext'
import Creatable from 'react-select/creatable'
import { VisibilityButton } from '@compUtils'
import { useUtils } from '@hooks'

import { customStylesValid, customStylesFacultative } from '@styles/styles'

function Industry({ isModal = false }) {
    const { cvData, updateCV } = useCV()
    const { options, filterIndustries } = useUtils()
    const [selectedIndustries, setSelectedIndustries] = useState(
        cvData.PreferedIndustry?.map((industry) => ({
            value: industry,
            label: industry,
        })) || [],
    )

    const handleIndustryChange = (selectedOptions) => {
        setSelectedIndustries(selectedOptions || [])
        updateCV((prevData) => ({
            ...prevData,
            PreferedIndustry: selectedOptions?.map((option) => option.value),
        }))
    }

    return (
        <div
            className={`new-component ${
                selectedIndustries?.length === 0
                    ? 'facultative-border'
                    : 'valid-border'
            }`}
            style={isModal ? { width: 'auto' } : {}}
        >
            <div className="new-info-wrapper">
                <div className="new-info-header">
                    Industry
                    <VisibilityButton detailKeyOrPath={'PreferedIndustry'} />
                </div>
                <div className="new-info-container">
                    <Creatable
                        isMulti
                        value={selectedIndustries}
                        onChange={handleIndustryChange}
                        onInputChange={filterIndustries}
                        options={options}
                        isSearchable
                        placeholder="Select industries..."
                        styles={{
                            ...(selectedIndustries?.length > 0
                                ? customStylesValid
                                : customStylesFacultative),
                        }}
                        filterOption={null}
                        openMenuOnFocus={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default Industry
