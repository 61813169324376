import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import { useCV, useTmpData } from '@context'
import { AddSections } from '@compUtils'
import { dateToComparableNumber } from '@utils'
import ExperienceContainer from './ExperienceContainer'

import './Experiences.css'

function Experiences() {
    const { cvData } = useCV()
    const { isEditing } = useTmpData()

    const organizeByCategory = () => {
        let categories = {
            Work: [],
            Project: [],
            Association: [],
            Volunteer: [],
        }

        if (!cvData || !cvData.Experiences) return categories

        cvData.Experiences.forEach((company) => {
            if (
                company.ExperiencesInCompany &&
                company.ExperiencesInCompany?.length > 0
            ) {
                company.ExperiencesInCompany.forEach((exp) => {
                    const experienceType = exp.ExperienceType || 'Work'
                    if (!categories[experienceType]) {
                        categories[experienceType] = []
                    }
                    categories[experienceType].push({
                        company: company,
                        experience: exp,
                    })
                })
            }
        })

        Object.keys(categories).forEach((category) => {
            categories[category].sort((a, b) => {
                const aEndDate = dateToComparableNumber(a.experience.EndDate)
                const bEndDate = dateToComparableNumber(b.experience.EndDate)
                return bEndDate - aEndDate
            })
        })

        return categories
    }

    const categorizedExperiences = organizeByCategory()

    function getTitle(category) {
        if (category === 'Work') {
            return (
                <Tooltip
                    title={
                        <div style={{ textAlign: 'center' }}>
                            "Detail your past job titles, main responsibilities,
                            accomplishments, and tenure at each company."
                        </div>
                    }
                    placement="top"
                >
                    <span>WORK EXPERIENCE</span>
                </Tooltip>
            )
        } else if (category === 'Volunteer') {
            return (
                <Tooltip
                    title={
                        <div style={{ textAlign: 'center' }}>
                            Detail your past job titles, main responsibilities,
                            accomplishments, and tenure at each company.
                        </div>
                    }
                    placement="top"
                >
                    <span>VOLUNTEER</span>
                </Tooltip>
            )
        } else if (category === 'Project') {
            return (
                <Tooltip
                    title={
                        <div style={{ textAlign: 'center' }}>
                            "Summarize key projects you've worked on, either in
                            a professional setting or as a consultant."
                        </div>
                    }
                    placement="top"
                >
                    <span>PROJECTS</span>
                </Tooltip>
            )
        } else if (category === 'Association') {
            return (
                <Tooltip
                    title={
                        <div style={{ textAlign: 'center' }}>
                            "List your involvement in industry groups, board
                            memberships, and other professional engagements."
                        </div>
                    }
                    placement="top"
                >
                    <span>MEMBERSHIP & ASSOCIATIONS</span>
                </Tooltip>
            )
        }
        return category
    }

    return (
        <React.Fragment>
            {Object.entries(categorizedExperiences)?.map(
                ([category, companyExperiences]) => {
                    if (!isEditing && companyExperiences?.length === 0) {
                        return null
                    }

                    // Group by company
                    const groupedByCompany = {}
                    companyExperiences.forEach(({ company, experience }) => {
                        if (!groupedByCompany[company.CompanyName]) {
                            groupedByCompany[company.CompanyName] = {
                                company: company,
                                experiences: [],
                            }
                        }
                        groupedByCompany[company.CompanyName].experiences.push(
                            experience,
                        )
                    })

                    return (
                        <div key={category} className="experience">
                            <div className="experience-add-title">
                                <h3 className="title">{getTitle(category)}</h3>
                                {isEditing && (
                                    <AddSections category={category} />
                                )}
                            </div>
                            {Object.values(groupedByCompany)?.map(
                                ({ company, experiences }, index) => (
                                    <ExperienceContainer
                                        key={company.CompanyName}
                                        company={company}
                                        experiences={experiences}
                                        category={category}
                                        isEditing={isEditing}
                                        style={
                                            index === 0
                                                ? { marginTop: '10px' }
                                                : {}
                                        }
                                    />
                                ),
                            )}
                        </div>
                    )
                },
            )}
        </React.Fragment>
    )
}

export default Experiences
