import { useState, useEffect } from 'react'

export default function useValidation(userCV, isEditing, validationConfig) {
    const [status, setStatus] = useState({
        className: 'editing-blue',
        errorMessage: '',
    })

    useEffect(() => {
        if (!isEditing) {
            setStatus({ className: 'not-editing', errorMessage: '' })
        } else {
            let errors = validationConfig?.map((config) => {
                return config.validate(userCV[config.key])
            })
            errors = errors.filter(Boolean)

            const criticalErrors = errors.filter((error) =>
                error.startsWith('!'),
            )
            const normalErrors = errors.filter(
                (error) => !error.startsWith('!'),
            )

            if (criticalErrors?.length > 0) {
                const criticalErrorMessage = criticalErrors[0].substring(1)
                setStatus({
                    className: 'editing-red',
                    errorMessage: criticalErrorMessage,
                })
            } else if (normalErrors?.length > 0) {
                setStatus({
                    className: 'editing-orange',
                    errorMessage: normalErrors[0],
                })
            } else {
                setStatus({ className: 'editing-blue', errorMessage: '' })
            }
        }
    }, [userCV, isEditing, validationConfig])

    return status
}
