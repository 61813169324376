export const customStyles = {
    container: (provided) => ({
        ...provided,
        width: '100%',
    }),
    valueContainer: (provided) => ({
        ...provided,
        maxHeight: '38px',
        overflow: 'auto',
    }),
    menuList: (provided) => ({
        ...provided,
        maxHeight: '170px',
        overflowY: 'auto',
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 1000,
    }),
}

export const customStylesValid = {
    ...customStyles,
    control: (provided) => ({
        ...provided,
        '&:focus-within': {
            outline: '1px solid #007AFF !important',
            borderColor: '#007AFF',
        },
    }),
}

export const customStylesInvalid = {
    ...customStyles,
    control: (provided) => ({
        ...provided,
        '&:focus-within': {
            outline: '1px solid #FF453A !important',
            borderColor: '#FF453A',
        },
    }),
}

export const customStylesFacultative = {
    ...customStyles,
    control: (provided) => ({
        ...provided,
        '&:focus-within': {
            outline: '1px solid #FF9500 !important',
            borderColor: '#FF9500',
        },
    }),
}

export const customStylesValidIn = {
    ...customStyles,
    control: (provided) => ({
        ...provided,
        borderColor: '#007AFF',
        boxShadow: '0px 0px 4px #007AFF33',
        '&:hover': {
            borderColor: '#007AFF',
        },
        '&:focus-within': {
            borderColor: '#007AFF66',
            boxShadow: '0px 0px 10px #007AFF33',
            outline: '1px solid #007AFF !important',
        },
    }),
}

export const customStylesInvalidIn = {
    ...customStyles,
    control: (provided) => ({
        ...provided,
        borderColor: '#FF453A66',
        boxShadow: '0px 0px 4px #FF453A33',
        '&:hover': {
            borderColor: '#FF453A',
        },
        '&:focus-within': {
            borderColor: '#FF453A66',
            boxShadow: '0px 0px 10px #FF453A33',
            outline: '1px solid #FF453A !important',
        },
    }),
}

export const customStylesFacultativeIn = {
    ...customStyles,
    control: (provided) => ({
        ...provided,
        borderColor: '#FF950066',
        boxShadow: '0px 0px 4px #FF950033',
        '&:hover': {
            borderColor: '#FF950066',
        },
        '&:focus-within': {
            borderColor: '#FF950066',
            boxShadow: '0px 0px 10px #FF950033',
            outline: '1px solid #FF9500 !important',
        },
    }),
}

export const customStylesValidInMonth = {
    ...customStylesValidIn,
    container: (provided) => ({
        ...provided,
        width: '100%',
    }),
}

export const customStylesInvalidInMonth = {
    ...customStylesInvalidIn,
    container: (provided) => ({
        ...provided,
        width: '100%',
    }),
}

export const customStylesFacultativeInMonth = {
    ...customStylesFacultativeIn,
    container: (provided) => ({
        ...provided,
        width: '100%',
    }),
}

export const customStylesValidInYear = {
    ...customStylesValidIn,
    container: (provided) => ({
        ...provided,
        width: '100%',
    }),
}

export const customStylesInvalidInYear = {
    ...customStylesInvalidIn,
    container: (provided) => ({
        ...provided,
        width: '100%',
    }),
}

export const customStylesFacultativeInYear = {
    ...customStylesFacultativeIn,
    container: (provided) => ({
        ...provided,
        width: '100%',
    }),
}
